import {
  Box,
  Flex,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useContext, useState } from "react";
import IdeaContext from "../IdeaContext";
import IdeaAttributes from "./IdeaAttributes";
import { IconArrowLeft } from "@tabler/icons";
import { saveIdea } from "../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useHover } from "@mantine/hooks";

const Header = ({
  reviewMode,
  setReviewMode,
  description,
  title,
  setTitle,
  isLongTitle,
  longTitleCheck,
  descWidth,
  titleWidth,
  isLongDescription
}: {
  reviewMode: boolean;
  setReviewMode: any;
  description: string;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  isLongTitle: boolean;
  longTitleCheck: any;
  descWidth: number;
  titleWidth: number;
  isLongDescription: boolean
}) => {
  const { idea, setMatchesTrigger } = useContext(IdeaContext);

  const auth0 = useAuth0();
  const navigate = useNavigate();
  const { hovered, ref } = useHover();
  const [editing, setEditing] = useState(false);

  const updateIdeaTitle = (title: string) => {
    if (idea && idea.title !== title) {
      const ideaObj = {
        id: idea._id,
        title: title,
      };
      saveIdea(ideaObj, auth0)
        .then((response) => {
          console.log(response);
          setMatchesTrigger(0)
        })
        .catch((e: any) => console.log(e));
    }
  };

  const handleBackClick = () => {
    // Retrieve the last saved filters or set a default empty string
    const lastFilters = sessionStorage.getItem('lastFilters') || '';
    
    // Navigate to the home page with the last saved parameters
    navigate(`/${lastFilters}`);
  };
  

  return (
    <Flex
      pl={16}
      w="100%"
      bg="#F8F8FB"
      justify="space-between"
    >
      <Box maw="50vw">
        <Flex h={24}>
          <Tooltip label="Back to ideas page">
            <Stack
              mt={4}
              align="center"
              justify="center"
              h={20}
              w={20}
              mr={4}
              sx={{ cursor: "pointer" }}
              onClick={handleBackClick}
            >
              <IconArrowLeft size={19} />
            </Stack>
          </Tooltip>
          <Tooltip label={title} disabled={editing} maw={600} multiline>
            <Flex
              ref={ref}
              p={3}
              align="center"
              sx={{
                border: editing
                  ? "1.6px solid rgba(92, 92, 235, 0.3)"
                  : hovered
                    ? "1.6px solid #D8D8DB"
                    : "1.6px solid transparent",
                borderRadius: "6px",
              }}
            >
              
              <TextInput
                fw={600}
                placeholder="Enter title"
                radius={0}
                variant="default"
                w= {editing ? "49vw" : (titleWidth + 10)}
                maw="42vw"
                styles={() => ({
                  input: {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    border: "none",
                    fontSize: "20px",
                    fontWeight: "bold",
                    backgroundColor: "#F8F8FB",
                    margin: "0px",
                    padding: "0px",
                    "&:focus-within": {
                      border: "none",
                    },
                    minHeight: 20,
                    height: 24,
                  },
                })}
                value={title}
                onClick={() => {
                  if (!editing) {
                    setEditing(true);
                  }
                }}
                onChange={(e: any) => {
                  setTitle(e.target.value);
                  longTitleCheck();
                }}
                onBlur={(e: any) => {
                  setEditing(false);
                  updateIdeaTitle(e.target.value);
                }}
              />
          
            </Flex>
          </Tooltip>
        </Flex>
        {!reviewMode && (
          <Tooltip label={description} multiline disabled={!isLongDescription} w={600} >
            <Flex
              mt={2}
              ml={27}
              maw="650px"
            >
              <Tooltip label='Edit description'>
                <Flex h={18} sx={{ cursor: "pointer" }} onClick={() => setReviewMode(!reviewMode)}>
                  <Text
                    lineClamp={1}
                    ta="start"
                    maw={270}
                    fz={14}
                  >
                    {description || ""}
                  </Text>
                </Flex>
              </Tooltip>
              
              <Flex sx={{ cursor: "pointer" }} onClick={() => setReviewMode(!reviewMode)}>
                <Text
                  ml="6px" 
                  color="#5C5CEB"
                  sx={{ cursor: " pointer" }}
                  fz={14}
                  lineClamp={1}
                  w={150}
                >
                  {description?.length === 0 ? "Add description" : "More"}
                </Text>
              </Flex>
            </Flex>
          </Tooltip>
          
        )}
      </Box>
      <Box w="40vw" mt={3.8}>
        <IdeaAttributes reviewMode={reviewMode} setReviewMode={setReviewMode} />
      </Box>
    </Flex>
  );
};

export default Header;
