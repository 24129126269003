import React, { useContext, useMemo } from "react";
import IdeasContext from "../../../IdeasContext";
import { formatBigMonetaryValueIdeas } from "../../../ideas-table-helpers/Helpers";
import { Flex, Text } from "@mantine/core";
import { updateAccessorByState } from "../../../../../utils";
import Bar from "./Bar";
import { MAIN_DARK_PRIMARY } from "../../../../../constants/colors";

// Type definitions for component props
interface BarCellProps {
  data: Record<string, any>;
  accessor: string;
  renderParams?: {
    prefix?: string;
    default?: string;
    multiplyByComponentWeight?: boolean;
    backgroundColor?: string;
    topValues?: any;
  };
}

// Main component
export const BarCell: React.FC<BarCellProps> = ({
  data,
  accessor,
  renderParams = {},
}) => {
  const { sortState } = useContext(IdeasContext) as {
    sortState: "total" | "assigned" | "suggested";
  };
  const multiplyByComponentWeight =
    renderParams?.multiplyByComponentWeight || false;

  const topVal = renderParams?.topValues ? renderParams.topValues[sortState] : 0

  // Helper function to safely access nested values
  const getNestedValue = (obj: Record<string, any>, path: string): any => {
    return path
      .split(".")
      .reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
        obj
      );
  };

  // Update accessors based on the current sort state
  const totalAccessor = updateAccessorByState(accessor, "total");
  const assignedAccessor = updateAccessorByState(accessor, "assigned");
  const suggestedAccessor = updateAccessorByState(accessor, "suggested");

  // Retrieve values from the data object
  const totalValue = getNestedValue(data, totalAccessor);
  const assignedValue = getNestedValue(data, assignedAccessor);
  const suggestedValue = getNestedValue(data, suggestedAccessor);

  // Retrieve component weight, defaulting to 1 if not present
  const componentWeight = data.componentObj?.weight || 1;

  // Calculate multiplied values if required
  const multipliedTotalValue = multiplyByComponentWeight
    ? totalValue * componentWeight
    : totalValue;
  const multipliedAssignedValue = multiplyByComponentWeight
    ? assignedValue * componentWeight
    : assignedValue;
  const multipliedSuggestedValue = multiplyByComponentWeight
    ? suggestedValue * componentWeight
    : suggestedValue;

  const relevantValue =
    sortState === "suggested"
      ? multipliedSuggestedValue
      : sortState === "assigned"
        ? multipliedAssignedValue
        : sortState === "total"
          ? multipliedTotalValue
          : multipliedTotalValue;

  const multipliedTopValue = multiplyByComponentWeight
    ? topVal * componentWeight
    : topVal;
  const percVal = (topVal === 0) ? 0 : (relevantValue / multipliedTopValue) * 100;

  // Determine what to display based on sortState
  let displayContent;

  switch (sortState) {
  case "total":
    displayContent = (
      <Flex>
        <Text fz={12} weight={600} color={MAIN_DARK_PRIMARY} style={{ textAlign: 'left' }}>
          {formatBigMonetaryValueIdeas(
            multipliedTotalValue || 0,
            renderParams.prefix
          )}
        </Text>
      </Flex>
    );
    break;

  case "assigned":
    displayContent = (
      <Text fz={12} weight={600} color={MAIN_DARK_PRIMARY} style={{ textAlign: 'left' }}>
        {formatBigMonetaryValueIdeas(
          multipliedAssignedValue || 0,
          renderParams.prefix
        )}
      </Text>
    );
    break;

  case "suggested":
    displayContent = (
      <Text fz={12} weight={600} color={MAIN_DARK_PRIMARY} style={{ textAlign: 'left' }}>
        {formatBigMonetaryValueIdeas(
          multipliedSuggestedValue || 0,
          renderParams.prefix
        )}
      </Text>
    );
    break;

  default:
    displayContent = <Text>{renderParams.default || "-"}</Text>;
  }

  return (
    <Bar displayContent={displayContent} barWidthPercantage={percVal || 0} />
  );
};
