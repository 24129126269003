import { Box, Flex } from "@mantine/core";
import { Actions } from "../Actions";
import { EvidenceDetails } from "./EvidenceDetails";
import { MentionProvider } from "../../../MentionTextarea/MentionContext";
import RightSide from "../RightSide";
import { EvidenceModalType } from "..";
import { useState } from "react";

export interface ModalContentInterface {
  idea: any;
  request: any;
  customizations: any;
  setValidatedEvidences: React.Dispatch<React.SetStateAction<any>>;
  setOpened?: (bool: boolean) => void;
  setMatches: React.Dispatch<React.SetStateAction<any>>;
  matches: any;
}

export function ModalContent({
  evidence,
  setEvidence,
  evidencesHandlers
}: EvidenceModalType) {

  return (
    <Box>
      <Actions
        evidence={evidence}
        setEvidence={setEvidence}
        evidencesHandlers={evidencesHandlers}
      />
      <Flex>
        <Box w="55%">
          <EvidenceDetails evidence={evidence} />
        </Box>
        <Box w="45%">
          <MentionProvider>
            <RightSide
              evidence={evidence}
              setEvidence={setEvidence}
              evidencesHandlers={evidencesHandlers}
            />
          </MentionProvider>
        </Box>
      </Flex>
    </Box>
  );
}
