import { Box } from "@mantine/core";
import Comment from "./Comment";
import { MentionSuggestion } from "../../../MentionTextarea";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { searchMembers } from "../../../../Api";
import { UseCommentsReturn } from "../../../../hooks/useComments";
import { useMentions } from "../../../MentionTextarea/MentionContext";

export function CommentsNiv({
  comments,
  loading,
  addComment,
  deleteComment,
  editComment,
}: UseCommentsReturn) {

  const auth0 = useAuth0();

  const { setSuggestions, setLoading: setSuggestionsLoading } = useMentions();

  // Local state to manage the comment input
  const [commentValue, setCommentValue] = useState("");

  // Function to handle comment submission
  const handleSubmitComment = async () => {
    if (!commentValue.trim()) return; // Prevent submitting empty comments
    await addComment(commentValue.trim()); // Add the comment using the hook's function
    setCommentValue(""); // Reset the input field after submission
  };

  // Handle user search for mentions using searchMembers function
  const searchUser = async ({
    query,
  }: {
    query: string;
  }): Promise<MentionSuggestion[]> => {
    if (!query.trim()) return [];
    setSuggestionsLoading(true);
    try {
      // Utilize the imported searchMembers function
      const users = await searchMembers(query, auth0);

      const usersSuggestions = users.map((user: any) => ({
        id: user._id,
        email: user.email,
        name: user.name,
        picture: user.picture,
      }));
      setSuggestions(usersSuggestions);
      return usersSuggestions;
    } catch (error) {
      console.error("Error searching users:", error);
      return [];
    } finally {
      setSuggestionsLoading(false);
    }
  };

  return (
    <>
      {/* <Grid align="flex-start" gutter="xs" p={0}>
        <Grid.Col span={12}>
          <Box
          // sx={{ overflowY: "scroll", overflowX: "hidden", maxHeight: 400 }}
          > */}
      {comments.map((comment: any, index: number) => (
        <Box>
          <Comment
            comment={comment}
            index={index}
            key={comment._id}
            handleSubmitComment={handleSubmitComment}
            editComment={editComment}
            deleteComment={deleteComment}
            searchUser={searchUser}
            loading={loading}
          />
        </Box>
      ))}
    </>
  );
}
