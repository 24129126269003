import {
  Box,
  Button,
  Text,
  Group,
  TextInput,
  Tooltip,
  Flex,
  Select,
  Stack,
  ScrollArea,
  Textarea,
  Popover,
} from "@mantine/core";
import {  IconCheck, IconX } from "@tabler/icons";
import React, { useContext, useEffect, useState } from "react";
import SyncContext from "../../../../context/SyncContext";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addRequestsToIdea,
  searchOpportunityNiv,
  searchOrgs,
} from "../../../../Api";
import IdeaContext from "../../IdeaContext";
import { useThrottle } from "../../../../utils";
import { showNotification } from "@mantine/notifications";
import {Loader} from "../../../../icons/x-symbol-svgrepo-com";

const AddEvidenceScreen = ({
  addEvidenceFormScreen,
  setAddEvidenceFormScreen,
  navToPerspective,
  reviewMode
  
}: {
  addEvidenceFormScreen: boolean;
  setAddEvidenceFormScreen: React.Dispatch<React.SetStateAction<boolean>>;
  navToPerspective: any;
  reviewMode: boolean
}) => {
  const auth0 = useAuth0();

  const { priorityData, defaultRequestPriority } = useContext(SyncContext);
  const { ideaId, loadEvidence, idea } = useContext(IdeaContext);

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  const [priority, setPriority] = useState(defaultRequestPriority);
  const [requestType, setRequestType] = useState("customer");
  const [accountSearchOpened, setAccountSearchOpened] = useState(false);
  const [accountSearchLoading, setAccountSearchLoading] = useState(false);

  const [searchAccountQuery, setSearchAccountQuery] = useState("");
  const [accountsResults, setAccountsResults] = useState([]);
  const [opportunitiesResults, setOpportunitiesResults] = useState([]);
  const [opportunityAccountName, setOpportunityAccountName] = useState("");
  const [accountName, setAccountName] = useState<string>("");
  const [accountsNames, setAccountsNames] = useState<string[]>([]);
  /* console.log(opportunitiesResults) */
  const [accountId, setAccountId] = useState("");
  const [account, setAccount] = useState<any>(null);


  const SALESFORCE = "salesforce";
  const [loading, setLoading] = useState(false);

  const searchAccounts = () => {
    if (!accountName || accountName === '') {
      setAccountsResults([]);
      return
    }
    setAccountSearchLoading(true);
    searchOrgs({query: accountName}, auth0).then((res) => {
      setAccountsResults(res.accounts);
      const accounts = res.accounts;
      const mapped = accounts.map((a: any) => (a.value = a.name));
      setAccountsNames(mapped);
      setAccountSearchLoading(false);
    });
  };

  const searchOpportunity = () => {
    searchOpportunityNiv(accountId, auth0).then((res) => {
      setOpportunitiesResults(res.opportunities);
    });
  };

  const handleAttach = (request: any) => {
    setLoading(true);
    if (request.itemType === "account") {
      if (!request.origin) {
        //if this is a cxItem account and not a cxOrg
        // check if the request has salesforce id
        let hasSalesforce = request.sfId;
        if (!hasSalesforce) {
          // support for sources on cxOrgs
          request?.sources?.forEach((source: any) => {
            if (source.origin === SALESFORCE) {
              hasSalesforce = true;
            }
          });
        }
        if (hasSalesforce) {
          request.origin = SALESFORCE;
        } else {
          request.origin =
            request?.sources?.length > 0
              ? request?.sources[0]?.origin
              : undefined;
        }
      }
    }

    const newRequest: any = {
      title: title,
      type: 'request',
      componentId: idea?.componentId,
      origin: request.origin,
      originId: request.originId,
      orgId: account?._id || request.company?._id || request.orgId,
      contactId: request.contactId,
      internalContactId: request.internalContactId,
      priority: priority || defaultRequestPriority,
      committedDate: request.committed,
      text: text,
      businessName: "PM Feedback",
      // itemId: account?._id,
      ownerId: idea?.ownerId,
      sourceOfAssignment: 'bagel'
    };
    ideaId && addRequestsToIdea(auth0, ideaId, newRequest)
      .then((res) => {
        console.log(res);
        
        loadEvidence();

      })
      .catch((e) => console.log(e))
      .finally(() => {
        navToPerspective()
        showNotification({
          title: "Evidence added successfully",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });
        setLoading(false);
      });
  };

  const throttledSearchAccounts = useThrottle(() => searchAccounts(), 1000);

  useEffect(() => {
    if (accountName === '') {
      setAccountsResults([]);
      return
    }
    throttledSearchAccounts();
  }, [accountName]);


  return (
    <Stack
      px="md"
      /* h={`calc(100vh - ${reviewMode ? 407 : 437}px)`} */
      // bg="#FDFEFF"
      /* h="70%" */ /* my="24px" */ /* align="center" */ 
      // w="496px"
      /* py='24px' */ 
      /* h="100%" */
      w="-webkit-fill-available"
      /* h="40px" */
    >
      {/*  Header */}

      <Flex justify="space-between" mb={16} align="center" mt={24} h='20px'>
        <Text fz="20px" fw={600}>
          Add Evidence
        </Text>
        <Flex
          mr="8px"
          sx={{ cursor: " pointer" }}
          onClick={() => {
            setAddEvidenceFormScreen(!addEvidenceFormScreen);
          }}
        >
          <IconX size={16} color="#5C5CEB" />
          <Text ml="3px" color="#5C5CEB" fz={12}>
            Close
          </Text>
        </Flex>
      </Flex>

      {/* Title */}
      <Box /* mb='14px' */>
        <Flex mb={8} h='18px'>
          <Text fz="14px" fw={600}  mr="3px">
          Title
          </Text>
          {title?.length < 3 && (
            <Text
              fz="13px"
              ta="center"
              style={{ color: "red", verticalAlign: "middle" }}
            >
            *
            </Text>
          )}
        </Flex>

        <Box h='40px'>
          <TextInput
            // w="424px"
            withAsterisk
            placeholder="What would you like to add?"
            value={title}
            onChange={(e: any) => {
              setTitle(e.target.value);
            }}
            styles={() => ({
        
              input: {
                height: '40px',
                borderRadius: "8px",
                margin:'0px',
                paddingTop:'0px',
                paddingBottom:'0px', 
              },
            })}
          />
        </Box>
        
      </Box>
      

      {/* Description */}

      <Flex h='18px'>
        <Text fz="14px" fw={600} mb={8} mr="3px">
          Description
        </Text>
        {text?.length < 3 && (
          <Text
            fz="13px"
            ta="center"
            style={{ color: "red", verticalAlign: "middle" }}
          >
            *
          </Text>
        )}
      </Flex>

      <Textarea
        /* h="90px" */
        // w="424px"
        /* mb={24} */
        withAsterisk
        placeholder="What problem or need should it solve? Who is it for (persona)?"
        value={text}
        onChange={(e: any) => {
          setText(e.target.value);
        }}
        styles={() => ({
          input: {
            borderRadius: "8px",
            height: "75px",
          },
        })}
      />

      {/* Priority */}

      <Text fz="14px" fw={600} /* mb={8} */ h='18px'>
        Priority
      </Text>

      <Select
        mb={14} 
        value={priority}
        onChange={setPriority}
        placeholder="Pick one"
        data={priorityData}
        styles={() => ({
          input: {
            borderRadius: "8px",
            margin: '0px'
          },
        })}
      />

      {/* Customer */}
      <Flex>
        <Text fz="14px" fw={600} /* mb={8} */ h='18px'>
        Select customer
        </Text>
        {!account && <Text h={3} color='red' ml={2} fz="14px">*</Text>}
      </Flex>
      

      <Popover opened={accountSearchOpened} onChange={setAccountSearchOpened}>
        <Popover.Target>
          <TextInput
            mb={16}
            value={account?.name || accountName}
            onChange={(e: any) => {
              setAccountName(e.target.value);
            }}
            onFocus={() => {
              setAccountSearchOpened(true)
              setAccountName('')
              setAccount(undefined)
            }}
            placeholder="Search account"
            styles={() => ({
              input: {
                borderRadius: "8px",
              },
            })}
          />
        </Popover.Target>
        <Popover.Dropdown>
          <ScrollArea.Autosize /* maxHeight={300} */ w={410}>
            {accountSearchLoading && (<Loader height={40} width={40} />)}
            {!accountSearchLoading && accountsResults.length === 0 && (<Text fz={"xs"}>No Results</Text>)}
            {!accountSearchLoading && accountsResults.map((item: any) => (
              <Box
                px="5px"
                py="5px"
                fz={"sm"}
                mih={"50px"}
                onClick={() => {
                  setAccount(item);
                  setAccountSearchOpened(false);
                }}
                sx={{
                  border /* Bottom */: "1px solid #D8D8DB",
                  borderRadius: "6px",
                  width: '410px',
                  marginTop: '10px',
                  cursor:'pointer'
                }}
              >
                <Flex mb="4px">
                  <Text fw={600} mr="4px">
                    Account:
                  </Text>
                  <Text fw={600}>{item?.name}</Text>
                </Flex>
                <Flex>
                  <Flex w="50%">
                    <Text c="dimmed" mr="4px">
                      Type:
                    </Text>
                    <Text tt="capitalize">
                      {item?.company?.type || item?.accountType || "N/A"}
                    </Text>
                  </Flex>

                  <Flex w="50%">
                    <Text c="dimmed" mr="4px">
                      Plan:
                    </Text>
                    <Tooltip
                      label={item?.company?.plan || item.plan || "N/A"}
                      multiline
                    >
                      <Text lineClamp={1} tt="capitalize">
                        {item?.company?.plan || item.plan || "N/A"}
                      </Text>
                    </Tooltip>
                  </Flex>
                </Flex>

                <Flex>
                  <Flex w="50%">
                    <Text c="dimmed" mr="4px">
                      Arr:
                    </Text>
                    <Text tt="capitalize">
                      {item?.company?.arr?.toLocaleString() ||
                        item?.arr?.toLocaleString() ||
                        "N/A"}
                    </Text>
                  </Flex>

                  <Flex w="50%">
                    <Text c="dimmed" mr="4px">
                      employeeCount:
                    </Text>

                    <Text lineClamp={1} tt="capitalize">
                      {item?.company?.employeeCount?.toLocaleString() ||
                        item?.employeeCount?.toLocaleString() ||
                        "N/A"}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            ))}
          </ScrollArea.Autosize>
        </Popover.Dropdown>
      </Popover>




      {/* Add */}

      <Group position="right" mt="lg" mb="24px">
        <Button
          onClick={() => {
            handleAttach(account);
          }}
          type="submit"
          disabled={title.length < 3 || text.length < 3 || !account}
          loading={loading}
          styles={(theme) => ({
            root: {
              backgroundColor: "#5B60E3",
              border: 0,
              height: 56,
              width: 174,
              "&:hover": {
                backgroundColor: theme.fn.darken("#5B60E3", 0.05),
              },
            },
          })}
        >
          Add as evidence
        </Button>
      </Group>
    </Stack>
  );
};

export default AddEvidenceScreen;

// Phase 2- connect oppurtunity

{
  /* <Modal
          size="40vw"
          opened={opened}
          onClose={() => setOpened(false)}
          title={accountModal ? "Connect an account" : "Connect an opportunity"}
        >
          <Stack h="80vh">
            <Menu opened>
              <Menu.Target>
                <TextInput
                  mt="md"
                  withAsterisk
                  label={
                    accountModal ? "Add account" : "Connect an opportunity"
                  }
                  placeholder={
                    accountModal ? "Search account" : "Add related opportunity"
                  }
                  value={searchAccountQuery}
                  onChange={(event) => {
                    setSearchAccountQuery(event.currentTarget.value);
                    accountModal
                      ? event.currentTarget.value !== "" &&
                        searchAccounts(event.currentTarget.value)
                      : event.currentTarget.value !== "" && console;
                  }}
                />
              </Menu.Target>
              <Menu.Dropdown>
                <Box w="32vw" h="56vh">
                  {accountsResults?.length < 1 || searchAccountQuery === "" ? (
                    <Text>
                      {accountModal
                        ? "No Accounts found"
                        : "No opportunity items found"}
                    </Text>
                  ) : (
                    <ScrollArea h="50vh">
                      {accountModal
                        ? accountsResults?.map((a: any) => (
                          <Menu.Item>
                            <Card
                              h="150px"
                              withBorder={accountId === a.orgId}
                              shadow={accountId === a.orgId ? "lg" : ""}
                              onClick={() => {
                                setAccountId(a.orgId);
                                setAccount(a);
                              }}
                            >
                              <Grid>
                                <Grid.Col span={6}>
                                  <Flex>
                                    <Text>Name: </Text>
                                    <Text ml="sm">{a?.name}</Text>
                                  </Flex>
                                </Grid.Col>
                                <Grid.Col span={6}>
                                  <Flex>
                                    <Text ml="lg">Type: </Text>
                                    <Text ml="sm">
                                      {a?.type || a.accountType || ""}
                                    </Text>
                                  </Flex>
                                </Grid.Col>
                              </Grid>
                            </Card>
                          </Menu.Item>
                        ))
                        : opportunitiesResults?.map((a: any) => (
                          <Menu.Item>
                            <Card
                              h="150px"
                              withBorder={accountId === a.orgId}
                              shadow={accountId === a.orgId ? "lg" : ""}
                              onClick={() => {
                                setAccountId(a.orgId);
                                setAccount(a);
                              }}
                            >
                              <Grid>
                                <Grid.Col span={6}>
                                  <Flex>
                                    <Text>Title: </Text>
                                    <Text ml="sm">{a?.title}</Text>
                                  </Flex>
                                </Grid.Col>
                                <Grid.Col span={6}>
                                  <Flex>
                                    <Text ml="lg">Type: </Text>
                                    <Text ml="sm">
                                      {a?.type || a.opportunityType || ""}
                                    </Text>
                                  </Flex>
                                </Grid.Col>
                              </Grid>
                            </Card>
                          </Menu.Item>
                        ))}
                    </ScrollArea>
                  )}
                </Box>
              </Menu.Dropdown>
            </Menu>
            <Stack align="center">
              <Button
                w="125px"
                mt="60vh"
                onClick={() => {
                  searchOpportunity();
                  setOpened(!opened);
                }}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </Modal> */
}

{
  /* <Stack align="center" mt='lg'> // To be implemented in phase 2
            <SegmentedControl
              mb="lg"
              value={requestType}
              onChange={setrequestType}
              data={[
                { label: "Customer Request", value: "customer" },
                { label: "Internal Request", value: "internal" },
              ]}
            />
          </Stack> */
}

{
  /* <Stack justify="center" w='100%'> */
}
{
  /* <Button
              w='200px'
              styles={(theme) => ({
                root: {
                  backgroundColor: "#5B60E3",
                  border: 0,
                  height: 35,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                  },
                },
              })}
              onClick={() => {
                setAccountModal(true);
                setOpened(true);
              }}
            >
              {requestType === "customer"
                ? "Select customer"
                : "Select internal stakeholder"}
            </Button> */
}
{
  /* <Flex>
                <Text>Account: </Text>
                <Text ml="md"> {account?.name || ""}</Text>
              </Flex> */
}
{
  /* <Button
                disabled={!accountId || opened}
                onClick={() => {
                  setAccountModal(false);
                  setOpened(true);
                }}
              >
                Select opurtunity
              </Button> */
}
{
  /* </Stack> */
}