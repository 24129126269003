import React, { useContext, useMemo, useState } from "react";
import {
  Text,
  Box,
  Flex,
  Button,
  Tooltip,
  Paper,
  Group,
  Sx,
  Modal,
} from "@mantine/core";
import ReactTimeAgo from "react-time-ago";
import {
  IconCoin,
  IconAlertOctagon,
  IconCategory,
  IconUsers,
} from "@tabler/icons";
import { SourceIcon } from "../../utils/SourceIcon";
import { UseListStateHandlers } from "@mantine/hooks";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { DeclineModalContent } from "../idea/RightBar/DeclineModalContent";
import { openModal } from "@mantine/modals";
import LogoIcon from "../layouts/LogoIcon";
import { ProductAreaIcon } from "../../icons/x-symbol-svgrepo-com";
import { stripHtml } from "../../utils";
import SyncContext from "../../context/SyncContext";
import { OverflownText } from "../OverflownText";


interface EvidenceCardProps {
  evidence: any;
  evidences:any[];
  onAction: () => void;
  members: any[];
  handleModalOpen: (evidence: any, modalType: string) => void;
  evidencesHandlers?: UseListStateHandlers<any[]>;
}

interface PriorityColor {
  color: string;
  bgColor: string;
}

export const InfoTooltip: React.FC<{
  label: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  sx?: Sx;
}> = ({ label, icon, children, sx }) => (
  <Tooltip label={label}>
    <Group
      noWrap
      spacing={3}
      h="32px"
      px="sm"
      py={0}
      align="center"
      sx={{
        cursor: "default",
        borderRadius: "5px",
        background: "#F8F9FB",
        alignContent: "center",
        ...sx,
      }}
    >
      {icon && <Box pt={5} mr={5}>{icon}</Box>}
      <Text fz="14px">{children}</Text>
    </Group>
  </Tooltip>
);

const EvidenceCard: React.FC<EvidenceCardProps> = ({
  evidence,
  evidences,
  onAction,
  handleModalOpen,
  evidencesHandlers
}) => {
  const { requestPriorities } = useContext(SyncContext);

  // console.log({ evidence });
  evidence = evidences.find((ev:any) => ev._id == evidence._id)

  const initialAmount = evidence?.cxItem?.amount;
  const origin =
    evidence?.cxItem?.trueOrigin ||
    evidence?.cxItem?.origin ||
    evidence?.origin ||
    "Unassigned";

  const priorityInfo = useMemo(() => {
    if (!requestPriorities) return null;

    const sortedPriorities = [...requestPriorities].sort(
      (a: any, b: any) => a.index - b.index
    );
    const defaultPriority = sortedPriorities.find((p: any) => p?.isDefault);

    const colorsByPriorityIndex: PriorityColor[] = [
      { color: "#DB2525", bgColor: "#FFF9F9" },
      { color: "#FF8700", bgColor: "#FFFAF2" },
      { color: "#2684FF", bgColor: "#F7FAFF" },
      { color: "#0BAC4D", bgColor: "#F5FEF9" },
    ];

    const index = sortedPriorities.findIndex(
      (p: any) =>
        p.key?.toLowerCase() === evidence.priority?.toLowerCase() ||
        p.label?.toLowerCase() === evidence.priority?.toLowerCase()
    );

    const clampedIndex =
      index >= 0 && index < colorsByPriorityIndex.length
        ? index
        : colorsByPriorityIndex.length - 1;

    const currentPriority = sortedPriorities[index] || defaultPriority;

    return currentPriority
      ? {
        color: colorsByPriorityIndex[clampedIndex].color,
        bgColor: colorsByPriorityIndex[clampedIndex].bgColor,
        label: currentPriority.label || "Unassigned",
      }
      : {
        color: colorsByPriorityIndex[2].color,
        bgColor: colorsByPriorityIndex[2].bgColor,
        label: "Unassigned",
      };
  }, [requestPriorities, evidence.priority]);

  const openDeclineModal = () =>
    openModal({
      modalId: "decline",
      title: <LogoIcon />,
      centered: true,
      size: 600,
      children: (
        <DeclineModalContent
          evidence={evidence}
          onUpdated={onAction}
          evidencesHandlers={evidencesHandlers}
          // idea={evidence.idea}
        />
      ),
    });

  const submitter = evidence.submitter;
  const submitterName = submitter?.name || submitter?.displayName || "Unknown";

  const icon = useMemo(() => {
    const size = origin === "zendesk" ? 18 : 25;
    return <SourceIcon sourceName={origin} width={size} height={size} />;
  }, [origin]);

  const productArea =
    evidence?.componentObj?.name ||
    evidence?.idea?.componentObj?.name ||
    "Unassigned";

  const ownerName = evidence?.owner?.name || "Unassigned";

  return (
    <Paper
      m="sm"
      p="sm"
      mb={0}
      withBorder
      sx={{
        position: "relative",
        "&:hover": {
          cursor: "pointer",
          borderColor: "#5B60E3",
        },
        "&:hover .hover-actions": {
          opacity: 1,
        },
      }}
      onClick={() => {
        // setSearchParams({ requestId: evidence._id });
        // setOpened(true)
        handleModalOpen(evidence, "request")
      }}
    >
      {/* Hover Actions Container */}
      <Flex
        className="hover-actions"
        justify="flex-end"
        align="center"
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          opacity: 0,
          transition: "opacity 0.3s",
          zIndex: 10,
        }}
        onClick={(e) => e.stopPropagation()} // Prevent card click when interacting with buttons
      >
        <Tooltip
          withArrow
          position="top"
          label="You can provide a reason in the next step"
        >
          <Button
            variant="outline"
            color="indigo"
            size="xs"
            sx={{ borderRadius: "5px", marginRight: "7px" }}
            onClick={openDeclineModal}
          >
            Decline
          </Button>
        </Tooltip>
        <Button
          variant="filled"
          color="indigo"
          size="xs"
          sx={(theme) => ({
            backgroundColor: "#5B60E3",
            border: 0,
            // height: 35,
            "&:hover": {
              backgroundColor: theme.fn.darken("#5B60E3", 0.05),
            },
            borderRadius: "5px",
          })}
          onClick={(e) => {
            e.stopPropagation();
            handleModalOpen(evidence, "review");
          }}
        >
          Review
        </Button>
      </Flex>

      <Box mb="md">
        <Group align="center" spacing={0}>
          <Text
            fw={500}
            mr={7}
            maw="800px"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {evidence.title}
          </Text>
          {evidence.createdAt && (
            <Text fz="11px" color="#998e9c" mt="5.74px">
              <ReactTimeAgo date={new Date(evidence.createdAt)} />
            </Text>
          )}
          {submitterName && (
            <Text ml={4.75} fz="11px" color="#998e9c" mt="5.74px">
              by {submitterName}
            </Text>
          )}
        </Group>

        {evidence.text && (
          <OverflownText
            lineClamp={1}
            fz="13px"
            sx={{
              lineHeight: "20px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {stripHtml(evidence.text)}
          </OverflownText>
        )}
      </Box>

      <Group mt={10} spacing={8}>
        {priorityInfo && (
          <InfoTooltip
            label="Priority"
            icon={<IconAlertOctagon size={18} color={priorityInfo.color} />}
            sx={{
              background: priorityInfo.bgColor,
              color: priorityInfo.color,
              border: `1px solid ${priorityInfo.color}35`,
            }}
          >
            {priorityInfo.label}
          </InfoTooltip>
        )}

        {evidence?.company?.name? (
          <InfoTooltip
            label="Account"
            icon={<IconUsers color="black" size={16} />}
          >
            {evidence.company?.name}
          </InfoTooltip>
        ) : null}

        {initialAmount && initialAmount !== 0 && (
          <InfoTooltip
            label="Amount"
            icon={<IconCoin color="black" size={18} />}
          >
            ${initialAmount.toLocaleString()}
          </InfoTooltip>
        )}

        {evidence.cxItem?.opportunityType && (
          <InfoTooltip
            label="Opportunity Type"
            icon={<IconCategory color="black" size={14} />}
          >
            {evidence.cxItem.opportunityType}
          </InfoTooltip>
        )}

        {origin && (
          <Tooltip label={"Origin"}>
            <Group
              noWrap
              spacing={3}
              h="32px"
              px="sm"
              py={0}
              bg="#F8F9FB"
              align="center"
              sx={{ cursor: "default", borderRadius: "5px" }}
            >
              {icon}
              <Text fz="14px" color="black">
                {capitalizeFirstLetter(origin)}
              </Text>
            </Group>
          </Tooltip>
        )}

        {productArea && (
          <InfoTooltip
            label="Product Area"
            icon={<ProductAreaIcon color="black" />}
          >
            {capitalizeFirstLetter(productArea)}
          </InfoTooltip>
        )}

        {ownerName && (
          <InfoTooltip
            label="Owner"
            icon={<IconUsers color="black" size={16} />}
          >
            {capitalizeFirstLetter(ownerName)}
          </InfoTooltip>
        )}
      </Group>
    </Paper>

  );
};

export default React.memo(EvidenceCard);
