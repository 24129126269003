import {Checkbox, Flex, Group, Loader, Tabs, Text, Tooltip} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import IdeaContext from "../IdeaContext";

const InitialHeaderRow = () => {
  const {
    matches,
    displayedRequests,
    displayedMatches,
    matchesLoading,
    // computedValue,
    showmatches,
    setshowmatches,
    showValidated,
    setShowValidated,
    displayedRequestsMatches,
    setShowPending,
    // displayedPendingRequests,
    // displayedValidatedRequests,
    activeInboxTab,
    setActiveInboxTab
  } = useContext(IdeaContext);


  const [inboxItems, setInboxItems] = useState<any[]>([])
  const [activeTab, setActiveTab] = useState<string | null>('');
  const [defaultTab, setDefaultTab] = useState<string | null>('');

  const validated = displayedRequests.filter(
    (r: any) => (r.type === 'gap' && r.ideaId)  || (r.type === 'request' && r.state === 'validated')
  );

  const tabsFunc = (activeMatches: boolean, activeValidated: boolean, activePending: boolean, inboxTab: boolean) => {
    setActiveInboxTab(inboxTab)
    setshowmatches(activeMatches)
    setShowValidated(activeValidated)
    setShowPending(activePending)
  }

  useEffect(() => {
    if(!showmatches){
      setDefaultTab("validated")
      setActiveTab("validated")
    }
    else{
      setDefaultTab("all")
      setActiveTab("all")
    }
  }, [defaultTab]);

  useEffect(() => {

    const _pendingRequests = displayedRequests.filter(
      (r: any) => r.state === "inReview"
    );
    const inbox = [..._pendingRequests, ...displayedRequestsMatches]
    setInboxItems(inbox)

  }, [displayedRequests, displayedRequestsMatches]);

  return (

    <Tabs color="indigo" w='100%' value={activeTab} onTabChange={setActiveTab} defaultValue={defaultTab}>

      <Tabs.List >
        <Tabs.Tab
          /* pl={0}  */
          value="all"
          onClick={() => {
            tabsFunc(true, true, true, false);
          }}
        >
          <Flex pl={7} pr={1}>
            <Text fz={12} color={(showmatches && showValidated) ? '#212529' : '#5C5CEB'}>
            All evidence
            </Text>
            <Text fz={12} color={'#5C5CEB'} ml={3}>
              {(displayedRequests?.length + displayedMatches?.length + displayedRequestsMatches?.length) || 0}
            </Text>

          </Flex>

        </Tabs.Tab>
        <Tabs.Tab
          value="validated"
          onClick={() => {
            tabsFunc(false, true, false, false);
          }}
        >
          <Flex px={1}>
            <Text fz={12} color={activeTab === 'validated' ? '#212529' : '#5C5CEB'}>
            Assigned evidence
            </Text>
            <Text fz={12} color={'#5C5CEB'} ml={3}>
              {validated?.length || 0}
            </Text>

          </Flex>


        </Tabs.Tab>
        <Tabs.Tab
          value="matches"
          onClick={() => {
            tabsFunc(true, false, false, false);
          }}
        >
          <Flex>
            <Text fz={12} color={activeTab === 'matches' ? '#212529' : '#5C5CEB'}>
            Suggested evidence
            </Text>
            {(matchesLoading && displayedMatches.length == 0 ) ?
              <Loader mt={4} ml={3} color='indigo' size={12}/> :
              <Text fz={12} color={'#5C5CEB'} ml={3}>
                {!(matchesLoading && displayedMatches.length == 0) ? 
                  (displayedMatches?.length || 0) :
                  0
                }
              </Text>
            }
          </Flex>

        </Tabs.Tab>

        <Tabs.Tab
          value="Pending"
          onClick={() => {
            tabsFunc(false, false, true, true);
          }}
        >
          <Tooltip label="Formal requests">
            <Flex>
              <Text fz={12} color={ activeInboxTab ?'#212529'  :   '#5C5CEB'}>
            Inbox
              </Text>
              <Text fz={12} color={'#5C5CEB'} ml={3}>
                {(inboxItems?.length) || 0}
              </Text>
            </Flex>
          </Tooltip>


        </Tabs.Tab>
      </Tabs.List>

    </Tabs>
  );
};

export default InitialHeaderRow;
