import { Anchor, Button, Flex, Modal, Stack, Text, Tooltip } from "@mantine/core";
import { useContext, useState } from "react";
import IdeaContext from "../../../../IdeaContext";
import { reassignRequest } from "../../../../../../Api";
import { showNotification } from "@mantine/notifications";
import { useAuth0 } from "@auth0/auth0-react";
import { IconCheck, IconX } from "@tabler/icons";
import useEvidenceActions from "../../../../../../hooks/useEvidenceActions";

const AssignmentVerificationModal = ({
  verifyOpened,
  setVerifyOpened,
  navToPerspective,
  reassignedRequest
}: {
  verifyOpened: boolean;
  setVerifyOpened: React.Dispatch<React.SetStateAction<boolean>>;
  navToPerspective: any
  reassignedRequest: any
}) => {

  const auth0 = useAuth0();

  const { chosenEvidence, idea, loadEvidence } = useContext(IdeaContext);
  const { validate, isLoading } = useEvidenceActions({ evidenceId: chosenEvidence._id });

  // const [loading, setLoading] = useState(false);
  
  const assignedIdea = (chosenEvidence?.ideas && chosenEvidence?.ideas?.length > 0)
    ? chosenEvidence?.ideas[0]
    : null;

  const handleReassignRequestWithTextItemFromMatch = ( evidenceId: string, matchId: string) => {
    // setLoading(true)
    // reassignRequest(auth0, idea?._id, evidenceId , true , matchId, true)

    validate({
      ideaId:idea?._id,
      matchId,
      sourceOfAssignment:"suggestion"
    })
      .then(() => {
        showNotification({
          title: "Eviedence has been re-assigned successfuly",
          color: "teal",
          message: "",
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });
        setVerifyOpened(false);
        // setLoading(false)
        loadEvidence();
        navToPerspective();
      })
      .catch((e) => {
        console.log(e);
        showNotification({
          title: "Error re-assigning request",
          color: "red",
          message: "please try again...",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200
        });
      });
  }




  return (
    <Modal
      yOffset={223}
      opened={verifyOpened}
      onClose={() => setVerifyOpened(false)}
      h="40vw"
      size="30vw"
    >
      <Stack mx="md" mb={5}>
        <Stack /* align="center" */ mb={5}>
          <Text fz={14} mr={5} lineClamp={1}>
            This Evidence is already assigned to another Idea:
          </Text>
          <Anchor
            href={`/idea/${assignedIdea?._id}`}
            target="_blank"
          >
            <Tooltip disabled={!assignedIdea?.title} label={assignedIdea?.title}>
              <Text color="indigo" fz={14} weight={600} lineClamp={1}>
                {assignedIdea?.title}
              </Text>
            </Tooltip>
          </Anchor>
        </Stack>
        <Text fz={14}>
          Would you like to reassign this Evidence to the current Idea?
        </Text>

        <Flex justify="space-around" style={{ width: "100%" }} mt={13}>
          <Button
            mx="md"
            onClick={() => setVerifyOpened(false)}
            styles={(theme) => ({
              root: {
                height: "32px",
                backgroundColor: "#DB2525",
                fontWeight: 400,
                "&:hover": {
                  backgroundColor: theme.fn.darken("#DB2525", 0.05),
                },
              },

              leftIcon: {
                marginRight: 15,
              },
            })}
          >
            Cancel
          </Button>

          <Button
            mx="md"
            styles={(theme) => ({
              root: {
                height: "32px",
                backgroundColor: "#5C5CEB",
                fontWeight: 400,
                "&:hover": {
                  backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                },
              },

              leftIcon: {
                marginRight: 15,
              },
            })}
            onClick={() => handleReassignRequestWithTextItemFromMatch(chosenEvidence?._id, chosenEvidence?.match?._id)} 
            loading={isLoading.validate}
          >
            Reassign
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
};

export default AssignmentVerificationModal;
