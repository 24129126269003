import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  searchUsers,
  searchAdmins,
  searchMembers,
  searchOrgs,
  searchSelectedOrgs,
  searchIdeas,
  searchAll,
} from "../Api";

interface SearchData {
  users: any[];
  admins: any[];
  members: any[];
  orgs: any[];
  selectedOrgs: any[];
  ideas: any[];
  all: any[];
}

interface SearchLoading {
  users: boolean;
  admins: boolean;
  members: boolean;
  orgs: boolean;
  selectedOrgs: boolean;
  ideas: boolean;
  all: boolean;
}

interface SearchErrors {
  users: string | null;
  admins: string | null;
  members: string | null;
  orgs: string | null;
  selectedOrgs: string | null;
  ideas: string | null;
  all: string | null;
}

// Debounce utility
function debounce<Func extends (...args: any[]) => any>(func: Func, wait: number) {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<Func>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

const useApiSearch = () => {
  const auth0 = useAuth0();

  const [data, setData] = useState<SearchData>({
    users: [],
    admins: [],
    members: [],
    orgs: [],
    selectedOrgs: [],
    ideas: [],
    all: [],
  });

  const [loading, setLoading] = useState<SearchLoading>({
    users: false,
    admins: false,
    members: false,
    orgs: false,
    selectedOrgs: false,
    ideas: false,
    all: false,
  });

  const [errors, setErrors] = useState<SearchErrors>({
    users: null,
    admins: null,
    members: null,
    orgs: null,
    selectedOrgs: null,
    ideas: null,
    all: null,
  });


  const resetResults = useCallback((key: keyof SearchData) => {
    // Reset the specified key in data, errors, and loading
    setData((prev) => ({ ...prev, [key]: [] }));
    setErrors((prev) => ({ ...prev, [key]: null }));
    setLoading((prev) => ({ ...prev, [key]: false }));
  }, []);


  const handleSearchUsers = async (query: string) => {
    setLoading((prev) => ({ ...prev, users: true }));
    setErrors((prev) => ({ ...prev, users: null }));
    try {
      const usersData = await searchUsers(query, auth0);
      setData((prev) => ({ ...prev, users: usersData }));
    } catch (error: any) {
      console.error("Error searching users:", error);
      setErrors((prev) => ({
        ...prev,
        users: error.message || "Failed to search users",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, users: false }));
    }
  };

  const searchUsersFn = useCallback(
    debounce(handleSearchUsers, 500),
    [auth0]
  );

  const handleSearchAdmins = async (query: string) => {
    setLoading((prev) => ({ ...prev, admins: true }));
    setErrors((prev) => ({ ...prev, admins: null }));
    try {
      const adminsData = await searchAdmins(query, auth0);
      setData((prev) => ({ ...prev, admins: adminsData }));
    } catch (error: any) {
      console.error("Error searching admins:", error);
      setErrors((prev) => ({
        ...prev,
        admins: error.message || "Failed to search admins",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, admins: false }));
    }
  };

  const searchAdminsFn = useCallback(
    debounce(handleSearchAdmins, 500),
    [auth0]
  );

  const handleSearchMembers = async (query: string) => {
    setLoading((prev) => ({ ...prev, members: true }));
    setErrors((prev) => ({ ...prev, members: null }));
    try {
      const membersData = await searchMembers(query, auth0);
      setData((prev) => ({ ...prev, members: membersData }));
    } catch (error: any) {
      console.error("Error searching members:", error);
      setErrors((prev) => ({
        ...prev,
        members: error.message || "Failed to search members",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, members: false }));
    }
  };

  const searchMembersFn = useCallback(
    debounce(handleSearchMembers, 500),
    [auth0]
  );

  const handleSearchOrgs = async (params: { query: string }) => {
    setLoading((prev) => ({ ...prev, orgs: true }));
    setErrors((prev) => ({ ...prev, orgs: null }));
    try {
      const orgsData = await searchOrgs(params, auth0);
      setData((prev) => ({ ...prev, orgs: orgsData }));
    } catch (error: any) {
      console.error("Error searching orgs:", error);
      setErrors((prev) => ({
        ...prev,
        orgs: error.message || "Failed to search orgs",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, orgs: false }));
    }
  };

  const searchOrgsFn = useCallback(
    debounce(handleSearchOrgs, 500),
    [auth0]
  );

  const handleSearchSelectedOrgs = async (params: { query: string; ids?: string[] }) => {
    setLoading((prev) => ({ ...prev, selectedOrgs: true }));
    setErrors((prev) => ({ ...prev, selectedOrgs: null }));
    try {
      const selectedOrgsData = await searchSelectedOrgs(params, auth0);
      setData((prev) => ({ ...prev, selectedOrgs: selectedOrgsData }));
    } catch (error: any) {
      console.error("Error searching selected orgs:", error);
      setErrors((prev) => ({
        ...prev,
        selectedOrgs: error.message || "Failed to search selected orgs",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, selectedOrgs: false }));
    }
  };

  const searchSelectedOrgsFn = useCallback(
    debounce(handleSearchSelectedOrgs, 500),
    [auth0]
  );

  const handleSearchIdeas = async (query: string) => {
    setLoading((prev) => ({ ...prev, ideas: true }));
    setErrors((prev) => ({ ...prev, ideas: null }));
    try {
      const ideasData = await searchIdeas(query, auth0);
      setData((prev) => ({ ...prev, ideas: ideasData }));
    } catch (error: any) {
      console.error("Error searching ideas:", error);
      setErrors((prev) => ({
        ...prev,
        ideas: error.message || "Failed to search ideas",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, ideas: false }));
    }
  };

  const searchIdeasFn = useCallback(
    debounce(handleSearchIdeas, 500),
    [auth0]
  );

  const handleSearchAll = async (query: string) => {
    setLoading((prev) => ({ ...prev, all: true }));
    setErrors((prev) => ({ ...prev, all: null }));
    try {
      const allResults = await searchAll(query, auth0);
      setData((prev) => ({ ...prev, all: allResults }));
    } catch (error: any) {
      console.error("Error searching all:", error);
      setErrors((prev) => ({
        ...prev,
        all: error.message || "Failed to search all",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, all: false }));
    }
  };

  const searchAllFn = useCallback(
    debounce(handleSearchAll, 500),
    [auth0]
  );

  return {
    data,
    loading,
    errors,
    // Debounced search methods
    searchUsers: searchUsersFn,
    searchAdmins: searchAdminsFn,
    searchMembers: searchMembersFn,
    searchOrgs: searchOrgsFn,
    searchSelectedOrgs: searchSelectedOrgsFn,
    searchIdeas: searchIdeasFn,
    searchAll: searchAllFn,
    // Reset function
    resetResults,
  };
};

export default useApiSearch;