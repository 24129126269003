import { ChildProps } from "./../../../types/types";
// Helper function to get nested field values
export const getNestedFieldValue = (obj: any, fieldPath: string) => {
  // Replace "chatCompany" with "company" in the fieldPath
  const modifiedFieldPath = fieldPath.replace("chatCompany", "company").replace("chat.", "cxItem.");

  const fieldParts = modifiedFieldPath.split(".");

  // Iterate over field parts and return the nested value or null if not found
  return fieldParts.reduce((o, part) => (o ? o[part] : null), obj);
};

// Helper function to check if the condition is met
const checkCondition = (request: any, condition: Record<string, any>) => {
  if (!condition || Object.keys(condition).length === 0) return true;

  for (const [fieldPath, expectedValue] of Object.entries(condition)) {
    const modifiedFieldPath = fieldPath.replace("chatCompany", "company");
    const fieldValue = getNestedFieldValue(request, modifiedFieldPath);

    const isNotOperator =
      typeof expectedValue === "string" && expectedValue.startsWith("!");
    const comparisonValue = isNotOperator
      ? expectedValue.slice(1)
      : expectedValue;

    if (isNotOperator) {
      // Pass if the field is undefined or not equal to the comparison value
      return fieldValue !== comparisonValue;
    } else {
      // If the field value doesn't match the expected value, return false
      if (fieldValue !== comparisonValue) {
        return false;
      }
    }
  }
  return true;
};

const extractNumber = (value: any): number | null => {
  if (typeof value === "number") {
    return value;
  }
  if (typeof value === "string") {
    // Remove all characters except digits, decimal point, and minus sign
    const numericString = value.replace(/[^0-9.-]+/g, "");
    const parsed = parseFloat(numericString);
    return isNaN(parsed) ? null : parsed;
  }
  return null;
};

export type RankData = {
  index: number;
  total: number;
  objectName: string;
  childName: string;
};

export const calculateImpactResults = (
  validated: any[],
  pending: any[],
  impactAggregationProperties: any[],
  idea: any
) => {
  const impactResults: Record<string, any> = {};

  impactAggregationProperties.forEach((property: any) => {
    const {
      fieldPath,
      propertyName,
      impact,
      fieldDefaultValue,
      operation = "sum",
    } = property;

    let total: number | string = 0;
    let validatedTotal: number | string = 0;
    const displayRanks = impact.displayRanks || [];
    let ranksData: RankData[] | null = null;
    const pendingCompanyIds: Set<string> = new Set();
    const validatedCompanyIds: Set<string> = new Set();

    const consolidateByCompany = impact?.consolidateByCompany || false;

    const multiplyByComponentWeight =
      impact?.multiplyByComponentWeight || false;

    const pendingCompanyValues: Record<string, number[]> = {};
    const validatedCompanyValues: Record<string, number[]> = {};

    const processedChatIds = new Set<string>(); // Track all processed chat IDs

    const applyOperation = (values: number[]) => {
      if (values.length === 0) return 0;
      switch (operation) {
      case "concat":
        return values.join(", ");
      case "sum":
        return values.reduce((acc, val) => acc + val, 0);
      case "count":
        return values.length;
      case "avg":
        return values.reduce((acc, val) => acc + val, 0) / values.length;
      case "min":
        return Math.min(...values);
      case "max":
        return Math.max(...values);
      default:
        return 0;
      }
    };

    const shouldConsolidateByChat = fieldPath.includes("cxItem");

    const processRequest = (
      request: any,
      companyValues: Record<string, number[]>,
      companyIds: Set<string>,
      isValidated: boolean,
      defaultValue: number
    ) => {


      const fieldValue = getNestedFieldValue(request, fieldPath);

      const value =
        fieldValue != null ? extractNumber(fieldValue) : defaultValue || 0;


      const companyId = getNestedFieldValue(request, "company._id");
      const cxItemId = getNestedFieldValue(request, "cxItem._id");


      if (value && !isNaN(value)) {
        if (shouldConsolidateByChat && cxItemId) {

          if (processedChatIds.has(cxItemId)) {
            // Skipping duplicate chat IDs
            return;
          }
          processedChatIds.add(cxItemId);
        }

        if (consolidateByCompany && companyId) {


          if (!companyValues[companyId]) {
            companyValues[companyId] = [];
          }
          companyValues[companyId] = [value];
        } else {


          if (isValidated) {
            //@ts-ignore
            validatedTotal += value;

          } else if (!isValidated) {
            //@ts-ignore
            total += value;
          }
        }

        if (companyId && companyId !== "N/A") companyIds.add(companyId);
      }
    };

    // Process validated and pending requests
    validated.forEach((request) =>
      processRequest(
        request,
        validatedCompanyValues,
        validatedCompanyIds,
        true,
        fieldDefaultValue
      )
    );

    pending.forEach((request) =>
      processRequest(
        request,
        pendingCompanyValues,
        pendingCompanyIds,
        false,
        fieldDefaultValue
      )
    );

    if (consolidateByCompany) {
      validatedTotal = applyOperation(
        Object.values(validatedCompanyValues).flat()
      );
      total = applyOperation(Object.values(pendingCompanyValues).flat());
    }

    // Multiply totals by component weight if required
    const componentWeight = idea.componentObj?.weight || 1;

    if (multiplyByComponentWeight) {
      if (typeof total === "number") {
        total *= componentWeight;
      }
      if (typeof validatedTotal === "number") {
        validatedTotal *= componentWeight;
      }
    }

    ranksData = displayRanks?.map((rankPropertyName: string) => {
      const objectName = rankPropertyName;
      let childName = null;
      let rankData = null;
      if (["domains", "components"].includes(objectName)) {
        if (objectName == "components") {
          childName = idea.componentObj?.name;
          rankData = idea.ranks?.components?.[childName.toLowerCase()];
        } else {
          childName = idea.componentObj?.domain;
          rankData = idea.ranks?.domains?.[childName.toLowerCase()];
        }
      } else if (idea.ranks?.[objectName]) {
        rankData = idea.ranks?.[objectName];
      } else {
        return undefined;
      }

      return {
        index: rankData?.index,
        total: rankData?.total,
        objectName,
        childName,
      };
    });

    impactResults[propertyName] = {
      total,
      validatedTotal,
      customerCount: pendingCompanyIds.size,
      validatedCustomerCount: validatedCompanyIds.size,
      ranksData: ranksData?.filter((rank) => rank !== undefined),
    };
  });

  return impactResults;
};
