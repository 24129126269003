import {
  Group,
  Text,
  Anchor,
  Box,
  Flex,
  Accordion,
  Title,
  Avatar,
  Spoiler,
  Button,
} from "@mantine/core";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { AccountCard } from "./AccountCard";
import { ContactCard } from "./ContactCard";
import { SourceIcon } from "../../../../utils/SourceIcon";
import { stripHtml } from "../../../../utils";
import SyncContext from "../../../../context/SyncContext";
import { getInitials } from "../../../../utils/getInitials";
import { CxItemCard } from "./CxItemCard";
import { evaluateDisplayIf } from "../../../ideaNiv/right-bar/evidence-card/CardInfoTextItem";
import { getNestedFieldValue } from "../../../ideaNiv/helpers/calculateImpactResults";
import { formatNumberValue } from "../../../ideaNiv/Funcs";
import Property from "../RightSide/Property";
import { IconExternalLink } from "@tabler/icons";

export function EvidenceDetails({ evidence }: { evidence: any }) {
  //same
  const { members, ideaInfoTabsConfig } = useContext(SyncContext);
  const [sortedTextItems, setSortedTextItems] = useState<any[]>([]);
  const [highestScoreIndex, setHighestScoreIndex] = useState(0);
  console.log({ evidence });

  const {
    cxItem,
    contact,
    internalContact,
    chatCompany,
    company,
    relatedTextItems,
    textId,
    origin,
    link,
    updatedAt,
    title,
    text,
  } = evidence || {};
  const resolvedContact = contact || internalContact;
  const resolvedCompany = chatCompany || company;
  const evidenceOrigin =
    cxItem?.trueOrigin || cxItem?.origin || origin || "bagel";
  const isGongCall = cxItem?.origin === "gong";
  const isSupportTicket =
    ["zendesk", "intercom"].includes(cxItem?.origin) &&
    cxItem?.itemType === "conversation";

  // Generate tabs and content based on ideaInfoTabsConfig
  const tabsConfig = ideaInfoTabsConfig || {};
  const tabKeys = Object.keys(tabsConfig);

  // Filter tabs based on displayIf condition
  const filteredTabs = tabKeys.filter((tabKey) => {
    const tab = tabsConfig[tabKey];
    return evaluateDisplayIf(evidence, tab.displayIf);
  });

  useEffect(() => {
    if (relatedTextItems?.length) {
      const sortedItems = relatedTextItems.sort(
        (a: any, b: any) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
      setHighestScoreIndex(
        sortedItems.findIndex((item: any) => item._id === textId)
      );

      sortedItems.forEach((item: any) => {
        const fieldKey = item?.fieldName?.split(".")[1];
        if (fieldKey && cxItem?.comments?.[fieldKey]) {
          const comment = cxItem.comments[fieldKey];
          item.comment = comment;
          item.fromUser =
            members.find((m: any) => m.email === comment?.fromEmail) ||
            members.find((m: any) => m.email === comment?.fromName);
        }
      });

      setSortedTextItems(sortedItems);
    }
  }, [relatedTextItems, textId, cxItem?.comments, members]);

  return (
    <Box
      p="md"
      sx={{
        overflow: "scroll",
        position: "sticky",
        top: 56,
      }}
    >
      <Box mb={3}>
        <Text tt="capitalize" fz="xs" color="dimmed">
          title:
        </Text>
        <Text size={18} mb="xs"  color={!title ? 'dimmed' : '#000'}>
          {title || "Unassigned"}
        </Text>
        <Text tt="capitalize" fz="xs" color="dimmed">
          Description:
        </Text>
        <Spoiler maxHeight={130} showLabel="See more" hideLabel="Hide">
          <Text sx={{ cursor: "default", whiteSpace: "pre-line" }} color={!text ? 'dimmed' : '#000'}>
            {text ? stripHtml(text) : "Unassigned"}
          </Text>
        </Spoiler>
      </Box>

      {isGongCall && (
        <Box pl={35} mt="xs">
          <Title order={5} mb="3px">
            Evidence:
          </Title>
          <Flex mb="1px">
            <Text fw={500} fz={15} mb="4px">
              Call:
            </Text>
            <Text ml="3px">{cxItem?.title || "N/A"}</Text>
          </Flex>
        </Box>
      )}

      {(isGongCall || isSupportTicket) &&
        sortedTextItems.map((item, index) => (
          <Flex key={item._id} ml={35} mt={5}>
            {item.excerpts?.length > 0 && (
              <>
                <Box mr="xs" w="33px" pt="4.4px">
                  <Avatar src={item?.fromUser?.picture} radius="xl" size={30}>
                    {getInitials(item?.comment?.fromName)}
                  </Avatar>
                </Box>
                <Box
                  px={10}
                  py={8}
                  sx={{ borderRadius: "11px" }}
                  bg={highestScoreIndex === index ? "#FFFFED" : undefined}
                >
                  <Flex justify="space-between">
                    <Flex direction="column">
                      <Text fw={600} fz="lg">
                        {item.comment?.fromName}
                      </Text>
                      <Text fz="lg">
                        {item.comment?.fromEmail &&
                          `<${item.comment?.fromEmail}>`}
                      </Text>
                    </Flex>
                    <Text size="xs" c="dimmed">
                      {item.createdAt && (
                        <ReactTimeAgo date={new Date(item.createdAt)} />
                      )}
                    </Text>
                  </Flex>
                  <Flex mt="xs">
                    <Text fw={600} fz="lg" mr="xs">
                      To:
                    </Text>
                    <Text fw={600} fz="lg">
                      {item.comment?.toName}
                    </Text>
                    <Text fz="lg">
                      {item.comment?.toEmail && `<${item.comment?.toEmail}>`}
                    </Text>
                  </Flex>
                  {item.excerpts.map((excerpt: any) => (
                    <Text mt="xs" w={720}>
                      {stripHtml(excerpt)}
                    </Text>
                  ))}
                </Box>
              </>
            )}
          </Flex>
        ))}

      <Box mt="sm">
        <Group spacing={6}>
          {cxItem?.link && (
            <Button
              styles={{
                label: {
                  color: "#0D65D7",
                  fontSize: 14,
                  fontWeight: 400,
                },
              }}
              variant="light"
              color="gray"
              compact
              component="a"
              href={cxItem?.link}
              target="_blank"
              leftIcon={
                <SourceIcon
                  sourceName={evidenceOrigin}
                  height={18}
                  width={18}
                />
              }
              rightIcon={<IconExternalLink size={18} color="#0D65D7" />}
            >
              <Text tt="capitalize">
                {evidenceOrigin} - {cxItem?.originId}
              </Text>
            </Button>
            // <>
            //   <SourceIcon sourceName={evidenceOrigin} height={24} />
            //   <Text fz="xs" tt="capitalize">
            //     {evidenceOrigin}--
            //     {cxItem?.link && (
            //       <Anchor ml="lg" size="xs" href={cxItem.link} target="_blank">
            //         Go To {cxItem.originId}
            //       </Anchor>
            //     )}
            //   </Text>
            // </>
          )}
          {!cxItem && evidenceOrigin && link && (
            <>
              <SourceIcon sourceName={evidenceOrigin} />
              <Text fz="xs" tt="capitalize">
                {evidenceOrigin}
                {updatedAt && (
                  <Text ml="lg" size="xs" c="dimmed" span>
                    {moment(updatedAt).fromNow()}
                  </Text>
                )}
                <Anchor ml="lg" size="xs" href={link} target="_blank">
                  Go To {evidence?.itemKey || evidence?.originId}
                </Anchor>
              </Text>
            </>
          )}
        </Group>
      </Box>

      {/* <Accordion
        pt={20}
        sx={{ borderRadius: "8px" }}
        variant="filled"
        multiple
        chevronPosition="left"
      >
        {cxItem && (
          <CxItemCard
            cxItem={cxItem}
            contact={resolvedContact}
            company={resolvedCompany}
            opType={cxItem?.opportunityType}
            requestText={cxItem?.text}
          />
        )}
        {resolvedCompany && <AccountCard company={resolvedCompany} />}
        {resolvedContact && <ContactCard contact={resolvedContact} />}


      </Accordion> */}
      {filteredTabs.map((tabKey) => {
        const tab = tabsConfig[tabKey];

        return (
          <Box key={tabKey} mt={12}>
            <Text fz={14} fw={600} mb={8}>
              {tab.label}
            </Text>
            <Group position="left" spacing={5}>
              {tab.properties.map((property: any) => {
                let value = getNestedFieldValue(evidence, property.fieldPath);

                if (value === undefined || value === null || value === "") {
                  value = "N/A";
                } else if (typeof value === "number") {
                  value = formatNumberValue(value);
                } else if (typeof value === "boolean") {
                  value = value ? "Yes" : "No";
                }

                return (
                  <Property
                    key={property.fieldPath}
                    label={property.label}
                    text={value}
                    Icon={property.icon}
                    withBorder
                  />
                );
              })}
            </Group>
          </Box>
        );
      })}
    </Box>
  );
}
