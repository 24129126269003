import { useContext, useMemo } from "react";
import IdeaContext from "../IdeaContext";
import { Box, Skeleton, Text } from "@mantine/core";
import EvidenceCardNiv from "./EvidenceCardNiv";

const EvidenceResults = ({
  displayedRequests,
  // showMatches,
  chooseMatch,
  showMatchesResults,
  showRequestsResults,
  showQueryResults,
  textItemsResults,
  loadingtextItems,
  chosenEvidence,
}: {
  displayedRequests: any[];
  // showMatches: boolean;
  chooseMatch: any;
  showMatchesResults: boolean;
  showRequestsResults: boolean;
  showQueryResults: boolean;
  textItemsResults: any[];
  loadingtextItems: boolean;
  chosenEvidence: any;
}) => {
  const searchedMatches =
    displayedRequests.filter(
      (s: any) => s.state !== "validated" || !s.ideaId
    ) || [];
  const matchesLength = searchedMatches?.length || 0;
  const searchedRequests = displayedRequests.filter((s: any) => s.ideaId) || [];
  const requestsLength = searchedRequests?.length || 0;
  // console.log("filters-",{searchedMatches, searchedRequests})

  const _textItemsResults = useMemo(
    () =>
      textItemsResults.filter(
        (tir: any) => !displayedRequests.some((dr) => dr._id === tir._id)
      ) || [],
    [textItemsResults, displayedRequests]
  );
  const { showmatches } = useContext(IdeaContext);
  return (
    <Box pt="16px" px="20px" w="100%">
      {showmatches && (
        <Box
          pb={12}
          sx={{
            borderBottom: "1px solid #D8D8DB",
          }}
        >
          <Text h={16} fw={600} mb={12} color="dimmed" fz="12px">
            PENDING EVIDENCE {matchesLength}
          </Text>

          {searchedMatches?.length > 0 &&
            searchedMatches?.map((evidence: any, index: number) => (
              <EvidenceCardNiv
                item={evidence}
                chooseMatch={chooseMatch}
                index={index}
                fromSearch={false}
                chosenEvidence={chosenEvidence}
                ideaPage
              />
            ))}
        </Box>
      )}

      {showRequestsResults && (
        <Box
          py={12}
          sx={{
            borderBottom: "1px solid #D8D8DB",
          }}
        >
          <Text h={16} fw={600} my={12} color="dimmed" fz="12px">
            VALIDATED EVIDENCE {requestsLength}
          </Text>

          {searchedRequests?.length > 0 &&
            searchedRequests?.map((evidence: any, index: number) => (
              <EvidenceCardNiv
                item={evidence}
                chooseMatch={chooseMatch}
                index={index}
                fromSearch={false}
                chosenEvidence={chosenEvidence}
                ideaPage
              />
            ))}
        </Box>
      )}

      {showQueryResults && (
        <Box
          py={12}
          sx={{
            borderBottom: "1px solid #D8D8DB",
          }}
        >
          <Text h={16} fw={600} my={12} color="dimmed" fz="12px">
            ADDITIONAL SEARCH RESULTS {textItemsResults.length}
          </Text>

          {!loadingtextItems &&
          _textItemsResults &&
          _textItemsResults.length > 0 ? (
              _textItemsResults?.map((evidence: any, index: number) => (
                <EvidenceCardNiv
                  item={evidence}
                  chooseMatch={chooseMatch}
                  index={index}
                  fromSearch
                  chosenEvidence={chosenEvidence}
                  ideaPage
                />
              ))
            ) : !loadingtextItems &&
            _textItemsResults &&
            _textItemsResults.length === 0 ? (
                <></>
              ) : (
                <Box w="100%" pr="4px" ml={-8} mt={8}>
                  {[1, 2, 3, 4].map((i) => (
                    <Skeleton
                      key={i}
                      m="sm"
                      height={66}
                      mb={0}
                      sx={{ borderRadius: "8px" }}
                    />
                  ))}
                </Box>
              )}
        </Box>
      )}
    </Box>
  );
};

export default EvidenceResults;
