import {
  Flex,
  Box,
  Text,
  Card,
  Spoiler,
  LoadingOverlay,
  Group,
  Button,
  Tooltip,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconMessage } from "@tabler/icons";
import { useState } from "react";

export function SearchResultIdeaItem({
  ideaItem,
  loading,
  /* assignFunc, */
  chooseIdea,
  chosenIdea,
  isCreateNew = false,
  requestsCount,
  reAssign,
}: {
  ideaItem: any;
  loading: boolean;
  /* assignFunc: () => void; */
  chooseIdea: any;
  chosenIdea: any;
  isCreateNew?: boolean;
  requestsCount?: number;
  reAssign?: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const isChosen = chosenIdea?._id === ideaItem?._id



  return (
    <Card
      /* withBorder */
      p="xs"
      my="xs"
      w={438}
      bg={isChosen?"#F0F0FA" : "#FFF"}
      /* h={100} */
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        position: "relative",
        minHeight: "120px",
        cursor: "pointer",
        border: (isHovered || isChosen) ? "1px solid #5C5CEB" : "1px solid #D8D8DB",
      }}
      onClick={() => {
        chooseIdea(ideaItem)
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} />

      <Group position="apart" style={{ marginBottom: "12px" }}>
        <Flex align="flex-start">
          {/* <IconMessage color="#5B60E3" style={{ marginRight: '8px' }} /> */}
          <Text size="sm" style={{ fontWeight: 600 }}>
            {ideaItem?.title || "Unassigned"}
          </Text>
        </Flex>
      </Group>

      <Spoiler
        maxHeight={20}
        showLabel="see more"
        hideLabel="Hide"
        sx={(theme) => ({
          fontSize: theme.fontSizes.sm,
          button: {
            color: "#5B60E3",
            fontSize: theme.fontSizes.sm,
          },
        })}
        my="xs"
      >
        <Text size="xs" style={{ color: "black" }}>
          {ideaItem?.description?.replace(/<[^>]*>/g, "") ||
            "No description provided."}
        </Text>
      </Spoiler>

      <Flex justify="space-between" style={{ marginTop: "auto" }}>
        <Box>
          <Text size="xs" style={{ color: "black" }}>
            Owner:
          </Text>
          <Text size="xs" color="dimmed">
            {ideaItem?.owner?.name || "-"}
          </Text>
        </Box>
        <Box>
          <Text size="xs" style={{ color: "black" }}>
            Status:
          </Text>
          <Text size="xs" color="dimmed">
            {ideaItem?.status || "-"}
          </Text>
        </Box>
        <Box>
          <Text size="xs" style={{ color: "black" }}>
            Priority:
          </Text>
          <Text size="xs" color="dimmed">
            {ideaItem?.priority || "-"}
          </Text>
        </Box>
        <Box>
          <Text size="xs" style={{ color: "black" }}>
            Product Area:
          </Text>
          <Text size="xs" color="dimmed">
            {ideaItem?.componentObj?.name || ideaItem?.component?.name || "-"}
          </Text>
        </Box>

        <Box>
          <Text size="xs" style={{ color: "black" }}>
          Companies:
          </Text>
          <Text size="xs" color="dimmed">
            {ideaItem?.aggregations?.accountsCount?.total  || "-"}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
}
