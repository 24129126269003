import React, { useEffect, useState } from "react";
import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import {inviteUser, listMembers} from "../../Api";
import {
  Title,
  Button,
  Box,
  Grid,
  Tabs,
  Card,
  TextInput,
  Menu,
  Select,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import UsersList from "./UsersList";
import InvitesList from "./InvitesList";
import { getConnectedUserRole } from "../../utils";

const tabs = [
  { name: "All Members" },
  { name: "Invite Sent" },
];

const roles = [
  { value: "admin", label: "Admin" },
  { value: "manager", label: "Manager" },
  { value: "agent", label: "Agent" },
];

const Members = () => {
  const auth0 = useAuth0();
  const [inviteName, setInviteName] = useState<string>("");
  const [inviteEmail, setInviteEmail] = useState<string>("");
  const [inviteRole, setInviteRole] = useState<string | null>("agent");
  const [members, setMembers] = useState([]);
  const [activeTab, setActiveTab] = useState<string | null>("All Members");
  const loadMembers = () => {
    listMembers(auth0)
      .then((users) => {
        setMembers(users);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadMembers();
  }, []);

  const getUsersForActiveTab = () => {
    if (activeTab === "All Members") {
      return <UsersList users={members.filter((member: any) => member.status !== "invited")} />;
    }
    if (activeTab === "Invite Sent") {
      return <InvitesList invites={members.filter((member: any) => member.status === "invited")} />;
    }
  };

  const submitInvite = () => {
    if (!inviteRole) {
      console.log("invite role not set");
      return;
    }
    const user = {
      name: inviteName,
      email: inviteEmail,
      role: inviteRole,
    };
    inviteUser(auth0, user)
      .then(() => {
        loadMembers()
      })
      .catch((e) => console.log(e.error));
  };
  const role = getConnectedUserRole(auth0);
  const canEdit = role === "admin";
  return (
    <Box pt={"md"}>
      <Title size={"xl"} pl={"md"}>
        Members
      </Title>
      <Grid
        mx="sm"
        justify="space-between"
        align="flex-end"
        sx={{ borderBottom: "1px solid #F1F1F1" }}
      >
        <Grid.Col span="content" pb="0">
          <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List m="0" sx={{ borderBottom: 0 }}>
              {tabs.map((tab) => (
                <Tabs.Tab key={tab.name} value={`${tab.name}`}>
                  {tab.name}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
        </Grid.Col>
        {canEdit && (
          <Grid.Col span={"content"}>
            <Menu width={300} shadow="md" position={"bottom-end"}>
              <Menu.Target>
                <Button
                  bg={"#5C5CEA"}
                  sx={{
                    fontWeight: 700,
                    ":hover": { backgroundColor: "#9f9af8" },
                  }}
                  color={"#fff"}
                  mr={30}
                  mb={30}
                >
                  {" "}
                  + New Member
                </Button>
              </Menu.Target>
              <Menu.Dropdown p={"md"}>
                <TextInput
                  value={inviteName}
                  onChange={(event: any) =>
                    setInviteName(event.currentTarget.value)
                  }
                  label="Name"
                  placeholder="Name"
                  size="xs"
                />
                <TextInput
                  value={inviteEmail}
                  onChange={(event: any) =>
                    setInviteEmail(event.currentTarget.value)
                  }
                  label="Email"
                  placeholder="john@doe.com"
                  size="xs"
                  mt="xs"
                />
                <Select
                  value={inviteRole}
                  label="Role"
                  onChange={setInviteRole}
                  data={roles}
                  mt={"md"}
                />
                <Menu.Item p={0} mt={"md"}>
                  <Button
                    onClick={submitInvite}
                    bg={"#5C5CEA"}
                    fullWidth
                    sx={{
                      fontWeight: 700,
                      ":hover": { backgroundColor: "#9f9af8" },
                    }}
                    color={"#fff"}
                  >
                    Invite User
                  </Button>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Grid.Col>
        )}
      </Grid>
      <Box p={"xl"} mt={"xs"} bg={"#F1F3F5"}>
        <Card
          p="md"
          radius="md"
          mt={"lg"}
          withBorder
          sx={{ padding: "0 !important" }}
        >
          <Box>
            <Grid justify="space-between" align="flex-end">
              <Grid.Col span={4}>
                <TextInput
                  mt={30}
                  ml={30}
                  mb={15}
                  disabled={true}
                  radius="md"
                  placeholder="Search"
                  // value={parentQuery}
                  // onChange={(event: any) => setParentQuery(event.currentTarget.value)}
                  icon={<IconSearch size={14.5} />}
                  sx={{
                    input: {
                      backgroundColor: "#F9FAFB",
                      ":focus": {
                        borderColor: "#b1b1b1;",
                      },
                    },
                  }}
                />
              </Grid.Col>
              <Grid.Col span={"content"} pb={"md"} pr={"xl"}>
                {/* <Text size={"sm"} m={15}>
                  4 seats remaining
                </Text> */}
              </Grid.Col>
            </Grid>
          </Box>
          {getUsersForActiveTab()}
        </Card>
      </Box>
    </Box>
  );
};

export default withAuth0(Members);
