export interface Company {
    tier?: string;
    type?: string;
    plan?: string;
    businessName?: string;
  }

  interface CategoryData {
    dataArr: any[];
    requestsCount: number;
    matchesCount: number;
    title: string;
    inboxCount?: number;
  }

export type Perspectives ={
  label: string;          // Dynamic label for each perspective
  key: string;            // Key or fieldPath for fetching the category value
  fieldPath?: string;     // Optionally, a path to the field if nested
  countType: 'requests' | 'matches' | 'inbox'; // Type of count to associate with this perspective
}[];

export const getCalculatedPerspectiveForRequestNew = async (
  allRequests: any[],
  perspectiveConfigurations: Perspectives,
): Promise<Record<string, CategoryData>> => {
  
  // Initialize an object to hold dynamic sets for each perspective category
  const categorySets = perspectiveConfigurations.reduce((acc, perspective) => {
    acc[perspective.key] = new Set<string>();
    return acc;
  }, {} as Record<string, Set<string>>);

  // Populate categories dynamically from all requests based on the perspectives
  allRequests.forEach((request: any) => {
    perspectiveConfigurations.forEach((perspective) => {
      let value;
      if (perspective.fieldPath) {
        // Resolve nested fields like request.company.tier
        value = perspective.fieldPath.split('.').reduce((acc, key) => acc && acc[key], request);
      } else {
        value = request[perspective.key]; // Direct key
      }

      if (value) {
        categorySets[perspective.key].add(value);
      }
    });
  });

  // Initialize category arrays dynamically based on the perspectives
  const initializeCategoryArray = (categorySet: Set<string>, perspective: typeof perspectiveConfigurations[0]) => {
    return Array.from(categorySet, value => ({
      [perspective.key]: value,
      value: value,
      requestsCount: 0,
      matchesCount: 0,
      inboxCount: 0
    }));
  };

  // Generate arrays for each perspective category
  const categoryData = perspectiveConfigurations.reduce((acc, perspective) => {
    acc[perspective.key] = initializeCategoryArray(categorySets[perspective.key], perspective);
    return acc;
  }, {} as Record<string, any[]>);

  // Count requests, matches, and inbox for each category dynamically
  allRequests.forEach((request: any) => {
    perspectiveConfigurations.forEach((perspective) => {
      let value: any;
      if (perspective.fieldPath) {
        value = perspective.fieldPath.split('.').reduce((acc, key) => acc && acc[key], request);
      } else {
        value = request[perspective.key];
      }

      if (value) {
        const arr = categoryData[perspective.key];
        const item = arr.find((item: any) => item[perspective.key]?.toLowerCase() === value.toLowerCase());

        if (item) {
          if ((request.type === 'gap' && request.ideaId && !request?.match) || request.state === 'validated' && !request?.match) {
            item.requestsCount++;
          } else if (request.isGapMatch) {
            item.matchesCount++;
          } else if (request.isRequestMatch || request.state=== 'inReview') {
            item.inboxCount++;
          }
        }
      }
    });
  });

  let maxTotal = 0;
  
  Object.keys(categoryData).forEach(key => {
    const arr = categoryData[key];
    
    arr.forEach(item => {
      item.total = item.requestsCount + item.matchesCount + item.inboxCount;
      if (item.total > maxTotal) {
        maxTotal = item.total;
      }
    });
    arr.sort((a: any, b: any) => b.requestsCount - a.requestsCount)
  });

  

  // Adjust percentages dynamically and sort - Moved to components - To Do - fix tabs and more...
  /* Object.keys(categoryData).forEach(key => {
    const arr = categoryData[key];
    
    arr.forEach(item => {
      if (maxTotal > 0) {
        item.validatedPercentage = (item.requestsCount / maxTotal) * 100;
        item.matchesPercentage = ((item.matchesCount + item.inboxCount)  / maxTotal) * 100;
        item.inboxPercentage = (item.inboxCount / maxTotal) * 100;
        item.bgPercentage = 100 - item.validatedPercentage - item.matchesPercentage - item.inboxPercentage; 
      } else {
        item.validatedPercentage = 0;
        item.matchesPercentage = 0;
        item.inboxPercentage = 0;
        item.bgPercentage = 100;
      }
    });

  
    arr.sort((a, b) => {
      const percentageDiff = (b.validatedPercentage + b.matchesPercentage) - (a.validatedPercentage + a.matchesPercentage);
      if (percentageDiff !== 0) {
        return percentageDiff;
      }
      return (b.requestsCount + b.matchesCount + b.inboxCount) - (a.requestsCount + a.matchesCount + a.inboxCount);
    });
  }); */

  // Return structured data for each perspective category
  const result = perspectiveConfigurations.reduce((acc, perspective) => {
    const dataArr = categoryData[perspective.key];
    acc[perspective.key] = {
      dataArr,
      requestsCount: dataArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: dataArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
      inboxCount: dataArr.reduce((sum, curr) => sum + curr.inboxCount, 0),
      title: perspective.label || perspective.key
    };
    return acc;
  }, {} as Record<string, CategoryData>);

  return result;
};


// Discovery function (No need for inbox or validated)

export const getCalculatedPerspectiveForRequestDiscoveryNew = async (
  allRequests: any[],
  perspectiveConfigurations: Perspectives
): Promise<Record<string, CategoryData>> => {

  // Initialize an object to hold dynamic sets for each perspective category
  const categorySets = perspectiveConfigurations.reduce((acc, perspective) => {
    acc[perspective.key] = new Set<string>();
    return acc;
  }, {} as Record<string, Set<string>>);

  // Populate categories dynamically from all requests based on the perspectives
  allRequests.forEach((request: any) => {
    perspectiveConfigurations.forEach((perspective) => {
      let value;
      if (perspective.fieldPath) {
        // Resolve nested fields like request.company.tier
        value = perspective.fieldPath.split('.').reduce((acc, key) => acc && acc[key], request);
      } else {
        value = request[perspective.key]; // Direct key
      }

      if (value) {
        categorySets[perspective.key].add(value);
      }
    });
  });

  // Initialize category arrays dynamically based on the perspectives
  const initializeCategoryArray = (categorySet: Set<string>, perspective: typeof perspectiveConfigurations[0]) => {
    return Array.from(categorySet, value => ({
      [perspective.key]: value,
      value: value,
      matchesCount: 0,
    }));
  };

  // Generate arrays for each perspective category
  const categoryData = perspectiveConfigurations.reduce((acc, perspective) => {
    acc[perspective.key] = initializeCategoryArray(categorySets[perspective.key], perspective);
    return acc;
  }, {} as Record<string, any[]>);

  // Count requests, matches, and inbox for each category dynamically
  allRequests.forEach((request: any) => {
    perspectiveConfigurations.forEach((perspective) => {
      let value: any;
      if (perspective.fieldPath) {
        value = perspective.fieldPath.split('.').reduce((acc, key) => acc && acc[key], request);
      } else {
        value = request[perspective.key];
      }

      if (value) {
        const arr = categoryData[perspective.key];
        const item = arr.find((item: any) => item[perspective.key]?.toLowerCase() === value.toLowerCase());

        if (item) {
         
          item.matchesCount++;
          
        }
      }
    });
  });

  // Adjust percentages and total dynamically
  let maxTotal = 0;
  
  Object.keys(categoryData).forEach(key => {
    const arr = categoryData[key];
    
    arr.forEach(item => {
    
      if (item.matchesCount  > maxTotal) {
        maxTotal = item.matchesCount;
      }
    });
  });

  // Adjust percentages dynamically and sort
  Object.keys(categoryData).forEach(key => {
    const arr = categoryData[key];
    
    arr.forEach(item => {
      if (maxTotal > 0) {
        item.matchesPercentage = (item.matchesCount / maxTotal) * 100;
        item.bgPercentage = 100  - item.matchesPercentage
        
      }  else {
        item.matchesPercentage = 0;
        item.bgPercentage = 100;
      } 
    });

    arr.sort((a, b) => a.bgPercentage - b.bgPercentage);

  });

  // Return structured data for each perspective category
  const result = perspectiveConfigurations.reduce((acc, perspective) => {
    const dataArr = categoryData[perspective.key];
    acc[perspective.key] = {
      dataArr,
      requestsCount: dataArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: dataArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
      title: perspective.label || perspective.key
    };
    return acc;
  }, {} as Record<string, CategoryData>);

  return result;
};



/* Old function: export const getCalculatedPerspectiveForRequest = async (
  allRequests: any[]
): Promise<{ tierData: CategoryData, typeData: CategoryData, planData: CategoryData, businessNameData: CategoryData }> => {
  const categories = {
    tiers: new Set<string>(),
    types: new Set<string>(),
    plans: new Set<string>(),
    businessNames: new Set<string>()
  };

  allRequests.forEach((request:any) => {
 
    if (request.company) {
      if (request.company.tier) categories.tiers.add(request.company.tier);
      if (request.company.type) categories.types.add(request.company.type);
      if (request.company.plan) categories.plans.add(request.company.plan);
    }
    if (request.businessName) {
      categories.businessNames.add(request.businessName);
    }
  });

  const initializeCategoryArray = (categorySet: Set<string>, key: string) => {
    const arr = Array.from(categorySet, value => ({
      [key]: value,
      value: value,
      requestsCount: 0,
      matchesCount: 0,
      inboxCount: 0,
      ...(key === 'businessName' ? {
        businessCategory: `${value} Category`, 
        icon: `bagel_${value.replace(/\s+/g, '_')}`,
        value: value,
        total: 0,
        display: true,
        validatedPercentage: 0,
        matchesPercentage: 0,
        bgPercentage: 100
      } : {})
    }));

    return arr;
  };

  const tierArr = initializeCategoryArray(categories.tiers, 'tier');
  const typeArr = initializeCategoryArray(categories.types, 'type');
  const planArr = initializeCategoryArray(categories.plans, 'plan');
  const businessNameArr = initializeCategoryArray(categories.businessNames, 'businessName');

  allRequests.forEach((request:any) => {
    const company = request.company;

    const incrementCounters = (arr: any[], key: string) => {
      let value = company?.[key] || 'N/A';

      if(key === 'businessName'){
        value = request?.[key]
      }

      const item = arr.find((item:any) => item[key]?.toLowerCase() === value?.toLowerCase());
      if (item) {
        if (request?.status?.toLowerCase() === 'validated') {
          item.requestsCount++;
        } else if (request.status === 'match' && request.type === 'IdeaXConversation')
        {
          item.matchesCount++;
        }
        else if (request.type === 'IdeaXRequest' || request.status === 'new')
        {
          item.inboxCount++;
        }
      }
    };

    incrementCounters(tierArr, 'tier');
    incrementCounters(typeArr, 'type');
    incrementCounters(planArr, 'plan');
    incrementCounters(businessNameArr, 'businessName');
  });

  businessNameArr.forEach(item => {
    item.total = item.requestsCount + item.matchesCount;
    if (item.total > 0) { 
      item.validatedPercentage = (item.requestsCount / item.total) * 100;
      item.matchesPercentage = (item.matchesCount / item.total) * 100;
      item.bgPercentage = 100 - item.validatedPercentage - item.matchesPercentage;
    }
  });

  const maxTierTotal = Math.max(...tierArr.map(item => item.requestsCount + item.matchesCount + item.inboxCount));
  const maxTypeTotal = Math.max(...typeArr.map(item => item.requestsCount + item.matchesCount + item.inboxCount));
  const maxPlanTotal = Math.max(...planArr.map(item => item.requestsCount + item.matchesCount + item.inboxCount));
  const maxBusinessNameTotal = Math.max(...businessNameArr.map(item => item.requestsCount + item.matchesCount + item.inboxCount));

  const adjustAndSortPercentages = (arr: any[], maxTotal: number) => {
    arr.forEach(item => {
      item.total = item.requestsCount + item.matchesCount + item.inboxCount;
      if (item.total > 0) { 
        item.validatedPercentage = (item.requestsCount / maxTotal) * 100;
        item.matchesPercentage = (item.matchesCount / maxTotal) * 100;
        item.inboxPercentage = (item.inboxCount / maxTotal) * 100;
        item.bgPercentage = 100 - item.validatedPercentage - item.matchesPercentage - item.inboxPercentage;
      } else {
        item.validatedPercentage = 0;
        item.matchesPercentage = 0;
        item.inboxPercentage = 0;
        item.bgPercentage = 100;
      }
    });

    arr.sort((a, b) => (b.validatedPercentage + b.matchesPercentage) - (a.validatedPercentage + a.matchesPercentage));
  };

  adjustAndSortPercentages(tierArr, maxTierTotal);
  adjustAndSortPercentages(typeArr, maxTypeTotal);
  adjustAndSortPercentages(planArr, maxPlanTotal);
  adjustAndSortPercentages(businessNameArr, maxBusinessNameTotal);

  return {
    tierData:

    { dataArr: tierArr,
      requestsCount: tierArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: tierArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
      inboxCount: tierArr.reduce((sum, curr) => sum + curr.inboxCount, 0),
      title: "Tiers" },

    typeData: { dataArr: typeArr,
      requestsCount: typeArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: typeArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
      inboxCount: tierArr.reduce((sum, curr) => sum + curr.inboxCount, 0),
      title: "Types" },

    planData: { dataArr: planArr,
      requestsCount: planArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: planArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
      inboxCount: tierArr.reduce((sum, curr) => sum + curr.inboxCount, 0),
      title: "Plans" },

    businessNameData: { dataArr: businessNameArr,
      requestsCount: businessNameArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: businessNameArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
      inboxCount: tierArr.reduce((sum, curr) => sum + curr.inboxCount, 0),
      title: "Business Names" }

  };
}; */


