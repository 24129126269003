import { Anchor, Box, Button, Flex, Modal, Stack, Text, Tooltip } from "@mantine/core";
import { IconBell, IconCheck, IconPlus, IconX } from "@tabler/icons";
import { useContext, useState } from "react";
import { showNotification } from "@mantine/notifications";

import { useAuth0 } from "@auth0/auth0-react";
import SyncContext from "../../../../../context/SyncContext";
import IdeaContext from "../../../IdeaContext";
import { addRequestsToIdea, reassignRequest } from "../../../../../Api";
import useEvidenceActions from "../../../../../hooks/useEvidenceActions";


const SearchActions = ({
  navToPerspective,
  setActiveSearch,
}: {
  navToPerspective: any;
  setActiveSearch: any;
}) => {
  // const auth0 = useAuth0();

  // const { defaultRequestPriority } = useContext(SyncContext);

  const {
    idea: _idea,
  } = useContext(IdeaContext);

  const {
    loadEvidence,
    chosenEvidence,
    ideaId,
    idea,
    reCalculatPerspectiveForRequest,
    setAddEvidenceFormScreen,
    setCardView,
    setChosenEvidence,
    triggerImpact,
    setTriggerImpact
  } = useContext(IdeaContext);
  console.log({chosenEvidence})

  const { validate, isLoading } = useEvidenceActions({evidenceId: chosenEvidence._id})
  
  const [loading, setLoading] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [verifyOpened, setVerifyOpened] = useState(false);

  const assignedRequest = (chosenEvidence?.requests && chosenEvidence?.requests?.length > 0) ? chosenEvidence?.requests[0] : null
  const assignedIdea = (chosenEvidence?.ideas && chosenEvidence?.ideas?.length > 0) ? chosenEvidence?.ideas[0] : null
  const SALESFORCE = "salesforce";

  const handleReassignRequestWithTextItem = () => {
    setLoadingVerify(true)
    validate({ideaId:_idea._id, sourceOfAssignment:"search"})
      .then(({ success, evidence }) => {
        reCalculatPerspectiveForRequest( evidence, "add");
        setAddEvidenceFormScreen(false);
        setCardView(false);
        setChosenEvidence({});
        loadEvidence();
        setTriggerImpact(!triggerImpact)

        showNotification({
          title: "Eviedence has been re-assigned successfuly",
          color: "teal",
          message: "",
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });
        setLoadingVerify(false)
        setVerifyOpened(false);
        // loadEvidence();
        // navToPerspective();
      })
      .catch((e) => {
        console.log(e);
        showNotification({
          title: "Error re-assigning request",
          color: "red",
          message: "please try again...",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200
        });
      });
  }

  const handleTextItemAssignment = (chosenEvidence: any)=>{

    // setLoading(true);

    if (chosenEvidence?.cxItem?.itemType === "account") {
      if (!chosenEvidence?.cxItem.origin) {
        //if this is a cxItem account and not a cxOrg
        // check if the request has salesforce id
        let hasSalesforce = chosenEvidence?.company?.sfId;
        if (!hasSalesforce) {
          // support for sources on cxOrgs
          chosenEvidence?.company?.sources?.forEach((source: any) => {
            if (source.origin === SALESFORCE) {
              hasSalesforce = true;
            }
          });
        }
        if (hasSalesforce) {
          chosenEvidence.cxItem.origin = SALESFORCE;
        } else {
          chosenEvidence.cxItem.origin =
          chosenEvidence.company.sources?.length > 0
            ? chosenEvidence.company?.sources[0]?.origin
            : undefined;
        }
      }
    }

    // const newEvidence: any = {
    //   title: chosenEvidence?.title ,
    //   text: chosenEvidence?.text,
    //   type: chosenEvidence?.type,
    //   componentId: idea?.componentId,
    //   cxOrigin: chosenEvidence.cxItem?.origin,
    //   originId: chosenEvidence.cxItem?.originId,
    //   orgId: chosenEvidence.cxItem?.orgId,
    //   reporterId: chosenEvidence.cxItem?.contactId,
    //   priority: defaultRequestPriority, 
    //   // committedDate: textItem.committed,
    //   textId: textItem?._id,
    //   businessName: textItem?.businessName,
    //   itemId: chosenEvidence?.parentId,
    //   ownerId: idea?.ownerId,
    //   sourceOfAssignment: "search",
    // };
    ideaId &&
    validate({ideaId, sourceOfAssignment:'search'})
      .then(({ success, evidence }) => {
        console.log("validate evidence ToIdea", {evidence});
        reCalculatPerspectiveForRequest( evidence, "add");
        setAddEvidenceFormScreen(false);
        setCardView(false);
        setChosenEvidence({});
        loadEvidence();
        setTriggerImpact(!triggerImpact)
      })
      .catch((e) => console.log(e))
      .finally(() => {
        navToPerspective();
        setActiveSearch(false);
        showNotification({
          title: "Evidence added successfully",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });
        // setLoading(false);
      });

  }





  const handleAttachFromSearch = (chosenEvidence: any) => {

    
    if (
      // Checking if this text item is already assigned to a request and idea for verification step
      // assignedRequest && 
      // assignedIdea &&
      // assignedRequest?.state === "validated"   
      chosenEvidence.ideaId
    ) 
    {  // verification step
      setVerifyOpened(true)
    }
    else{
      // assign directly if not already assigned
      handleTextItemAssignment(chosenEvidence)
    }
  };

  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      h="64px"
      sx={{ borderBottom: "1px solid #D8D8DB" }}
      align="center"
    >
      {/* Verifcation modal that handles reassignment of text item to current idea */}
      {verifyOpened && <Modal
        yOffset={223}
        opened={verifyOpened}
        onClose={() => setVerifyOpened(false)}
        h='40vw'
        size='30vw'
      >
        <Stack mx='md' mb={5}>
          <Stack  mb={5}> 
            <Text fz={14} mr={5} lineClamp={1}>
                        This Evidence is already assigned to another Idea:
            </Text>
            <Anchor  href={`/idea/${ chosenEvidence?.idea?._id}`} target="_blank">
              <Tooltip disabled={!chosenEvidence?.idea?.title} label={chosenEvidence?.idea?.title}>
                <Text fz={14} color="indigo" weight={600} lineClamp={1}>
                  {chosenEvidence?.idea?.title}
                </Text>
              </Tooltip> 
            </Anchor>
          </Stack>
          <Text fz={14}>
                Would you like to reassign this Evidence to the current Idea?
          </Text>
                
          <Flex justify="space-around" style={{ width: '100%' }} mt={13}>
      
            <Button
              mx="md" 
              onClick={()=> setVerifyOpened(false)}
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#DB2525",
                  fontWeight: 400,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#DB2525", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
            >
                    Cancel
            </Button>
      
            <Button
              mx="md" 
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#5C5CEB",
                  fontWeight: 400,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              onClick={handleReassignRequestWithTextItem}
              loading={isLoading.validate} 
            >
                    Reassign
            </Button>
          </Flex>
        </Stack>
      
      </Modal>}


      <Flex align="center" w="107px" mr="12px" sx={{ cursor: "default" }}>
        <Box>
          <IconBell size={15} color="#D8D8DB" />
        </Box>

        <Text ml="4px" color="#D8D8DB" fz={12} lineClamp={1}>
          Activity
        </Text>
      </Flex>
      <Flex align="center">
        <Box>
          <Button
            fz="12px"
            color="indigo"
            w="75px"
            loading={isLoading.validate}
            styles={(theme) => ({
              root: {
                height: "32px",
                backgroundColor: "#5C5CEB",
                border: 0,
                fontWeight: 400,
                padding: 0,
                "&:hover": {
                  backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                },
              },

              leftIcon: {
                marginRight: 15,
              },
            })}
            onClick={() => {
              handleAttachFromSearch(chosenEvidence);
            }}
          >
            <IconPlus size={14} />
            <Text>assign</Text>
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SearchActions;