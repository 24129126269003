import { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Text,
  Flex,
  Button,
  Menu,
  TextInput,
  Checkbox,
  ScrollArea,
  Tooltip,
  Skeleton,
  Pagination,
  Group,
  LoadingOverlay,
} from "@mantine/core";
import {
  IconArrowNarrowDown,
  IconUser,
  IconUsers,
  IconCheck,
  IconArrowNarrowUp,
  IconUserX,
  IconListSearch,
} from "@tabler/icons";
import EvidenceCard from "./EvidenceCard";
import { getBagelId, useThrottle } from "../../utils";
import { useListState } from "@mantine/hooks";
import { openModal } from "@mantine/modals";
import SyncContext from "../../context/SyncContext";
import { useSearchParams } from "react-router-dom";
import { getEvidence, listEvidences } from "../../api/evidences";
import { EvidenceModal } from "./EvidenceModal";

// Helper function to get initial state from localStorage
const getInitialValue = (key: string, defaultValue: any) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};

const Inbox = () => {
  const auth0 = useAuth0();
  const userId = getBagelId(auth0.user);
  const { editCustomizations, owners } = useContext(SyncContext);
  const [evidencesList, evidencesHandlers] = useListState<any[]>([]);
  const [activePage, setActivePage] = useState(1);

  const MAX_REQUESTS_PER_PAGE = 25;

  const [loading, setLoading] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);
  const [my, setMy] = useState(getInitialValue("showMyEvidences", true));
  const [oldestFirst, setOldestFirst] = useState(
    getInitialValue("oldestFirst", false)
  );
  const [selectedMembers, setSelectedMembers] = useState(owners);
  const [selectedOwnersIds, setSelectedOwnersIds] = useState<string[]>(
    getInitialValue("owners-filters", [])
  );
  const [unAssigned, setUnassigned] = useState(
    getInitialValue("unAssigned", false)
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState("");

  const throttledGetEvidences = useThrottle(() => getEvidences(), 1000);
  const getEvidences = () => {
    localStorage.setItem("sort", oldestFirst.toString());
    setLoading(true);

    const params: any = {
      oldestFirst,
      filter: {
        unAssigned,
      },
      perPage: MAX_REQUESTS_PER_PAGE,
      page: activePage,
    };
    if (my) {
      const userId = getBagelId(auth0.user);
      params.filter.ownerIds = [userId];
    } else if (selectedOwnersIds.length > 0) {
      params.filter.ownerIds = selectedOwnersIds;
    } else {
      params.filter.ownerIds = [];
    }
    listEvidences(params, auth0)
      .then((res) => {
        evidencesHandlers.setState(res.evidences);
        console.log({ total: res.total, page: params.page });
        if (res.evidences.length == 0 && params.page > 1) {
          setActivePage(1);
        }
        setResultsCount(res.total);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const handleModalOpen = (evidence: any) => {
    setSearchParams({ evidenceId: evidence._id });
    openModal({
      id: "requestsInbox",
      size: "calc(100vw - 40px)",
      withCloseButton: false,
      onClose() {
        setSearchParams({});
      },
      padding: 0,
      children: (
        <EvidenceModal
          // evidence={()=>evidencesList.find((ev:any) => ev?._id === request._id)}
          evidence={evidence}
          evidencesHandlers={evidencesHandlers}
        />
      ),
    });
  };

  const fetchRequest = async (requestId: string) => {
    try {
      const request = await getEvidence(requestId, auth0);
      return request;
    } catch (error) {
      console.error("Error fetching the request:", error);
    }
  };

  useEffect(() => {
    const queryParams = searchParams;
    const requestId = queryParams.get("requestId");
    const evidenceId = queryParams.get("evidenceId");
    if (requestId || evidenceId) {
      (async () => {
        //@ts-ignore
        const requestRes = await fetchRequest(requestId || evidenceId);
        if (requestRes) {
          handleModalOpen(requestRes);
        }
      })();
    }
  }, []);

  useEffect(throttledGetEvidences, [oldestFirst]);
  useEffect(throttledGetEvidences, [my]);
  useEffect(throttledGetEvidences, [unAssigned]);

  useEffect(() => {
    getEvidences();
  }, [activePage]);

  useEffect(throttledGetEvidences, [selectedOwnersIds]);

  useEffect(() => {
    localStorage.setItem("owners-filters", JSON.stringify(selectedOwnersIds));
    localStorage.setItem("showMyEvidences", my.toString());
    localStorage.setItem("oldestFirst", oldestFirst.toString());
    localStorage.setItem("unAssigned", unAssigned.toString());
  }, [selectedOwnersIds, my, oldestFirst, unAssigned]);

  useEffect(() => {
    setSelectedMembers(owners);
  }, [owners]);

  const isAllSelected = owners.every((member: any) =>
    selectedOwnersIds.includes(member._id)
  );

  return (
    <Box>
      <Group
        h={"70px"}
        position="apart"
        w="calc(100vw - 56px)"
        mt={"xl"}
        pb={"lg"}
        sx={{ borderBottom: "1px #ccc solid" }}
        noWrap
      >
        <Box>
          <Flex lh="16px">
            <Text fz="18px" fw={600} ml={13}>
              Pending evidences
            </Text>
            <Text ml={5} color="#5C5CEB" fz="12px" mt={6} fw={500}>
              ({resultsCount})
            </Text>
          </Flex>
        </Box>
        <Box>
          <Pagination
            value={activePage}
            onChange={setActivePage}
            total={Math.ceil(resultsCount / MAX_REQUESTS_PER_PAGE)}
            withEdges
            withControls
            position="center"
            noWrap
          />
        </Box>
        <Box mr="sm">
          <Menu position="top-start">
            <Menu.Target>
              <Button
                variant="subtle"
                w={93}
                h={40}
                m={3}
                p={0}
                sx={{ borderRadius: "6px" }}
                color="gray"
              >
                <Text color="black"> Created</Text>
                <Text ml={0} mt={4} p={0} color="black">
                  {!oldestFirst && <IconArrowNarrowDown height={20} />}
                  {oldestFirst && <IconArrowNarrowUp height={20} />}
                </Text>{" "}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                w={150}
                onClick={() => {
                  setOldestFirst(!oldestFirst);
                  // sort(!oldestFirst);
                }}
              >
                <Flex>
                  {" "}
                  Newest{" "}
                  <Text ml={3}>
                    {!oldestFirst && <IconCheck size={15} />}
                  </Text>{" "}
                </Flex>
              </Menu.Item>
              <Menu.Item
                w={150}
                onClick={() => {
                  setOldestFirst(!oldestFirst);
                }}
              >
                <Flex>
                  {" "}
                  Oldest{" "}
                  <Text ml={3}>
                    {oldestFirst && <IconCheck size={15} />}
                  </Text>{" "}
                </Flex>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <Button
            disabled={selectedOwnersIds.length > 0 || unAssigned}
            w={90}
            h={40}
            m={3}
            variant={my ? "filled" : "default"}
            sx={
              my
                ? {
                  backgroundColor: "#9F9AF8",
                  borderColor: "#FFF",
                  width: 26,
                  height: 26,
                  minHeight: 26,
                  minWidth: 26,
                  borderRadius: "6px",
                  textAlign: "left",
                  ":hover": {
                    backgroundColor: "#5B60E3",
                  },
                }
                : {}
            }
            onClick={() => {
              setMy(!my);
            }}
          >
            <Text>
              <IconUser height={15} color="black" />
            </Text>

            <Text>Me</Text>
          </Button>
          <Menu position="top-start" width={250} shadow="sm" withArrow>
            <Menu.Target>
              <Button
                disabled={my || unAssigned}
                variant={selectedOwnersIds.length > 0 ? "filled" : "default"}
                sx={
                  selectedOwnersIds.length > 0
                    ? {
                      backgroundColor: "#9F9AF8",
                      borderColor: "#FFF",
                      width: 26,
                      height: 26,
                      minHeight: 26,
                      minWidth: 26,
                      borderRadius: "6px",
                      ":hover": { backgroundColor: "#5B60E3" },
                    }
                    : {}
                }
                w={120}
                h={40}
                m={3}
              >
                <Text>
                  <IconUsers height={15} color="black" />
                </Text>
                Owners
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Box
                sx={{
                  zIndex: 101,
                }}
              >
                <Group position="apart" noWrap spacing={6} mx={5} mt={3}>
                  <Tooltip label="Deselect all">
                    <Checkbox
                      checked={isAllSelected}
                      // icon={({className}) => <IconX size={20} width={5} className={className} />}
                      onChange={(event) => {
                        if (event.currentTarget.checked) {
                          setSelectedOwnersIds(owners.map((m: any) => m._id));
                        } else {
                          setSelectedOwnersIds([]);
                        }
                      }}
                    />
                    {/* <ActionIcon
                      disabled={selectedOwnersIds.length === 0}
                      sx={{
                        backgroundColor: "#9F9AF8",
                        borderColor: "#FFF",
                        borderRadius: "6px",
                        height:36,
                        ":hover": { backgroundColor: "#5B60E3" },
                      }}
                      variant="default"
                      onClick={() => {
                        setSelectedOwnersIds([]);
                      }}
                    >
                      {" "}
                      <IconUserMinus size={16} />
                    </ActionIcon> */}
                  </Tooltip>
                  <TextInput
                    // m={8}
                    placeholder="Type here"
                    icon={<IconListSearch size={14.5} />}
                    value={query}
                    onChange={(event) => {
                      setQuery(event.currentTarget.value);
                      console.log(event.currentTarget.value);
                      if (event.currentTarget.value?.trim() === "") {
                        setSelectedMembers(owners);
                      } else {
                        const splitNames = owners.filter((n: any) =>
                          n.name
                            .toLowerCase()
                            .startsWith(event.currentTarget.value)
                        );
                        setSelectedMembers(splitNames);
                      }
                    }}
                  />
                </Group>
                <ScrollArea h={230} type="scroll">
                  <Checkbox.Group
                    mx={5}
                    mb="sm"
                    value={selectedOwnersIds}
                    onChange={setSelectedOwnersIds}
                    /* orientation="vertical" */
                  >
                    {selectedMembers.map((m: any) => (
                      <Checkbox
                        value={m._id}
                        key={m._id}
                        label={m.name}
                        my={16}
                      />
                    ))}
                  </Checkbox.Group>
                </ScrollArea>
              </Box>
            </Menu.Dropdown>
          </Menu>
          <Button
            disabled={selectedOwnersIds.length > 0 || my}
            h={40}
            m={3}
            variant={unAssigned ? "filled" : "default"}
            sx={
              unAssigned
                ? {
                  backgroundColor: "#9F9AF8",
                  borderColor: "#FFF",
                  borderRadius: "6px",
                  textAlign: "left",
                  ":hover": {
                    backgroundColor: "#5B60E3",
                  },
                }
                : {}
            }
            onClick={() => setUnassigned(!unAssigned)}
          >
            <Text mr={5}>
              <IconUserX size={16} />
            </Text>

            <Text>Unassigned</Text>
          </Button>
        </Box>
      </Group>
      <Box
        sx={{
          overflowY: "scroll",
          width: "calc(100vw - 56px)",
          height: "calc(100vh - 156px)",
          paddingBottom: 20,
        }}
      >
        <LoadingOverlay visible={loading} overlayBlur={2} zIndex={5} />
        <Box>
          {evidencesList.length == 0 && (
            <Box mt={6} pt={6}>
              <Text ta="center" c="dimmed" mt={6} pt={6}>
                No requests found...
              </Text>
            </Box>
          )}

          {evidencesList.map((ev: any) => (
            <EvidenceCard
              key={ev._id}
              evidence={ev}
              evidences={evidencesList}
              onAction={() => getEvidences()}
              members={selectedMembers}
              evidencesHandlers={evidencesHandlers}
              handleModalOpen={handleModalOpen}
            />
          ))}
          {evidencesList.length == 0 &&
            loading &&
            [1, 2, 3, 4].map((i) => (
              <Skeleton key={i} m="sm" mb={0} height={120} />
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Inbox;
