import axios from "axios";
import { ACCESSORS } from "./components/ImportItems/CsvDataTable";
import ImportItems from "./components/ImportItems";

export interface SearchResults {
  members: any[];
  users: any[];
  accounts: [];
  evidence: any[];
}

export type CustomFieldData = {
  description: string;
  name: string;
  searcherKey: string;
  type: string;
  dataAccessor: string;
};

export const getAuthHeaders = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const token = await auth0.getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      resolve({ headers });
    } catch (e) {
      reject(e);
    }
  });
};

export const getSettingsForAccount = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/settings/",
        config
      );
      resolve(response.data.account);
    } catch (e) {
      reject(e);
    }
  });
};


export const listMembers = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/users/list",
        config
      );
      resolve(response.data.users);
    } catch (e) {
      reject(e);
    }
  });
};

export const listOwners = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/users/listOwners",
        config
      );
      resolve(response.data.users);
    } catch (e) {
      reject(e);
    }
  });
};

export const listTeams = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/teams/list",
        config
      );
      resolve(response.data.teams);
    } catch (e) {
      reject(e);
    }
  });
};

// export const getIntegrations = (auth0: any) => {
//   return new Promise<any>(async (resolve, reject) => {
//     try {
//       const config = await getAuthHeaders(auth0);
//       const response = await axios.get(
//         window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/integrations/list",
//         config
//       );
//       resolve(response.data.integrations);
//     } catch (e) {
//       reject(e);
//     }
//   });
// };

// export const fetchIntegration = (auth0: any, type: string) => {
//   return new Promise<any>(async (resolve, reject) => {
//     try {
//       const config = await getAuthHeaders(auth0);
//       const response = await axios.get(
//         `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/integrations/${type}`,
//         config
//       );
//       resolve(response.data.integration);
//     } catch (e) {
//       reject(e);
//     }
//   });
// };

// export const createToken = (auth0: any, type: string, accountId: string) => {
//   return new Promise<any>(async (resolve, reject) => {
//     try {
//       const config = await getAuthHeaders(auth0);
//       const response = await axios.post(
//         `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/integrations/${type}/createToken`,
//         { accountId }, // Pass accountId in the body
//         config
//       );
//       resolve(response.data); // Token creation response
//     } catch (e) {
//       reject(e);
//     }
//   });
// };

export const createAPIToken = (auth0: any, name: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.post(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/settings/token/api`,
        { name }, // Pass name in the body
        config
      );
      resolve(response.data); // Token creation response
    } catch (e) {
      reject(e);
    }
  });
};


export const listTokens = (auth0: any, type: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/settings/token/${type}`,
        config
      );
      console.log(response.data.tokenDoc)
      resolve(response.data.tokenDoc);
    } catch (e) {
      reject(e);
    }
  });
};

export const listSlackChannels = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/integrations/channel-list",
        config
      );
      resolve(response.data.channels);
    } catch (e) {
      reject(e);
    }
  });
};

export const listSolutions = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/solutions/list",
        config
      );
      resolve(response.data.solutions);
    } catch (e) {
      reject(e);
    }
  });
};

export const listCompanies = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/companies/list",
        config
      );
      resolve(response.data.companies);
    } catch (e) {
      reject(e);
    }
  });
};

export const listOpTypes = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/fields/opType",
        config
      );
      resolve(response.data.opTypes);
    } catch (e) {
      reject(e);
    }
  });
};

export const listFields = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/fields/list",
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const listFieldsCustomizationsList = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/fields/customizationsList",
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const listObjectsFields = (type: "priority" | "status", auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + `/fields/list/${type}`,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const updateObjectsFields = (auth0: any, id: string, body: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      console.log("updateObjectsFields", { body });
      const config = await getAuthHeaders(auth0);
      const response = await axios.put(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + `/fields/${id}`,
        body,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteOptionAndReassign = (
  auth0: any,
  id: string,
  type: "status" | "priority" | "component",
  oldKey: string,
  newKey: string,
  options: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.put(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          `/fields/${id}/reassign-tags`,
        { type, oldKey, newKey, options },
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const listRequests = (
  params: { oldestFirst: boolean; filter: { ownerIds: string[] } },
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.post(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/requests/list",
        params,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const dismissSuggested = (
  auth0: any,
  matchId: string,
  ideaId: string,
  textOrRequestId: string,
  textType: boolean
  
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.post(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/ideas/dismissSuggested",
        { matchId, ideaId, textOrRequestId, textType },
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const dismissFeedback = (
  requestId: string,
  rejectInfo: { reason: string; comment: string },
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.post(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/ideas/dismissRequest",
        { requestId, rejectInfo },
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const getIdea = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/ideas/" + id;
      const response = await axios.get(url, config);
      resolve(response.data.idea);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getIdeaRequests = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/ideas/" +
        id +
        "/requests";
      const response = await axios.get(url, config);
      resolve(response.data);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getUnreadNotificationsCount = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/ideas/" +
        id +
        "/requestsUnreadNotificationsCount";
      const response = await axios.get(url, config);
      resolve(response.data);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};


export const getIdeaMatches = (
  id: string,
  auth0: any,
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/ideas/" +
        id +
        "/matches";
      const response = await axios.get(url,  config);
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const getRequestMatches = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/requests/" +
        id +
        "/matches";
      const response = await axios.get(url, config);
      resolve(response.data.matches);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getIdeaMatchesForEvidence = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/matches/" +
        id +
        "/matches";
      const response = await axios.get(url, config);
      resolve(response.data.matches);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const saveIdea = (idea: any, auth0: any) => {

  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/ideas/update";
      const response = await axios.post(url, { idea }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const addRequestsToIdea = (
  auth0: any,
  ideaId: string,
  request: any,
  matchId?: boolean
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/ideas/addRequestsToIdea";
      const response = await axios.post(
        url,
        { ideaId, request, matchId },
        config
      );
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const attachRequestToIdea = (
  ideaId: string,
  conversationId: string,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/ideas/" +
        ideaId +
        "/attach";
      const response = await axios.post(url, { conversationId }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const detachRequest = (
  ideaId: string,
  requestId: string,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/ideas/" +
        ideaId +
        "/detach";
      const response = await axios.post(url, { requestId }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const validateRequest = (
  auth0: any,
  requestId: string,
  ideaId?: string,
  matchId?: string
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/ideas/validateRequest";
      const response = await axios.post(url, { requestId, ideaId, matchId }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const validateMatch = (
  auth0: any,
  matchId: string,
  ideaId: string,
  request: any,
  textId?: string
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      if (!(ideaId?.length > 0 && matchId?.length > 0)) {
        return reject("Invalid ideaId or matchId");
      }
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/ideas/validateMatch";
      const response = await axios.post(
        url,
        { matchId, ideaId, textId, request },
        config
      );
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const deleteIdea = (ideaId: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/ideas/" + ideaId;
      const response = await axios.delete(url, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const addCommentToIdea = (
  ideaId: string,
  comment: string,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/ideas/comment`;
      const response = await axios.post(
        url,
        { ideaId, comment: { text: comment } },
        config
      );
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const addCommentToRequest = (
  ideaId: string,
  requestId: string,
  comment: string,
  usersMentioned: {
    _id: string;
    name: string;
  }[],
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/requests/comment`;
      const response = await axios.post(
        url,
        { ideaId, requestId, comment: { text: comment, usersMentioned } },
        config
      );
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const updateRequestComment = (
  commentId: string,
  comment: string,
  usersMentioned: {
    _id: string;
    name: string;
  }[],
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/requests/comment/${commentId}`;
      const response = await axios.post(
        url,
        { commentId, comment: { text: comment, usersMentioned } },
        config
      );
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const deleteCommentFromRequest = (
  ideaId: string,
  commentId: string,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/requests/comment/${commentId}/delete`;
      const response = await axios.post(url, { ideaId, commentId }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getSolution = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/solutions/" + id;
      const response = await axios.get(url, config);
      resolve(response.data.solution);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getSolutionCategories = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/solutions/categories";
      const response = await axios.get(url, config);
      resolve(response.data.categories);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const filterSolutions = (filters: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/solutions/filter";
      const response = await axios.post(url, { filters }, config);
      resolve(response.data.solutions);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const createNewIdea = (
  { idea, request, productItemId }: any,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/ideas";
      const response = await axios.post(
        url,
        { idea, request, productItemId },
        config
      );
      resolve(response.data);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const convertIdeaDiscovery = (fields: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/ideas/discovery`;
      const response = await axios.post(url, {fields} ,config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const assignRequestToNewIdea = (
  { idea, evidence, productItemId }: any,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/ideas/assignRequestToNewIdea";
      const response = await axios.post(
        url,
        { idea, evidence, productItemId },
        config
      );
      resolve(response.data);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getFilteredIdeas = (
  filters: any,
  sort: { [name: string]: 1 | -1 },
  limit: number,
  skip: number,
  auth0: any
) => {
  return new Promise<{ totalCount: number; ideas: any[] }>(
    async (resolve, reject) => {
      try {
        const config = await getAuthHeaders(auth0);
        const url =
          window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/ideas/filters_query";
        const response = await axios.post(
          url,
          { filters, sort, limit, skip },
          config
        );
        resolve(response.data);
      } catch (e: any) {
        console.log(e);
        reject(e);
      }
    }
  );
};

export const saveIdeaStarred = (idea: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/ideas/starred";
      const response = await axios.post(url, { idea }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const saveRequestStarred = (idea: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/requests/starred";
      const response = await axios.post(url, { idea }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getSolutionMatches = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/solutions/" +
        id +
        "/matches";
      const response = await axios.get(url, config);
      resolve(response.data.matches);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const saveSolution = (solution: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/solutions/update";
      const response = await axios.post(url, { solution }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const saveRequest = (request: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/requests/update";
      const response = await axios.post(url, { request }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const saveStarred = (solution: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/solutions/starred";
      const response = await axios.post(url, { solution }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const saveUpvote = (solution: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/solutions/upvote";
      const response = await axios.post(url, { solution }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const deleteSolution = (solutionId: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/solutions/" +
        solutionId;
      const response = await axios.delete(url, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const deleteToken = (auth0: any, tokenId: string)  => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + `/settings/token/${tokenId}`;
      const response = await axios.delete(url, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const searchIdeas = (query: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.post(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/search/ideas",
        { query },
        config
      );
      resolve(response.data.ideas);
    } catch (e) {
      reject(e);
    }
  });
};

export const searchAll = (query: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/search?query=" +
          query,
        config
      );
      resolve(response.data.results);
    } catch (e) {
      reject(e);
    }
  });
};


export const searchOpportunityNiv = (query: string ,auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.put(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/search/opportunityNIV?query=" ,
        query,
        config
      );
      resolve(response.data.results);
    } catch (e) {
      reject(e);
    }
  });
};

export const searchEvidences = (query: string ,auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.put(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/search/evidences" ,
        {query : query},
        config
      );
      resolve(response.data.evidences);
    } catch (e) {
      reject(e);
    }
  });
};

export const saveSettings = (settings: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/settings/";
      const response = await axios.post(url, { settings }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const saveDomains = (
  data: { domain: string; push: boolean },
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/settings/domains";
      const response = await axios.post(url, { data }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const updateCategory = (category: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/settings/categories?operation=update";
      const response = await axios.post(url, { ...category }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const insertCategory = (category: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/settings/categories?operation=insert";
      const response = await axios.post(url, { ...category }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const deleteCategory = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        `/settings/categories/${id}`;
      const response = await axios.delete(url, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getOrganization = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/companies/" + id;
      const response = await axios.get(url, config);
      resolve(response.data.company);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getOrganizationItems = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/companies/" +
        id +
        "/matches";
      const response = await axios.get(url, config);
      resolve(response.data.matches);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getClusterTickets = (clusterId: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/clusters/" +
        clusterId;
      const response = await axios.get(url, config);
      resolve(response.data.tickets);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const searchUsers = (query: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/search/users";
      const response = await axios.post(url, { query }, config);
      resolve(response.data.users);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const searchAdmins = (query: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/search/admins";
      const response = await axios.post(url, { query }, config);
      resolve(response.data.admins);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const searchMembers = (query: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/search/members";
      const response = await axios.post(url, { query }, config);
      resolve(response.data.members);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const searchOrgs = (
  { query }: { query: string },
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/search/orgs";
      const response = await axios.post(url, { query }, config);
      resolve(response.data);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const searchSelectedOrgs = (
  { query, ids }: { query: string; ids?: string[] },
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/search/searchSelectedOrgs";
      const response = await axios.post(url, { query, ids }, config);
      resolve(response.data.accounts);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};


export const getSuggestionsForIdea = (
  title: string,
  text: string,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_MLAPI_DOMAIN + "/suggestions/idea";
      const response = await axios.post(
        url,
        { title, description: text },
        config
      );
      resolve(response.data);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getAllTickets = (
  type: string,
  page: number,
  pageSize: number,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/conversations/list?type=${type}&page=${page}&pageSize=${pageSize}`;
      const response = await axios.get(url, config);
      resolve(response.data.conversations);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const updateTicketLabeling = (
  auth0: any,
  ticketId: string,
  ticketType: string,
  highlighted?: string[],
  blocks?: any[]
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/conversations/update`;
      const response = await axios.post(
        url,
        { ticketId, ticketType, highlighted, blocks },
        config
      );
      resolve(response.data.success);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const verifyEmail = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/users/verifyEmail`;
      const response = await axios.post(url, {}, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const inviteUser = (
  auth0: any,
  user: { name: string; email: string; role: string }
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/users/invite`;
      const response = await axios.post(url, { user }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getTopIdeasReport = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/ideas/top-ideas";
      const response = await axios.get(url, config);
      resolve(response.data.requests);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const createTeam = (team: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/teams";
      const response = await axios.post(url, { team }, config);
      resolve(response.data);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getViews = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/ideas/views/get",
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const addViews = (body: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/ideas/views/add";
      const response = await axios.post(url, body, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const deleteTeam = (teamId: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/teams/" + teamId;
      const response = await axios.delete(url, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const saveMember = (teamId: string, memberId: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/teams/addMember";
      const response = await axios.post(url, { teamId, memberId }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const deleteMember = (teamId: string, memberId: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/teams/deleteMember";
      const response = await axios.post(url, { teamId, memberId }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const canEditTeamIds = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/fields/canEditTeamIds",
        config
      );
      resolve(response.data.customizations);
    } catch (e) {
      reject(e);
    }
  });
};

export const listNotifications = (auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/users/notifications",
        config
      );
      resolve(response.data.notifications);
    } catch (e) {
      reject(e);
    }
  });
};

export const markNotificationsRead = (auth0: any, id = '', mode: string) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.post(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/users/markNotificationsRead",
        {id: id, mode: mode},
        config
      );
      resolve(response);
    } catch (e) {
      reject(e);
    }
  });
};

export const markItemNotificationsRead = (itemId: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.post(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/users/markItemNotificationsRead",
        {itemId},
        config
      );
      resolve(response);
    } catch (e) {
      reject(e);
    }
  });
};

export const updateMember = (user: any, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/users/update";
      const response = await axios.post(url, { user }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getTopRequests = (
  limit: number,
  activeProductArea: any[],
  activeTopBy: string,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/reports/top-ideas";
      const response = await axios.post(
        url,
        { limit, activeProductArea, activeTopBy },
        config
      );
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const reassignRequest = (
  auth0: any,
  ideaId: string,
  evidenceId: string,
  isGap?: boolean,
  matchId?: string,
  isMatchRelevant?: boolean
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/ideas/reassignRequest";
      const response = await axios.post(url, { evidenceId , ideaId, isGap , matchId, isMatchRelevant }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const searchProductItems = (type: string, query: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.post(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/search/product-items",
        { query, type },
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const linkToProductItem = (
  ideaId: string,
  productItemId: string,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/ideas/linkToProductItem";
      const response = await axios.post(url, { productItemId, ideaId }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const unLinkToProductItem = (
  ideaId: string,
  productItemId: string,
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/ideas/unLinkToProductItem";
      const response = await axios.post(url, { productItemId, ideaId }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getRequest = (requestId: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/requests/${requestId}`,
        config
      );
      resolve(response.data?.request);
    } catch (e) {
      reject(e);
    }
  });
};

export const getCommentsForRequest = (requestId: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/requests/${requestId}/comments`,
        config
      );
      resolve(response);
    } catch (e) {
      reject(e);
    }
  });
};

export const validateData = (
  auth0: any,
  data: {
    userEmails?: string[];
    sfIds?: string[];
    oppIds?: string[];
    domainsComponents?: {
      [key: string]: string;
    }[];
  }
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);

      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + `/import/validate`;
      const response = await axios.post(url, data, config);
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const runImport = (auth0: any, data: { rows: any[] }) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);

      const url = window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + `/import`;
      const response = await axios.post(url, data, config);
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const runImportEvidence = (auth0: any, data: { rows: any[] }) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);

      const url = window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + `/import/evidence`;
      const response = await axios.post(url, data, config);
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const getIdeasExportData = (
  auth0: any,
  data: { dates: any }
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);

      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        `/import/ideasExportData`;
      const response = await axios.post(url, data, {...config, timeout: 300000});
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const getEvidencesExportData = (
  auth0: any,
  data: { dates: [Date | null, Date | null] }
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);

      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        `/import/requestsExportData`;
      const response = await axios.post(url, data, {...config, timeout: 300000});
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const getIdeaPageDisplay = (type: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.get(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          `/fields/display/${type}`,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const updateIdeaPageDisplay = (
  body: { value: string; use: boolean },
  auth0: any
) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const response = await axios.put(
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
          "/fields/display/update",
        body,
        config
      );
      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

export const getRelatedCxItem = (itemId: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/cx-items/${itemId}`;
      const response = await axios.get(url, config);
      resolve(response?.data);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getRequestsMatchesForAnIdea = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/ideas/" +
        id +
        "/requestsMatches";
      const response = await axios.get(url, config);
      resolve(response.data);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const getEvidenceURL = (id: string, auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN +
        "/requests/" +
        id +
        "/evidence";
      const response = await axios.get(url, config);
      resolve(response.data.evidence);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

export const labelMatch = (matchId: string, ideaId: string, textId: string, sameRequest: boolean ,auth0: any) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url =
        window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN + "/matches/label";
      const response = await axios.post(url, { matchId, ideaId, textId, sameRequest }, config);
      resolve(response);
    } catch (e: any) {
      console.log(e);
      reject(e);
    }
  });
};

// Get customizations property init
export const getCustomizationsPropertyInit = (auth0: any): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const config = await getAuthHeaders(auth0);
      const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/customizations/property_init`;
      const response = await axios.get(url, config);
      resolve(response.data);
    } catch (e: any) {
      console.error(e);
      reject(e);
    }
  });
};

/**
 * Adds a new property to the "idea" customization.
 * 
 * @param auth0 - Auth0 instance for authentication
 * @param property - Property object to add
 * @returns A promise resolving to the API response
 */
export const addProperty = async (auth0: any, property: any): Promise<any> => {
  try {
    const token = await auth0.getAccessTokenSilently();
    const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/customizations/properties`;
    const response = await axios.post(url, property, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error("Error adding property:", error);
    throw error;
  }
};

/**
 * Updates an existing property in the "idea" customization.
 * 
 * @param auth0 - Auth0 instance for authentication
 * @param propertyName - Name of the property to update
 * @param updatedProperty - Updated property object
 * @returns A promise resolving to the API response
 */
export const updateProperty = async (
  auth0: any,
  propertyName: string,
  updatedProperty: any
): Promise<any> => {
  try {
    const token = await auth0.getAccessTokenSilently();
    const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/customizations/properties/${propertyName}`;
    const response = await axios.put(url, updatedProperty, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error("Error updating property:", error);
    throw error;
  }
};

/**
 * Deletes a property from the "idea" customization.
 * 
 * @param auth0 - Auth0 instance for authentication
 * @param propertyName - Name of the property to delete
 * @returns A promise resolving to the API response
 */
export const deleteProperty = async (
  auth0: any,
  propertyName: string
): Promise<any> => {
  try {
    const token = await auth0.getAccessTokenSilently();
    const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/customizations/properties/${propertyName}`;
    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error("Error deleting property:", error);
    throw error;
  }
};


/**
 * runs Aggregations for all ideas.
 * 
 * @param auth0 - Auth0 instance for authentication
 * @returns A promise resolving to the API response
 */
export const runAggregations = async (
  auth0: any,
): Promise<any> => {
  try {
    const token = await auth0.getAccessTokenSilently();
    const url = `${window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN}/admin/runAggregations`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error("Error deleting property:", error);
    throw error;
  }
};
