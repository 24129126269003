import {
  Box,
  Flex,
  Modal,
  ScrollArea,
  Text
} from "@mantine/core";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import SyncContext from "../../../../../../context/SyncContext";
import { getRelatedCxItem } from "../../../../../../Api";
import GongCallPart from "./GongCallPart";
import CallTopInfo from "./CallTopInfo";
import CallTitle from "./CallTitle";
import CallTextArea from "./CallTextArea";
import { IconArrowDown, IconArrowUp } from "@tabler/icons";

const GongCall = ({ item }: { item: any }) => {




  console.log('item')


  console.log(item)


  const auth0 = useAuth0();

  const { members } = useContext(SyncContext);

  const [opened, setOpened] = useState(false);
  const [gongCallParts, setGongCallParts] = useState<any[]>([]);
  const [bagelUsers, setBagelUsers] = useState<any[]>([]);
  const [externalUsers, setExternalUsers] = useState<any[]>([]);
  const [callDuration, setCallDuration] = useState<string>("");
  const [refsIndexes, setRefsIndexes] = useState<any[]>([]);

  const refsArr = useRef<(HTMLDivElement | null)[]>([]);
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);
  
  const scrollToElement = (index: number) => {

    if (refsArr.current[index]) {
      refsArr.current[index]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToTop = () => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  
  const excerptFirstPartMs =  item?.ml?.excerptFirstPartMs;
  const title = item?.title;
  const text = item?.text;
  const cxItem = item?.cxItem;
  const system = cxItem?.system || "";
  const createdAtDate = cxItem?.createdAt
    ? new Date(cxItem?.createdAt)
    : new Date();
  const dateDisplay = moment(createdAtDate).format("MM-DD-YY");
  const origin = cxItem?.origin || item?.origin;
  const excerptsArr: any [] =  item?.excerpts || [];

  const loadGongCall = (cxItemIdForGong: string) => {
    getRelatedCxItem(cxItemIdForGong, auth0)
      .then((res) => {
        console.log({res})
        const parties = res?.parties || [];
        if (parties.length > 0) {
          parties.forEach((p: any) => {
            const mail = p?.emailAddress;
            const foundMember = members?.find((m: any) => m.email === mail);
            if (foundMember) {
              p.isBagelUser = true;
            }
          });
        }

        const bagelParties = parties?.filter((p: any) => p.isBagelUser);
        const externalParties = parties?.filter((p: any) => !p.isBagelUser);
        setBagelUsers(bagelParties);
        setExternalUsers(externalParties);
        const items = res?.parts || {};

        const itemsArray: any[] = Object.values(items);
        if (itemsArray?.length > 0) {
          const length = itemsArray?.length;
          const durationMs = itemsArray[length - 1]?.startMs || 0;
          const duration = formatDuration(durationMs);
          setCallDuration(duration);
          itemsArray.map((itemCall: any, index: number) => {
            excerptsArr.forEach((excerptItem: any) => {

              let indexForExcerpt = 0
              const excerptFromArrayFirstPartMs = excerptItem?.excerptFirstPartMs;
              const excerptFromArrayLastPartMs = excerptItem?.excerptLastPartMs;

              if (itemCall.startMs === excerptFromArrayFirstPartMs) {
                itemCall.isFirstInExcerpt = true;
                setRefsIndexes((prevIndexes) => [...prevIndexes, index]); 
              }

              if (
                itemCall.startMs >= excerptFromArrayFirstPartMs &&
                itemCall.startMs <= excerptFromArrayLastPartMs
              ) {
                itemCall.isExcerpt = true;
                itemCall.excerptIndex = indexForExcerpt;
                indexForExcerpt = indexForExcerpt++
              }

              if (itemCall.startMs === excerptFromArrayLastPartMs) {
                itemCall.isLastInExcerpt = true;
                
              }

            });

            const text = itemCall.text;
            const split = text.split(":");
            itemCall.cleanText = split[1];
          });

          setGongCallParts(itemsArray);
        }
      })
      .catch((e) => console.log(e));
  };

  const formatDuration = (milliseconds: number): string => {
    const totalMinutes = Math.floor(milliseconds / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else {
      return `${minutes}m`;
    }
  };

  useEffect(() => {

    setRefsIndexes([]);
    

    if (item?.cxItemId) {
      loadGongCall(item?.cxItemId);
    }
  }, [item]);

  return (
    <Box mt={15}>
      {opened && <Modal
        opened={opened}
        centered
        onClose={() => setOpened(false)}
        size="750px"
        padding={0}
        styles={{
          close: {
            marginRight: "40px", // Adjust margin for the close button
            marginTop: "16px",
          },
        }}
        title={<CallTitle cxItem={cxItem} origin={origin} />}
      >
        <Box sx={{ borderTop: "1px solid #D8D8DB" }} w="100%">
          <ScrollArea
            w={720}
            h={542}
            mah={542}
            p={36}
            offsetScrollbars
            type="always"
            viewportRef={scrollAreaRef}
          >
            <Box>
              <CallTopInfo
                dateDisplay={dateDisplay}
                system={system}
                callDuration={callDuration}
                bagelUsers={bagelUsers}
                externalUsers={externalUsers}
              />
              {/*  excerpt */}
              {(excerptFirstPartMs && excerptsArr?.length > 0) && (
                <Text
                  color="#5C5CEB"
                  px="sm"
                  my="md"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    const firstExcerptIndex = refsIndexes[0]
                    scrollToElement(firstExcerptIndex)
                  }}
                  underline
                >
                  Jump to first excerpt
                </Text>
              )}

              {gongCallParts && gongCallParts?.length > 0 ? (
                gongCallParts?.map((callItem: any, index: number) =>  (

                  <Box key={index} ref={callItem?.isExcerpt ? (el) => {refsArr.current[index] = el} : null} bg={callItem.isExcerpt ? "#FFFDD4" : ""}>
                    <GongCallPart
                      callItem={callItem}
                      createdAtDate={createdAtDate}
                    />
                    { (refsIndexes.every((element: number) => index >= element) &&
                      callItem.isLastInExcerpt) ?
                      <Flex 
                        sx={{cursor: 'pointer'}}
                        ml={50}
                        pb='sm' 
                        mt='sm'  
                        onClick={scrollToTop}> 
                        <IconArrowUp size={16} color="#5C5CEB"/>
                        <Text ml={4} color="#5C5CEB">Back to top</Text> 
                      </Flex>
                      : !refsIndexes.every((element: number) => index > element) &&
                      callItem.isLastInExcerpt ? 
                        <Flex 
                          sx={{cursor: 'pointer'}}
                          ml={50}
                          pb='sm' 
                          mt='sm'  
                          onClick={() => {
                            const nextExcerptIndex = Array.from(refsIndexes).find((refIndex) => refIndex > index);
                            scrollToElement(nextExcerptIndex)
                          }}> 
                          <IconArrowDown size={16} color="#5C5CEB"/>
                          <Text ml={4} color="#5C5CEB">Next</Text> 
                        </Flex> : <Box></Box>
                    }
                  </Box>
                ) )
              ) : (
                <Flex justify="center">
                  <Text ml={80}>Gong call not available</Text>
                </Flex>
              )}
              {gongCallParts?.length > 0 && (
                <Flex justify="center" fw={600} fz={18} m="md">
                  The End
                </Flex>
              )}
            </Box>
          </ScrollArea>
        </Box>
      </Modal>}
      <CallTextArea
        title={title}
        text={text}
        excerptsArr={excerptsArr}
        setOpened={setOpened}
      />
    </Box>
  );
};

export default GongCall;
