// src/Api.comments.ts

import axios from "axios";

const API_DOMAIN = window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN;

/**
 * Retrieves the authorization headers using Auth0.
 * @param auth0 - The Auth0 instance for authentication.
 * @returns An object containing the authorization headers.
 * @throws If retrieving the access token fails.
 */
const getAuthHeaders = async (auth0: any) => {
  try {
    const token = await auth0.getAccessTokenSilently();
    return { headers: { Authorization: `Bearer ${token}` } };
  } catch (e) {
    console.error("Error retrieving auth headers:", e);
    throw e;
  }
};

/**
 * Fetches comments associated with a specific evidence.
 * @param evidenceId - The ID of the evidence.
 * @param auth0 - The Auth0 instance for authentication.
 * @returns A promise resolving to the list of comments.
 */
export const listComments = async (
  evidenceId: string,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/comments?evidenceId=${encodeURIComponent(evidenceId)}`;
    const response = await axios.get(url, config);
    return response.data.comments;
  } catch (e: any) {
    console.error("Error fetching comments:", e);
    throw e;
  }
};

/**
 * Creates a new comment for a specific evidence.
 * @param evidenceId - The ID of the evidence.
 * @param comment - The text content of the comment.
 * @param usersMentioned - An array of users mentioned in the comment.
 * @param auth0 - The Auth0 instance for authentication.
 * @returns A promise resolving to the newly created comment.
 */
export const createComment = async (
  evidenceId: string,
  comment: string,
  usersMentioned: { _id: string}[],
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/comments?evidenceId=${encodeURIComponent(evidenceId)}`;
    const payload = {
      comment: {
        text: comment,
        usersMentioned,
      },
    };
    const response = await axios.post(url, payload, config);
    return response.data;
  } catch (e: any) {
    console.error("Error creating comment:", e);
    throw e;
  }
};

/**
 * Updates an existing comment's content.
 * @param evidenceId - The ID of the evidence.
 * @param commentId - The ID of the comment to update.
 * @param comment - The updated text content of the comment.
 * @param usersMentioned - An array of users mentioned in the updated comment.
 * @param auth0 - The Auth0 instance for authentication.
 * @returns A promise resolving to the updated comment.
 */
export const updateComment = async (
  commentId: string,
  comment: string,
  usersMentioned: { _id: string }[],
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/comments/${encodeURIComponent(
      commentId
    )}`;
    const payload = {
      comment: {
        text: comment,
        usersMentioned,
      },
    };
    const response = await axios.put(url, payload, config);
    return response.data;
  } catch (e: any) {
    console.error("Error updating comment:", e);
    throw e;
  }
};

/**
 * Updates specific data fields of an existing comment.
 * Useful for partial updates like modifying mentions.
 * @param evidenceId - The ID of the evidence.
 * @param commentId - The ID of the comment to update.
 * @param commentData - An object containing the fields to update.
 * @param auth0 - The Auth0 instance for authentication.
 * @returns A promise resolving to the updated comment data.
 */
export const updateCommentData = async (
  evidenceId: string,
  commentId: string,
  commentData: any,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/comments/${encodeURIComponent(
      commentId
    )}/update?evidenceId=${encodeURIComponent(evidenceId)}`;
    const response = await axios.put(url, commentData, config);
    return response.data;
  } catch (e: any) {
    console.error("Error updating comment data:", e);
    throw e;
  }
};

/**
 * Deletes an existing comment.
 * @param evidenceId - The ID of the evidence.
 * @param commentId - The ID of the comment to delete.
 * @param auth0 - The Auth0 instance for authentication.
 * @returns A promise resolving to the deletion status.
 */
export const deleteComment = async (
  evidenceId: string,
  commentId: string,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const url = `${API_DOMAIN}/comments/${encodeURIComponent(
      commentId
    )}?evidenceId=${encodeURIComponent(evidenceId)}`;
    const response = await axios.delete(url, config);
    return response.data;
  } catch (e: any) {
    console.error("Error deleting comment:", e);
    throw e;
  }
};