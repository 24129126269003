import { Box, Card, Flex, Text} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { stripHtml } from "../../../utils";

const SimilarIdeaCard = ({ idea }: { idea: any }) => {
  
  const { hovered, ref } = useHover();
  
  return (
    <Card
      p={12}
      my={16}
      mx={36}
      ref={ref}
      bg={hovered ? "#F0F0FA" : "#FFF"}
      sx={{ cursor: "pointer", 
        borderRadius: 10,
        border: hovered ? "1px solid #5C5CEB" : "1px solid #DFDEFD"
      }}
      onClick={()=>{window.open(`/idea/${idea._id}`)}}

    >
      <Box mb={4}>
        <Text fz={14} fw={600} color="#212529" lineClamp={1}>
          {idea?.title || "N/A"}
        </Text>
      </Box>

      <Box>
        <Text fz={12} fw={400} color="#585C68" lineClamp={1}>
          { idea?.description ? stripHtml(idea?.description) :  "N/A"}
        </Text>
      </Box>

      <Flex justify="space-between" align="center">
        <Flex justify="flex-start" mt="md" gap={10}>
          <Box sx={{ borderRight: "1px solid #D8D8DB" }} pr={16}>
            <Text size="xs" color="dimmed">
              Owner{" "}
            </Text>
            <Text size="xs" fw={600} color="#212529">
              {" "}
              {idea?.owner?.name || "-"}
            </Text>
          </Box>
          <Box sx={{ borderRight: "1px solid #D8D8DB" }} pr={16}>
            <Text size="xs" color="dimmed">
              Status{" "}
            </Text>
            <Text size="xs" fw={600} color="#212529">
              {" "}
              {idea?.status || "-"}
            </Text>
          </Box>
          <Box sx={{ borderRight: "1px solid #D8D8DB" }} pr={16}>
            <Text size="xs" color="dimmed">
              Product Area{" "}
            </Text>
            <Text size="xs" fw={600} color="#212529">
              {" "}
              {idea?.componentObj?.name || idea?.component ||"-"}
            </Text>
          </Box>
          <Box sx={{ borderRight: "1px solid #D8D8DB" }} pr={16}>
            <Text size="xs" color="dimmed">
            Companies{" "}
            </Text>
            <Text size="xs" fw={600} color="#212529">
              {" "}
              {idea?.aggregations?.accountsCount?.total || "-"}
            </Text>
          </Box>
          <Box sx={{ borderRight: "1px solid #D8D8DB" }} pr={16}>
            <Text size="xs" color="dimmed">
              Priority{" "}
            </Text>
            <Text size="xs" fw={600} color="#212529">
              {" "}
              {idea?.priority || "-"}
            </Text>
          </Box>
        </Flex>
        
      </Flex>
    </Card>
  );
};

export default SimilarIdeaCard;
