// src/Api.matches.ts
import axios from "axios";

// Define the API domain from the runtime configuration
const API_DOMAIN = window.__RUNTIME_CONFIG__.REACT_APP_API_DOMAIN;

/**
 * Retrieves authentication headers using Auth0.
 * @param auth0 - The Auth0 instance.
 * @returns An object containing the Authorization header.
 */
const getAuthHeaders = async (auth0: any) => {
  try {
    const token = await auth0.getAccessTokenSilently();
    return { headers: { Authorization: `Bearer ${token}` } };
  } catch (e) {
    console.error("Error fetching auth token:", e);
    throw e;
  }
};

/**
 * Updates a match with the provided match data.
 * @param match - The match data to update.
 * @param auth0 - The Auth0 instance for authentication.
 * @returns The updated match data.
 */
export const updateMatch = async (
  match: any,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.post(`${API_DOMAIN}/matches/v1/update`, { match }, config);
    return response.data;
  } catch (e) {
    console.error("Error updating match:", e);
    throw e;
  }
};

/**
 * Labels a match as 'sameRequest' or 'sameEpic'.
 * @param matchId - The ID of the match to label.
 * @param ideaId - The associated idea ID.
 * @param textId - The associated text ID.
 * @param sameRequest - Boolean indicating the type of label.
 * @param auth0 - The Auth0 instance for authentication.
 * @returns The result of the labeling operation.
 */
export const labelMatch = async (
  matchId: string,
  ideaId: string,
  textId: string,
  sameRequest: boolean,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const payload = { matchId, ideaId, textId, sameRequest };
    const response = await axios.post(`${API_DOMAIN}/matches/v1/label`, payload, config);
    return response.data;
  } catch (e) {
    console.error("Error labeling match:", e);
    throw e;
  }
};


export type GetMatchesParams = 
  | { ideaId: string; evidenceId?: never }
  | { evidenceId: string; ideaId?: never };
/**
 * Retrieves matches based on provided query parameters.
 * @param params - An object containing either ideaId or evidenceId.
 * @param auth0 - The Auth0 instance for authentication.
 * @returns An array of matches.
 */
export const getMatches = async (
  params: GetMatchesParams,
  auth0: any
): Promise<any> => {
  try {
    const config = await getAuthHeaders(auth0);
    const response = await axios.get(`${API_DOMAIN}/matches/v1`, {
      ...config,
      params,
    });
    return response.data;
  } catch (e) {
    console.error("Error fetching matches:", e);
    throw e;
  }
};