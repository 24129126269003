import React, { useContext, useMemo, useState } from "react";
import { Flex, Button, Tooltip, Loader, Group } from "@mantine/core";
import {
  IconAlertOctagon,
  IconCategory,
  IconCoin,
  IconUser,
  IconUsers,
} from "@tabler/icons";
import { openModal } from "@mantine/modals";
import BagelAutocomplete from "../../general/BagelAutocomplete";
import { showNotification } from "@mantine/notifications";
import { formatMonetaryValue } from "../../ideaNiv/Funcs";
import SyncContext from "../../../context/SyncContext";
import { UseListStateHandlers } from "@mantine/hooks";
import { DeclineModalContent } from "../../idea/RightBar/DeclineModalContent";
import { useAuth0 } from "@auth0/auth0-react";
import { updateEvidence } from "../../../api/evidences";
import { OverflownText } from "../../OverflownText";
import { SourceIcon } from "../../../utils/SourceIcon";
import { InfoTooltip } from "../EvidenceCard";

export function Actions({
  evidence,
  setEvidence,
  evidencesHandlers,
}: {
  evidence: any;
  setEvidence: React.Dispatch<any>;
  evidencesHandlers?: UseListStateHandlers<any[]> | undefined;
}) {
  const auth0 = useAuth0();

  const [loading, setLoading] = useState<string | null>(null);
  const { components, domains, owners, requestPriorities } =
    useContext(SyncContext);
  console.log({ requestPriorities });
  const [domain, setDomain] = useState(
    evidence?.componentObj?.domain || evidence?.domain || "Unassigned"
  );
  // const [filteredComponents, setFilteredComponents] = useState(components);
  const [productArea, setProductArea] = useState(
    evidence?.componentObj?.name || "Unassigned"
  );

  const currentPriority = requestPriorities.find(
    (p: any) => p?.key?.toLowerCase() == evidence?.priority?.toLowerCase()
  );

  const origin =
    evidence?.cxItem?.trueOrigin ||
    evidence?.cxItem?.origin ||
    evidence?.origin;

  const openDeclineModal = () => {
    openModal({
      modalId: "decline",
      title: "Decline Evidence",
      centered: true,
      children: (
        <DeclineModalContent
          evidence={evidence}
          evidencesHandlers={evidencesHandlers}
        />
      ),
    });
  };

  const updateEvidenceField = (field: string, value: any) => {
    const updatedData = { [field]: value }; // Prepare the updated field data
    setLoading(field);
    updateEvidence(evidence._id, updatedData, auth0) // Call the API function
      .then(({ evidence: updatedEvidence }) => {
        const _field = field == "componentId" ? "Product area" : field;
        showNotification({
          title: `${_field} Updated`,
          message: `${_field} successfully updated to "${
            field == "componentId" ? updatedEvidence?.componentObj?.name : value
          }".`,
          color: "teal",
        });

        setEvidence(updatedEvidence);
        setProductArea(updatedEvidence?.componentObj?.name || "Unassigned");
        // Update evidence locally in the handlers
        evidencesHandlers?.applyWhere(
          (i: any) => i._id === evidence._id,
          () => ({ ...updatedEvidence })
        );

        if (field === "domain") setDomain(value);
      })
      .catch(() =>
        showNotification({
          title: `Error Updating ${field}`,
          message: `Failed to update ${field}.`,
          color: "red",
        })
      )
      .finally(() => setLoading(null));
  };

  const _domain = (evidence?.componentObj?.domain || evidence?.domain)
    ?.toLowerCase()
    ?.trim();

  // Memoize the filtered components
  const filteredComponents = useMemo(() => {
    if (!domain) {
      return components;
    }
    return components.filter(
      (comp: any) => comp.domain?.toLowerCase()?.trim() === _domain
    );
  }, [components, domain]);

  // Memoize the items for BagelAutocomplete
  const componentsItems = useMemo(
    () =>
      filteredComponents.map((comp: any) => ({
        id: comp._id,
        name: comp.name,
      })),
    [filteredComponents]
  );

  console.log({ owners });
  return (
    <Flex
      align="center"
      justify="space-between"
      mih={56}
      p="sm"
      sx={{ borderBottom: "1px solid #E5E7EB" }}
    >
      <Group spacing={5}>
        {/* Priority */}
        <BagelAutocomplete
          items={requestPriorities.map((p: any) => ({
            name: p.label,
            value: p.key,
          }))}
          selected={evidence?.priority}
          onSelect={(item) => updateEvidenceField("priority", item.name)}
        >
          <Tooltip label="Select Priority">
            <Flex
              h="32px"
              // mx={5}
              px="sm"
              align="center"
              sx={{
                background: currentPriority?.color
                  ? `${currentPriority?.color}10`
                  : "#F8F9FB",
                color: currentPriority?.color,
                border: `1px solid ${currentPriority?.color}35`,
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              {loading == "priority" ? (
                <Loader size={18} color={currentPriority?.color} />
              ) : (
                <IconAlertOctagon
                  size={18}
                  color={currentPriority?.color || "#000"}
                />
              )}
              <OverflownText
                lineClamp={1}
                ml={8}
                color={currentPriority?.color || "#000"}
              >
                {evidence?.priority || "Unassigned"}
              </OverflownText>
            </Flex>
          </Tooltip>
        </BagelAutocomplete>

        {/* Domain */}
        <BagelAutocomplete
          items={domains}
          selected={domain}
          onSelect={(item) => {
            updateEvidenceField("domain", item.name);
            setProductArea("Unassigned");
          }}
        >
          <Tooltip label="Select Domain">
            <Flex
              h="32px"
              // mx={5}
              px="sm"
              bg="#F8F9FB"
              align="center"
              sx={{ cursor: "pointer", borderRadius: "5px" }}
            >
              <OverflownText lineClamp={1}>{domain}</OverflownText>
            </Flex>
          </Tooltip>
        </BagelAutocomplete>

        {/* Product Area */}
        <BagelAutocomplete
          items={componentsItems}
          selected={productArea}
          onSelect={(item) => updateEvidenceField("componentId", item.id)}
          disabled={domain === "Unassigned"}
        >
          <Tooltip label="Select Product Area">
            <Flex
              h="32px"
              // mx={5}
              px="sm"
              bg="#F8F9FB"
              align="center"
              sx={{
                cursor: domain !== "Unassigned" ? "pointer" : "not-allowed",
                borderRadius: "5px",
              }}
            >
              <OverflownText lineClamp={1}>{productArea}</OverflownText>
            </Flex>
          </Tooltip>
        </BagelAutocomplete>

        {/* Owner */}
        <BagelAutocomplete
          items={owners}
          selected={evidence?.ownerId}
          onSelect={(item) => updateEvidenceField("ownerId", item._id)}
        >
          <Tooltip label="Select Owner">
            <Flex
              h="32px"
              // mx={5}
              px="sm"
              bg="#F8F9FB"
              align="center"
              sx={{ cursor: "pointer", borderRadius: "5px" }}
            >
              <IconUser stroke={1.5} color="black" size={16} />
              <OverflownText lineClamp={1} ml={8}>
                {evidence?.owner?.name || "Unassigned"}
              </OverflownText>
            </Flex>
          </Tooltip>
        </BagelAutocomplete>

        {evidence.cxItem?.opportunityType && (
          <InfoTooltip
            label="Opportunity Type"
            icon={<IconCategory stroke={1.5} color="black" size={14} />}
          >
            {evidence.cxItem.opportunityType}
          </InfoTooltip>
        )}

        {/* Amount */}
        {evidence?.cxItem?.amount && (
          <InfoTooltip
            label="Amount"
            icon={<IconCoin stroke={1.5} color="black" size={16} />}
          >
            {formatMonetaryValue(evidence?.cxItem?.amount)}
          </InfoTooltip>
        )}

        {/* origin */}
        {origin && (
          <InfoTooltip
            label="Origin"
            icon={<SourceIcon sourceName={origin} width={18} height={18} />}
          >
            <OverflownText tt="capitalize" lineClamp={1} ml={8} color="black">
              {origin}
            </OverflownText>
          </InfoTooltip>
        )}

        {/* company */}
        {evidence?.company?.name && (
          <InfoTooltip
            label="Account"
            icon={<IconUsers color="black" size={16} />}
          >
            {evidence.company?.name}
          </InfoTooltip>
        )}
      </Group>

      <Tooltip label="You can provide a reason in the next step">
        <Button
          compact
          color="red"
          variant="light"
          px="md"
          onClick={openDeclineModal}
          styles={() => ({
            root: {
              height: 35,
              borderRadius: "5px",
              border: "0.7px solid rgba(249, 88, 88, 0.4)",
            },
          })}
        >
          Decline
        </Button>
      </Tooltip>
    </Flex>
  );
}
