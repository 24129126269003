import { UseListStateHandlers } from "@mantine/hooks";
import { ModalContent } from "./Content/ModalContent";
import { ModalHeader } from "./ModalHeader";
import { Box } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { closeAllModals } from "@mantine/modals";
import { useState } from "react";

interface ModalContentType {
  evidence: any;
  evidencesHandlers?: UseListStateHandlers<any[]> | undefined;
}

export interface EvidenceModalType extends ModalContentType{
  setEvidence: React.Dispatch<any>
}
export function EvidenceModal({
  evidence:_evidence,
  evidencesHandlers,
}: ModalContentType) {
  if (_evidence.state !== "inReview") {
    const navigate = useNavigate();
    navigate(`/evidence/${_evidence._id}`);
    closeAllModals();
  }
  const [evidence, setEvidence] = useState(_evidence);

  return (
    <Box>
      <ModalHeader evidence={evidence} />
      <ModalContent evidence={evidence} setEvidence={setEvidence} evidencesHandlers={evidencesHandlers} />
    </Box>
  );
}
