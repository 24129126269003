import { useContext, useEffect, useState } from "react";
import { Box, Text } from "@mantine/core";
import IdeaContext from "../IdeaContext";
import DiscoveryContext from "../../Discovery/DiscoveryContext";
import SyncContext from "../../../context/SyncContext";
import { calculateImpactResults, getNestedFieldValue } from "../helpers/calculateImpactResults";
import ImpactList from "./ImpactList";

interface Company {
  _id: string;
  arr?: number;
  name: string;
}

interface Chat {
  _id: string;
  itemType?: string;
  amount?: number;
}

interface Request {
  company?: Company | null;
  chat?: Chat | null;
}

const Impact = ({ ideaPage }: { ideaPage: boolean }) => {
  
  const { impactAggregationProperties } = useContext(SyncContext);

  const ContextToUse: React.Context<any> = ideaPage ? IdeaContext : DiscoveryContext;
  const {
    displayedRequests,
    displayedMatches,
    displayedRequestsMatches, 
    ideaId,
    idea,
    showmatches,
    showValidated,
    activeInboxTab,
  } = useContext(ContextToUse);

  const [impactData, setImpactData] = useState<Record<string, any>>({});
  const [dynamicCount, setDynamicCount] = useState<number>(0);
  const [dynamicOrgIds, setDynamicOrgIds] = useState<string[]>([]);
 

  useEffect(() => {

    const _pendingRequests = ideaPage ? displayedRequests?.filter(
      (r: any) => r.state === "inReview" 
    ) : []
    const _validatedRequests = ideaPage ? displayedRequests?.filter(
      (r: any) => (r.type === 'gap' && r.ideaId)  || (r.state === 'validated')
    ) : [];

    const matchesCopy = !ideaPage ? [...displayedMatches , ...displayedRequestsMatches ] : activeInboxTab
      ? [   ..._pendingRequests, ...displayedRequestsMatches]
      : (showmatches && !showValidated)
        ? [...displayedMatches]
        : (showmatches && showValidated)
          ? [
            ..._pendingRequests,
            ...displayedMatches,
            ...displayedRequestsMatches
          ]
          : [];


    const validatedCopy = showValidated ? [..._validatedRequests] : [];

    const allCompanyIdsSet = new Set<string>();

    const collectCompanyIds = (requests: Request[]) => {
      requests.forEach((request) => {
        const companyId = getNestedFieldValue(request, "company._id");
        if (companyId && companyId !== 'N/A') {
          allCompanyIdsSet.add(companyId);
        }
      });
    };

    collectCompanyIds(matchesCopy);
    collectCompanyIds(validatedCopy);

    setDynamicCount(allCompanyIdsSet.size);
    setDynamicOrgIds(Array.from(allCompanyIdsSet));

    const validatedDeepCopy = structuredClone(validatedCopy);
    const matchesDeepCopy = structuredClone(matchesCopy);

    const companiesIds = new Set<string>();

    validatedDeepCopy.forEach(item => {
      const companyId = item.company?._id;

      if (companyId) {
        if (companyId === 'N/A') {
          item.company = null;
        }
        if (companiesIds.has(companyId)) {
          item.company = null;
        } else {
          companiesIds.add(companyId);
        }
      }
    });

    matchesDeepCopy.forEach(item => {
      const companyId = item.company?._id;

      if (companyId) {
        if (companyId === 'N/A') {
          item.company = null;
        }
        if (companiesIds.has(companyId)) {
          item.company = null;
        } else {
          companiesIds.add(companyId);
        }
      }
    });

   
    const impactResults = calculateImpactResults(validatedDeepCopy, matchesDeepCopy, impactAggregationProperties, idea);

    setImpactData(impactResults);
  }, [
    displayedRequests,
    displayedMatches,
    displayedRequestsMatches, 
    ideaId,
    showmatches,
    showValidated,
    activeInboxTab,
  ]);

  return (
    <Box px={6} py={24}>
      <ImpactList
        impactAggregationProperties={impactAggregationProperties}
        impactData={impactData}
        dynamicCount={dynamicCount}
        dynamicOrgIds={dynamicOrgIds}
        idea={idea}
        ideaPage={ideaPage}
      />
    </Box>
  );
};

export default Impact;