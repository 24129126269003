import { Box, Modal, ScrollArea, Text } from "@mantine/core";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SyncContext from "../../../../../../context/SyncContext";
import { getRelatedCxItem } from "../../../../../../Api";
import TicketCommentItem from "./TicketCommentItem";
import SupportTicketTextArea from "./SupportTicketTextArea";
import TicketTitle from "./TicketTitle";
import JumpToExcerpt from "./JumpToExcerpt";

const SupportTicket = ({ item }: { item: any }) => {

  const { members } = useContext(SyncContext);
  const auth0 = useAuth0();
  const [opened, setOpened] = useState(false);
  const [sortedTextItems, setSortedTextItems] = useState<any[]>([]);

  const title = item?.title;
  const text = item?.text;
  
  const cxItem = item?.cxItem;
  const origin = cxItem?.origin || item?.origin;
  const originContactId = cxItem?.originContactId;
  const originOwnerId = cxItem?.originOwnerId;
  const originSubmitterId = cxItem?.originSubmitterId;
  const contactEmail = cxItem?.contactEmail;
  const ownerEmail = cxItem?.ownerEmail;
  const submitterEmail = cxItem?.submitterEmail;
  const contactName = cxItem?.contactName;
  const ownerName = cxItem?.ownerName;
  const submitterName = cxItem?.submitterName;
  const excerptsArr = item?.excerpts || [];

  const [refsIndexes, setRefsIndexes] = useState<any[]>([]);

  const refsArr = useRef<(HTMLDivElement | null)[]>([]);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const scrollToElement = (index: number) => {
    if (refsArr.current[index]) {
      refsArr.current[index]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const scrollToTop = () => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const loadRelatedTextItems = (id: string) => {
    if (!id) {
      return;
    }
    getRelatedCxItem(id, auth0)
      .then((res) => {
        const items = res?.comments || {};

        excerptsArr.forEach((excerptItem: any) => {
          const { excerptCommentId } = excerptItem;

          if (items.hasOwnProperty(excerptCommentId)) {
            items[excerptCommentId].isExcerpt = true;
            items[excerptCommentId].commentId = excerptCommentId;
          }
        });
        

        const itemsArray: any[] = Object.values(items);
        if (itemsArray?.length > 0) {
          const sortedItems: any[] =
            itemsArray?.sort(
              (a: any, b: any) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            ) || [];

          sortedItems.forEach((commentItem: any, index: number) => {
            if (commentItem?.isExcerpt) {

              setRefsIndexes((prevIndexes) => [...prevIndexes, index]);
            }

            if (commentItem?.originSubmitterId && !commentItem?.fromEmail) {
              const orginSub = commentItem?.originSubmitterId;

              if (orginSub === originContactId) {
                commentItem.fromEmail = contactEmail;
              } else if (orginSub === originOwnerId) {
                commentItem.fromEmail = ownerEmail;
              } else if (orginSub === originSubmitterId) {
                commentItem.fromEmail = submitterEmail;
              }
            }

            if (commentItem?.originSubmitterId && !commentItem?.fromName) {
              const orginSub = commentItem?.originSubmitterId;

              if (orginSub === originContactId) {
                commentItem.fromName = contactName;
              } else if (orginSub === originOwnerId) {
                commentItem.fromName = ownerName;
              } else if (orginSub === originSubmitterId) {
                commentItem.fromName = submitterName;
              }
            }
          });

          sortedItems.forEach((commentItem: any) => {
            if (commentItem?.fromEmail) {
              const memberFromEmail = members.find(
                (m: any) => m.email === commentItem?.fromEmail
              );
              if (memberFromEmail) {
                commentItem.fromUser = memberFromEmail;
              }
            }
          });

          const firstExcerptIndex = sortedItems.findIndex(
            (item) => item.isExcerpt
          );
          const lastExcerptIndex = sortedItems
          
            .slice()
            .reverse()
            .findIndex((item) => item.isExcerpt);

          if (firstExcerptIndex !== -1) {
            sortedItems[firstExcerptIndex].isFirstExcerpt = true;
          }

          if (lastExcerptIndex !== -1) {
            sortedItems[
              sortedItems.length - 1 - lastExcerptIndex
            ].isLastExcerpt = true;
          }

          setSortedTextItems(sortedItems);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {

    setRefsIndexes([]);

    if (item?.cxItemId) {
      loadRelatedTextItems(item?.cxItemId);
    }
  }, [item]);

  return (
    <Box mt={15}>
      {opened && <Modal
        opened={opened}
        centered
        onClose={() => setOpened(false)}
        size="800px"
        zIndex={1100}
        title={<TicketTitle item={item} cxItem={cxItem} origin={origin}  />}
      >
      
        <Box w={578}>
          <ScrollArea
            h={500}
            mah={500}
            w={750}
            maw={750}
            offsetScrollbars
            type="always"
            viewportRef={scrollAreaRef}
            pl={20}
          >
            { excerptsArr?.length > 0 &&
              <Text
                color="#5C5CEB"
                px="sm"
                my="md"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  const firstExcerptIndex = refsIndexes[0];
                  scrollToElement(firstExcerptIndex);
                }}
                underline
              >
                  Jump to first excerpt
              </Text>
            }
            {sortedTextItems && sortedTextItems.length > 0 ? (
              sortedTextItems.map((sortedItem: any, index: number) => (
                <Box
                  key={index}
                  ref={
                    sortedItem?.isExcerpt
                      ? (el) => {
                        refsArr.current[index] = el;
                      }
                      : null
                  }
                  bg={sortedItem.isExcerpt ? "#FFFDD4" : ""}
                >
                  <TicketCommentItem
                    sortedItem={sortedItem}
                    excerpts={excerptsArr}
                  />

                  {!sortedItem.isLastExcerpt && sortedItem.isExcerpt && (
                    <JumpToExcerpt onClick={() => {
                      const nextExcerptIndex = Array.from(refsIndexes).find(
                        (refIndex) => refIndex > index
                      );
                      scrollToElement(nextExcerptIndex);
                    }} isLast={false}/>
                  )}
                  {sortedItem.isLastExcerpt && (
                    <JumpToExcerpt onClick={scrollToTop} isLast/>
                  )}
                </Box>
              ))
            ) : (
              <Text ml={80}>Support ticket not available</Text>
            )}
          </ScrollArea>
        </Box>
    
      </Modal>}

      <SupportTicketTextArea
        title={title}
        text={text}
        excerptsArr={excerptsArr}
        setOpened={setOpened}
      />
    </Box>
  );
};

export default SupportTicket;
