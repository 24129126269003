import { SimpleGrid } from "@mantine/core";
import ImpactItem from "./ImpactItem";
import { useContext, useEffect, useMemo, useState } from "react";
import IdeaContext from "../IdeaContext";
import DiscoveryContext from "../../Discovery/DiscoveryContext";

interface ImpactListProps {
  impactAggregationProperties: any[];
  impactData: Record<string, any>;
  dynamicCount: number;
  dynamicOrgIds: string[];
  idea: any;
  ideaPage: boolean;
}

const ImpactList = ({
  impactAggregationProperties,
  impactData,
  dynamicCount,
  dynamicOrgIds,
  idea,
  ideaPage,
}: ImpactListProps) => {

  const ContextToUse: React.Context<any> = ideaPage ? IdeaContext : DiscoveryContext;
  const { requestsCompanies, matchesCompanies, requestsMatchesCompanies } =
    useContext(ContextToUse);
    
  const sortedImpactAggregationProperties = useMemo(() => {
    return [...impactAggregationProperties].sort(
      (a: any, b: any) => (a.impact?.index || 0) - (b.impact?.index || 0)
    );
  }, [impactAggregationProperties]);

  const [companies, setCompanies] = useState<any[]>([]);

  const deduplicateOrgs = (requestsOrgs: any[], matchesOrgs: any[]) => {
    const orgIds: string[] = [];
    const orgs: any[] = [];

    requestsOrgs?.forEach((company) => {
      if (company && !orgIds.includes(company?._id)) {
        orgIds.push(company?._id);
        orgs.push(company);
      }
    });

    matchesOrgs?.forEach((company) => {
      if (company && !orgIds.includes(company?._id)) {
        orgIds.push(company?._id);
        orgs.push(company);
      }
    });

    orgs.sort((a, b) => a.name?.localeCompare(b.name));
    setCompanies(orgs);
  };

  useEffect(() => {
    const allMatchesCompanies = [...matchesCompanies, ...requestsMatchesCompanies]
    deduplicateOrgs(ideaPage? requestsCompanies : [] , allMatchesCompanies)
  }, [requestsCompanies, matchesCompanies, requestsMatchesCompanies]);

  
  return (
    <SimpleGrid
      cols={3} // Up to 3 Guage
      spacing="lg"
      style={{
        width: "100%",
        padding: "16px",
        alignItems: "stretch",
      }}
    >
      {sortedImpactAggregationProperties.map((aggregationProperty) => (
        <ImpactItem
          key={aggregationProperty.propertyName}
          aggregationProperty={aggregationProperty}
          impactData={impactData}
          dynamicCount={dynamicCount}
          dynamicOrgIds={dynamicOrgIds}
          ideaPage={ideaPage}
          ideaDomain={idea?.domain}
          ideaRanks={idea?.ranks}
          companies={companies}
        />
      ))}
    </SimpleGrid>
  );
};

export default ImpactList;
