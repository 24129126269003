import { useContext, useEffect, useState } from "react";
import IdeaContext from "../IdeaContext";
import { Box, Button, Flex, Skeleton, Stack, Text, Tooltip } from "@mantine/core";
import EvidenceCardNiv from "./EvidenceCardNiv";
import { IconCaretDown, IconCaretUp } from "@tabler/icons";
import { Loader, ZeroState } from "../../../icons/x-symbol-svgrepo-com";

const InitialEvidenceScreen = ({
  chooseMatch,
  chosenEvidence,
}: {
  chooseMatch: any;
  chosenEvidence: any;
}) => {
  const {
    displayedMatches,
    loadingEvidence,
    displayedRequests,
    matchesLoading,
    showmatches,
    showValidated,
    computed,
    ideaId,
    matchesTimer,
    getMatchesUpdate,
    matchesTrigger,
    displayedRequestsMatches,
    showPending
  } = useContext(IdeaContext);

  /* console.log('displayedMatches')

  console.log(displayedMatches) 

  console.log('displayedRequests')

  console.log(displayedRequests) 

  console.log('displayedRequestMatches')

  console.log(displayedRequestsMatches)  */

  const [inboxItems, setInboxItems] = useState<any[]>([])

  const [matchesLengthToDisplay, setMatchesLengthToDisplay] = useState(4);
  const computedValue = computed || "done";

  const requests = displayedRequests.filter(
    (r: any) => (r.type === 'gap' && r.ideaId)  || (r.state === 'validated')
  );

  useEffect(() => {
    matchesTimer();
    getMatchesUpdate();
  }, [matchesTrigger, ideaId]);

  useEffect(() => {
    if (!showValidated) {
      setMatchesLengthToDisplay(100);
    } else {
      setMatchesLengthToDisplay(4);
    }
  }, [showValidated]);

  useEffect(() => {

    const _pendingRequests = displayedRequests.filter(
      (r: any) => r.state === "inReview"
    );
    /* console.log('_pendingRequests')
    console.log(_pendingRequests) */
    const inbox = [..._pendingRequests, ...displayedRequestsMatches]
    setInboxItems(inbox)

  }, [displayedRequests, displayedRequestsMatches]);

  return (
    <Box>
      {!loadingEvidence &&
      !matchesLoading &&
      requests?.length === 0 &&
      displayedMatches?.length === 0 &&
      computed === "done" 
      && inboxItems?.length === 0
        ? 
        (
          <Flex justify="center" pt="16px" px="20px" w="100%" mt="46px">
            <ZeroState />
          </Flex>
        ) : !loadingEvidence &&
        !matchesLoading &&
        requests?.length === 0 &&
        displayedMatches?.length === 0 &&
        computed !== "done" ? (
            <Stack /* justify="center" */ pt="16px" align="center" mt="46px">
              {matchesTrigger === 0 ? (
                <Loader height={74} width={74} />
              ) : (
                <ZeroState />
              )}
              <Box
                bg="rgba(92, 92, 235, 0.1)"
                p="md"
                /* align="center" */ w={500}
                mt="38px"
                sx={{ borderRadius: "8px" }}
              >
                <span style={{ fontWeight: 600, marginRight: "5px" }}>
              There are currently no matching evidence,
                </span>
            Bagel will keep scanning all sources and will update as soon as a
            new match is found
              </Box>
            </Stack>
          ) : (
            <>
              {showmatches && (
                <>
                  {(matchesLoading || displayedMatches?.length > 0) && (
                    <Box px="20px" w="100%" mb="lg" mt="6px">
                      <Text color="dimmed" mb="sm">
                    Suggested Evidence
                      </Text>
                      {displayedMatches?.length > 0 &&
                    displayedMatches
                      ?.slice(0, matchesLengthToDisplay)
                      ?.map((evidence: any, index: number) => (
                        <EvidenceCardNiv
                          key={evidence._id}
                          item={evidence}
                          chooseMatch={chooseMatch}
                          index={index}
                          fromSearch={false}
                          chosenEvidence={chosenEvidence}
                          ideaPage
                        />
                      ))}
                    </Box>
                  )}
                  {matchesLoading &&
                displayedMatches.length == 0 &&
                computedValue === "done" && (
                    <Box w="99%" pr="20px" pl="8px" mt={8} mb="md">
                      {[1, 2].map((i) => (
                        <Skeleton
                          key={i}
                          m="sm"
                          height={66}
                          mb={0}
                          sx={{ borderRadius: "8px" }}
                        />
                      ))}
                    </Box>
                  )}
                </>
              )}
              {showmatches && displayedMatches?.length > 4 && showValidated && (
                <Flex justify="center">
                  <Button
                    variant="default"
                    onClick={() => {
                      if (matchesLengthToDisplay === 4) {
                        setMatchesLengthToDisplay(displayedMatches?.length);
                      } else {
                        setMatchesLengthToDisplay(4);
                      }
                    }}
                  >
                    <Text color="#5C5CEB" fz={13} fw={400}>
                      {matchesLengthToDisplay === 4 ? "Show all" : "Show less"}
                    </Text>

                    {matchesLengthToDisplay === 4 ? (
                      <IconCaretDown color="#5C5CEB" />
                    ) : (
                      <IconCaretUp color="#5C5CEB" />
                    )}
                  </Button>
                </Flex>
              )}
              {showValidated && (
                <Box w="100%" px="20px" mt={ 6}>
                  {(loadingEvidence || (requests && requests?.length > 0)) && (
                    <Text color="dimmed" mb="13px">
                  Assigned Evidence
                    </Text>
                  )}
                  {requests &&
                requests?.length > 0 &&
                requests?.map((request: any, index: number) => (
                  <EvidenceCardNiv
                    key={index}
                    item={request}
                    chooseMatch={chooseMatch}
                    index={index}
                    fromSearch={false}
                    chosenEvidence={chosenEvidence}
                    ideaPage
                  />
                ))}
                </Box>
              )}
              {loadingEvidence &&
            requests?.length === 0 &&
            displayedMatches?.length === 0 && (
                <Box w="99%" pr="20px" pl="8px" mt={8}>
                  {[1, 2, 3, 4].map((i) => (
                    <Skeleton
                      key={i}
                      m="sm"
                      height={66}
                      mb={0}
                      sx={{ borderRadius: "8px" }}
                    />
                  ))}
                </Box>
              )}
            </>
          )}
      <Box>
        {showValidated && showmatches && inboxItems && inboxItems?.length > 0 &&
          <Box px="20px" w="100%" mb="lg" mt={(requests?.length + displayedMatches?.length) > 0 ? 16: 6}>
            <Box w={100}>
              <Tooltip label='Formal requests'>
                <Text color="dimmed" mb="sm">
              Inbox
                </Text>
              </Tooltip>
            </Box>
            {inboxItems?.length > 0 &&
              inboxItems?.map((evidence: any, index: number) => (
                <EvidenceCardNiv
                  key={evidence._id}
                  item={evidence}
                  chooseMatch={chooseMatch}
                  index={index}
                  fromSearch={false}
                  chosenEvidence={chosenEvidence}
                  ideaPage
                />
              ))}
          </Box>
        }
      </Box>
    </Box>
  );
};

export default InitialEvidenceScreen;
