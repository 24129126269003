import { Box, Center, Text } from "@mantine/core";
import Comment from "../../../Comment";

export function Comments({
  comments,
  deleteComment,
  editComment,
  loading,
}: {
  request: any;
  origin: string;
  comments: any;
  deleteComment: (commentId: string) => void;
  editComment: (comment: any, text: string) => void;
  loading: any;
}) {
  return (
    <Box >
      {comments.map((comment: any, index: number) => (
        <Comment
          comment={comment}
          index={index}
          key={comment._id}
          editComment={editComment}
          deleteComment={deleteComment}
          loading={loading}
        />
      ))}
      {comments.length == 0 && <Center mx="md" mih={150}>
        <Text color="dimmed" align="center">Ther are no comments on this evidence ..</Text>
      </Center>}
    </Box>
  );
}
