import { Box, Button, Flex, Modal, Stack, Text, Tooltip } from "@mantine/core";
import {  IconCheck, IconPlus, IconX } from "@tabler/icons";
import  { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { openModal } from "@mantine/modals";


import { showNotification } from "@mantine/notifications";
import IdeaContext from "../../../IdeaContext";
import LogoIcon from "../../../../layouts/LogoIcon";
import { DeclineModal } from "./DeclineModal";
import { validateRequest } from "../../../../../Api";

import CommentsMenu from "../Comment/CommentsMenu";
import { RequestAssignment } from "./RequestAssignment";
import useEvidenceActions from "../../../../../hooks/useEvidenceActions";

const NewRequestActions = ({ navToPerspective }: { navToPerspective: any }) => {
  // FIX REASSIGN
  
  const {
    loadEvidence,
    chosenEvidence,
    setChosenEvidence,
    ideaId,
    idea,
    reCalculatPerspectiveForRequest,
  } = useContext(IdeaContext);

  const { validate } = useEvidenceActions({ evidenceId: chosenEvidence._id });

  const [opened, setOpened] = useState(false);

  const openDeclineModal = () => {
    setOpened && setOpened(false);
    openModal({
      modalId: "decline",
      title: <LogoIcon />,
      centered: true,
      size: 600,
      children: (
        <DeclineModal
          request={chosenEvidence}
          idea={idea}
          navToPerspective={navToPerspective}
          loadEvidence={loadEvidence}
        />
      ),
    });
  };

  /* const handleDecline = () => {

    const rejectInfo = {reason: 'not relevant', comment:'not relevant'}

    dismissFeedback(item?._id,rejectInfo, auth0)
      .then((res) => {
        console.log(res)
      })
      .catch((e) => console.log(e))
  
  }; */

  const onValidate = () => {
    /* const requestObj = {...} */
    ideaId &&
    validate({ ideaId, sourceOfAssignment:"request" })
      .then((res) => {
        console.log({ res });
        if (res) {
          reCalculatPerspectiveForRequest(
            { ...res.evidence, state: "validated" },
            "add"
          );
        }
        loadEvidence();
        showNotification({
          title: "Evidence added successfully",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
        });
      })
      .then(() => setChosenEvidence(null))
      .catch((e) => console.log(e));
  };

  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      h="64px"
      sx={{ borderBottom: "1px solid #D8D8DB" }}
      /* align="center" */
    >
      {opened && (
        <Modal
          mt={100}
          opened={opened}
          onClose={() => setOpened(false)}
          size="32vw"
          title={
            <Text fz={20} fw={600}>
              Reassign to another idea
            </Text>
          }
          styles={{
            content: {
              marginLeft: "calc((100vw - 32vw) / 2) !important",
            },
          }}
        >
          <Stack h={445} pt={5} /* align="center" */ justify="center" w="100%">
            <Stack /* align="center" */ justify="center">
              <RequestAssignment
                idea={idea}
                item={chosenEvidence}
                setOpened={setOpened}
                navToPerspective={navToPerspective}
                TextInputW="100%"
              />
            </Stack>
          </Stack>
        </Modal>
      )}
      <CommentsMenu />
      <Flex
        align="center"
        w="184px"
        mr="12px"
        sx={{ cursor: " pointer" }}
        onClick={() => setOpened(true)}
      >
        <Box>
          <IconPlus size={15} color="#5C5CEB" />
        </Box>
        <Text ml="4px" color="#5C5CEB" fz={12} lineClamp={1}>
          assign to another idea
        </Text>
      </Flex>
      <Flex align="center">
        <Box>
          <Tooltip
            label="Decline this evivdence (select a reason in the next step)"
            multiline
            maw={200}
            offset={12}
          >
            <Button
              fz="12px"
              color="indigo"
              w="75px"
              mr="12px"
              /* h='32px' */ styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#DB2525",
                  border: 0,
                  fontWeight: 400,
                  /* height: 35, */
                  padding: 0,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#DB2525", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              onClick={openDeclineModal}
            >
              <IconX size={14} />
              <Text>Decline</Text>
            </Button>
          </Tooltip>
        </Box>

        <Box>
          <Tooltip
            label="Assign to this Idea"
            multiline
            maw={200}
            offset={12}
            position="left"
          >
            <Button
              fz="12px"
              color="indigo"
              w="75px"
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#5C5CEB",
                  border: 0,
                  fontWeight: 400,
                  padding: 0,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              onClick={onValidate}
            >
              <IconPlus size={14} />
              <Text>assign</Text>
            </Button>
          </Tooltip>
        </Box>
      </Flex>
    </Flex>
  );
};

export default NewRequestActions;
