import { useContext } from 'react';
import { Box } from '@mantine/core';
import DiscoveryContext from '../DiscoveryContext';

import SideBar from '../../ideaNiv/right-bar/evidence-card/SideBar';

function CustomDrawer() {

  const { cardOpened } = useContext(DiscoveryContext);

  return (
 
    <Box
      bg='#fff'
      sx={(theme) => ({
        position: 'fixed',
        top: 63,
        right: cardOpened ? 0 : '-496px',
        width: 496,
        height: '100vh',
        backgroundColor: theme.colors.gray[0],
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        transition: 'right 0.3s ease', 
        zIndex: 1000,
      })}
    >
      <SideBar navToPerspective={null} setActiveSearch={null} reviewMode ideaPage={false}/>
    </Box>
  
  );
}

export default CustomDrawer;