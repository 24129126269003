// src/hooks/useComments.ts

import { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { listComments, createComment, deleteComment as deleteCommentAPI, updateComment } from "../api/comments";
import { getUsersFromTags } from "../components/ideaNiv/helpers/tags";
import { showNotification } from "@mantine/notifications";
// import { showNotification } from "@mantine/notifications";

interface UseCommentsProps {
  evidenceId: string;
}

export interface UseCommentsReturn {
  comments: any[];
  loading: {
    fetch: boolean;
    add: boolean;
    delete: boolean;
    edit: boolean;
  };
  addComment: (text: string) => Promise<void>;
  deleteComment: (commentId: string) => Promise<void>;
  editComment: (commentId: string, newText: string) => Promise<void>;
  fetchComments: () => Promise<void>;
}

const useComments = ({ evidenceId }: UseCommentsProps): UseCommentsReturn => {
  const auth0 = useAuth0();

  const [comments, setComments] = useState<any[]>([]);
  const [loading, setLoading] = useState({
    fetch: false,
    add: false,
    delete: false,
    edit: false,
  });

  // Fetch comments
  const fetchComments = useCallback(async () => {
    setLoading((prev) => ({ ...prev, fetch: true }));
    try {
      const fetchedComments = await listComments(evidenceId, auth0);
      setComments(fetchedComments ?? []);
    } catch (error) {
      console.error("Error fetching comments:", error);
      showNotification({
        title: "Error",
        message: "Failed to load comments. Please try again.",
        color: "red",
        autoClose: 2000,
      });
    } finally {
      setLoading((prev) => ({ ...prev, fetch: false }));
    }
  }, [evidenceId, auth0]);

  // Add a new comment
  const addComment = useCallback(
    async (text: string) => {
      if (!text.trim()) return;
      setLoading((prev) => ({ ...prev, add: true }));
      try {
        console.log({text})
        const usersMentioned = getUsersFromTags(text);
        const {comment: newComment} = await createComment(evidenceId, text.trim(), usersMentioned, auth0);
        console.log({newComment})
        setComments((prev) => [...prev, newComment]);
        showNotification({
          title: "Comment Added",
          message: "Your comment has been successfully added.",
          color: "green",
          autoClose: 2000,
        });
      } catch (error) {
        console.error("Error adding comment:", error);
        showNotification({
          title: "Error",
          message: "Failed to add comment. Please try again.",
          color: "red",
          autoClose: 2000,
        });
      } finally {
        setLoading((prev) => ({ ...prev, add: false }));
      }
    },
    [evidenceId, auth0]
  );

  // Delete a comment
  const deleteComment = useCallback(
    async (commentId: string) => {
      setLoading((prev) => ({ ...prev, delete: true }));
      try {
        await deleteCommentAPI(evidenceId, commentId, auth0);
        setComments((prev) => prev.filter((comment) => comment._id !== commentId));
        showNotification({
          title: "Comment Deleted",
          message: "Your comment has been deleted.",
          color: "green",
          autoClose: 2000,
        });
      } catch (error) {
        console.error("Error deleting comment:", error);
        showNotification({
          title: "Error",
          message: "Failed to delete comment. Please try again.",
          color: "red",
          autoClose: 2000,
        });
      } finally {
        setLoading((prev) => ({ ...prev, delete: false }));
      }
    },
    [evidenceId, auth0]
  );

  // Edit a comment
  const editComment = useCallback(
    async (comment: any, newText: string) => {
      console.log("editComment",{comment})
      if (!newText.trim()) return;
      setLoading((prev) => ({ ...prev, edit: true }));
      try {

        const usersMentioned =  getUsersFromTags(newText);
        const {updatedComment} = await updateComment( comment._id, newText.trim(), usersMentioned, auth0);
        setComments((prev) =>
          prev.map((_comment) => (_comment._id === comment._id ? updatedComment : _comment))
        );
        showNotification({
          title: "Comment Updated",
          message: "Your comment has been updated.",
          color: "green",
          autoClose: 2000,
        });
      } catch (error) {
        console.error("Error updating comment:", error);
        showNotification({
          title: "Error",
          message: "Failed to update comment. Please try again.",
          color: "red",
          autoClose: 2000,
        });
      } finally {
        setLoading((prev) => ({ ...prev, edit: false }));
      }
    },
    [evidenceId, auth0]
  );

  // useEffect(() => {
  //   fetchComments();
  // }, []);

  return {
    comments,
    loading,
    addComment,
    deleteComment,
    editComment,
    fetchComments,
  };
};

export default useComments;