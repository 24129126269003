import {
  Flex,
  Box,
  Text,
  Card,
  Spoiler,
  LoadingOverlay,
  Group,
  Button,
  Tooltip,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconMessage } from "@tabler/icons";
import { getDateString } from "../../utils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


export function IdeaItem({
  idea,
  loading,
  assignFunc,
  fromCreateIdea,
  isCreateNew = false,

  requestsCount,
  reAssign,
  
}: {
  idea: any;
  loading: boolean;
  assignFunc: (e: any) => void;
  fromCreateIdea: boolean;
  isCreateNew?: boolean;
  requestsCount?: number;
  reAssign?: boolean;
}) {

  return (
    <Card
      withBorder
      p="xs"
      my="xs"
      style={{ position: 'relative', minHeight: '120px' }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Group position="apart" mb={10} h={30} noWrap>
        
        <Flex align='flex-start'>
          <IconMessage color="#5B60E3" style={{ marginRight: '8px' }} />
          <Tooltip label={idea?.title || "Unassigned"} multiline maw={500}>
            <Text size="sm" style={{ fontWeight: 500 }} lineClamp={1} maw={350} sx={{cursor: 'pointer'}} onClick={()=> window.open(`/idea/${idea?._id}`, '_blank')}>{idea?.title || "Unassigned"}</Text>
          </Tooltip>
          
        </Flex>
          
        {!fromCreateIdea && <Box>
          <Tooltip label="Assign the Request to this Idea" withArrow>
            <Button
              mx="md" 
              color="indigo"
              compact
              onClick={(e) => {
                e.stopPropagation();
                assignFunc(e);
              }}
            >
              <Text fw={400}>
              Assign
              </Text>
                
            </Button>
          </Tooltip>
        </Box>}
      
      </Group>
  
      <Spoiler
        maxHeight={20} 
        showLabel="see more"
        hideLabel="Hide"
        sx={(theme) => ({
          fontSize: theme.fontSizes.sm,
          button: {
            color: "#5B60E3",
            fontSize: theme.fontSizes.sm,
          },
        })}
        my="xs"
      >
        <Text size="xs" style={{ color: 'black' }} lineClamp={1} w={600}>
          {idea?.description?.replace(/<[^>]*>/g, "") || "No description provided."}
        </Text>
      </Spoiler>
  
      <Flex justify="space-between" style={{ marginTop: 'auto' }}>
        <Box>
          <Text size="xs" style={{ color: 'black' }}>Owner:</Text>
          <Text size="xs" color="dimmed">{idea?.owner?.name || '-'}</Text>
        </Box>
        <Box>
          <Text size="xs" style={{ color: 'black' }}>Status:</Text>
          <Text size="xs" color="dimmed">{idea?.status || '-'}</Text>
        </Box>
        <Box>
          <Text size="xs" style={{ color: 'black' }}>Priority:</Text>
          <Text size="xs" color="dimmed">{idea?.priority || '-'}</Text>
        </Box>
        <Box>
          <Text size="xs" style={{ color: 'black' }}>Product Area:</Text>
          <Text size="xs" color="dimmed">{idea?.componentObj?.name || '-'}</Text>
        </Box>
        <Box>
          <Text size="xs" style={{ color: 'black' }}>Companies:</Text>
          <Text size="xs" color="dimmed">{idea?.aggregations?.accountsCount?.total || '-'}</Text>
        </Box>
      </Flex>
    </Card>
  );
  

}
