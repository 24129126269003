import { useContext, useEffect } from "react";
import IdeaContext from "../IdeaContext";
import { Box, Text, Tooltip } from "@mantine/core";
import EvidenceCardNiv from "./EvidenceCardNiv";

const Inbox = ({ chooseMatch }: { chooseMatch: any }) => {

  const { displayedRequests, displayedRequestsMatches, chosenEvidence } =
    useContext(IdeaContext);

  console.log('displayedRequestsMatches')

  console.log(displayedRequestsMatches)

  const _pendingRequests = displayedRequests.filter(
    (r: any) => r.state === "inReview"
  );

  return (
    <Box>
      {_pendingRequests?.length > 0 && (
        <Box pt="6px" px="20px" w="100%">
          <Tooltip label='These Inbox requests were connected to this Idea by the request submitter' position="top-start" multiline maw={220}>
            <Text color="dimmed" mb="sm">
            Suggested by GTM
            </Text>
          </Tooltip>
          <Box>
            {_pendingRequests?.map((evidence: any, index: number) => (
              <EvidenceCardNiv
                key={evidence._id}
                item={evidence}
                chooseMatch={chooseMatch}
                index={index}
                fromSearch={false}
                chosenEvidence={chosenEvidence}
                ideaPage
              />
            ))}
          </Box>
        </Box>
      )}

      {displayedRequestsMatches?.length > 0 && (
        <Box
          pt={_pendingRequests?.length > 0 ? "16px" : "6px"}
          px="20px"
          w="100%"
        >
          <Tooltip label='These Inbox requests were matched by AI as a potential fit for this Idea' position="top-start" multiline maw={220}>
            <Text color="dimmed" mb="sm">
            Suggested by AI
            </Text>
          </Tooltip>
          

          <Box>
            {displayedRequestsMatches?.map((evidence: any, index: number) => (
              <EvidenceCardNiv
                key={evidence._id}
                item={evidence}
                chooseMatch={chooseMatch}
                index={index}
                fromSearch={false}
                chosenEvidence={chosenEvidence}
                ideaPage
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Inbox;
