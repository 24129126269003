import {
  Box,
  Flex,
  Stack,
} from "@mantine/core";
import CardHeader from "./CardHeader";
import ValidatedRequestActions from "./actions/ValidatedRequestActions";
import { useContext } from "react";
import { MentionProvider } from "../../../MentionTextarea/MentionContext";
import IdeaContext from "../../IdeaContext";
import CardScroller from "./CardScroller";
import MatchActions from "./actions/MatchActions/MatchActions";
import RequestMatchesActions from "./actions/RequestMatchesActions";
import NewRequestActions from "./actions/NewRequestActions";
import SearchActions from "./actions/SearchActions";
import DiscoveryContext from "../../../Discovery/DiscoveryContext";
import CardHeaderDiscovery from "../../../Discovery/evidenceCard/evidence-card/CardHeaderDiscovery";


const SideBar = ({
  navToPerspective,
  setActiveSearch,
  reviewMode,
  ideaPage
}: {
  navToPerspective: any;
  setActiveSearch: any;
  reviewMode: boolean;
  ideaPage: boolean
}) => {

  const ContextToUse: React.Context<any> = 
  ideaPage ? IdeaContext : DiscoveryContext;

  const {
    chosenEvidence,
  } = useContext(ContextToUse);

  const status = chosenEvidence?.status; 
  const isAssigned: boolean = chosenEvidence?.ideaId
  const isRequestInreview : boolean = chosenEvidence?.state === 'inReview' && !chosenEvidence?.match
  const isGapMatch: boolean = chosenEvidence?.match && chosenEvidence?.type === 'gap'
  const isRequestMatch: boolean = chosenEvidence?.match && chosenEvidence?.type === 'request'

  return (
    // h="calc(100vh - 137px)"
    <Box  /* h={`calc(100vh - ${reviewMode ? 107: 137}px)`} */  h='100%' >
      <MentionProvider>
        <Stack justify="space-between" h='100%'  /* h={`calc(100vh - ${reviewMode ? 107: 137}px)`} *//* h='100%' */>
          <Box>
            <Flex
              mt="24px"
              pb="16px" 
              px="35px"
              justify="space-between"
              align="center"
              sx={{ borderBottom: "1px solid #D8D8DB" }}
            >
              {ideaPage ? <CardHeader navToPerspective={navToPerspective} /> : <CardHeaderDiscovery/>}
            </Flex>
            <CardScroller chosenEvidence={chosenEvidence} reviewMode={reviewMode} ideaPage={ideaPage}/>
          </Box>
          {ideaPage && <Box>

            {isGapMatch && status !== "search" &&(
              <MatchActions navToPerspective={navToPerspective} />
            )}
            {isRequestMatch && status !== "search" &&(
              <RequestMatchesActions navToPerspective={navToPerspective}/>
            )}

            {isRequestInreview && status !== "search" && (
              <NewRequestActions
                navToPerspective={navToPerspective}
              />
            )}
            {(isAssigned && status !== "search") && (
              <ValidatedRequestActions
                navToPerspective={navToPerspective}
              />
            )}

            { status === "search" && ( 
              <SearchActions
                navToPerspective={navToPerspective}
                setActiveSearch={setActiveSearch}
              />
            )}
          </Box>}
        </Stack>
      </MentionProvider>
    </Box>
  );
};

export default SideBar;
