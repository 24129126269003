import { Menu, Text, Grid, Flex, Box, Tooltip } from "@mantine/core";
import { IconFingerprint, IconMessage, IconCircleCheck } from "@tabler/icons";
import ReactTimeAgo from "react-time-ago";
import { populateTemplate } from "../../utils/populateTemplate";
import ReactHtmlParser from "react-html-parser";
import { markNotificationsRead } from "../../Api";
import { useAuth0 } from "@auth0/auth0-react";

const notificationsIcons: { [type: string]: any } = {
  IdeaUpdated: IconMessage,
  IdeaDeleted: IconMessage,
  RequestAttached: IconFingerprint,
  RequestDetached: IconFingerprint,
  RequestValidated: IconFingerprint,
  RequestDeclined: IconFingerprint,
};

const Notification = (props: {getListNotifications: () => void; notification: any }) => {
  const notification = props.notification;
  const Icon: any = notificationsIcons[notification.type];
  const title: any = notification.params.title;
  const auth0 = useAuth0();
  // console.log({template:notification.template, params:notification.params})
  const notificationContent: any[] = ReactHtmlParser(
    populateTemplate(notification.template, {...notification.params, ...notification})
  );
  const markNotificationRead = (id:string, mode: string) => {
    markNotificationsRead(auth0, id, mode).then(() => {
      props.getListNotifications();
    });
  }
  
  return (
    <Box m={4} px="sm" mt={"30px"}
      onClick={() => markNotificationRead(notification._id, notification.status === "unread" ? 'viewed' : "unread")}
      sx={{ ":hover": { backgroundColor: "#F8F8FB"}, cursor: "pointer"}}>
      {/* <Menu.Item> */}
      <Flex justify="space-between" align={"center"}>
        <Flex gap={15}>
          <Text sx={{fontSize: "14px"}} color="dimmed">{title}</Text>
          <Flex>
            {/* <Text color="dimmed" mr="sm">
                <IconDots size="17px" />
              </Text> */}
            <Text sx={{fontSize: "14px"}} color="dimmed">
              <ReactTimeAgo date={new Date(notification.createdAt)} />
            </Text>
          </Flex>
        </Flex>
        {notification.status === "unread" ? (
          <Tooltip label="Mark as read" withArrow>
            <Box sx={{height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#2684FF"}}></Box>
          </Tooltip>
        ) : (
          <Tooltip label="Mark as unread" withArrow>
            <Box sx={{cursor: "pointer"}}>
              <IconCircleCheck size={14} color="green"/>
            </Box>
          </Tooltip>
        )}
      </Flex>
      <Text mt="xs" sx={{fontSize: "14px"}}>
        <div className={"notification-content"}>{notificationContent}</div>
      </Text>
      {/* </Menu.Item> */}
    </Box>
  );
};

export default Notification;
