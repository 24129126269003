import { Box, Checkbox, Flex, Menu, Text, Tooltip } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import FiltersContext from "../../../ideas/filters/context/FiltersContext";
import CompanyCheckboxLine from "./CompanyCheckboxLine";
import IdeaContext from "../../IdeaContext";
import { findFilterByName } from "../../../../context/helpers";
import SyncContext from "../../../../context/SyncContext";
import DiscoveryContext from "../../../Discovery/DiscoveryContext";

const CompaniesList = ({
  dynamicCount,
  dynamicOrgIds,
  companies,
  showDropdownOnly = false, // New prop to control dropdown-only behavior
}: {
  dynamicCount: number;
  dynamicOrgIds: string[];
  companies: any[];
  showDropdownOnly?: boolean;
}) => {
  const { ideasFiltersConfig } = useContext(SyncContext);
  const { activeFilters, setActiveFilters } = useContext(FiltersContext);

  const key = "company";

  const currentValues =
    activeFilters?.find((f) => f?.parent?.toLowerCase() === key)?.selected || [];

  const handleCheckboxChange = (selectedArray: string[]) => {
    setActiveFilters &&
      setActiveFilters((prevFilters: any) => {
        const CurrentFilterConfig = findFilterByName(
          key,
          "evidence",
          ideasFiltersConfig
        );
        const index = prevFilters.findIndex(
          (filter: any) => filter.parent === key
        );

        if (index !== -1) {
          const updatedFilters = [...prevFilters];
          updatedFilters[index] = {
            ...CurrentFilterConfig,
            ...updatedFilters[index],
            selected: selectedArray,
          };
          return updatedFilters;
        } else {
          return [
            ...prevFilters,
            {
              ...CurrentFilterConfig,
              selected: selectedArray,
            },
          ];
        }
      });
  };



  // Render Dropdown only
  if (showDropdownOnly) {
    return (
      <Checkbox.Group value={currentValues} onChange={handleCheckboxChange}>
        <Box
          sx={{
            maxHeight: 300,
            width: 250,
            overflowY: "auto",
            overflowX: "hidden",
            borderRadius: 8,
            padding: "12px",
          }}
        >
          {companies &&
            companies?.length > 0 &&
            companies?.slice(0, 50).map((company: any) => (
              <CompanyCheckboxLine
                key={company?._id}
                company={company}
                active={dynamicOrgIds?.includes(company?._id)}
              />
            ))}
          {companies.length > 50 && (
            <Text mt="sm">{`And ${companies?.length - 50} others...`}</Text>
          )}
        </Box>
      </Checkbox.Group>
    );
  }

  // Default behavior with text and dropdown
  return (
    <Menu position="bottom-start">
      <Menu.Target>
        <Flex>
          <Tooltip label="Total">
            <Text
              fz={14}
              mb={15}
              color="#5C5CEB"
              sx={{ cursor: "pointer" }}
              mr={dynamicCount === companies?.length ? 4 : 3}
            >
              {companies?.length || 0}
            </Text>
          </Tooltip>
          {dynamicCount !== companies?.length && (
            <Tooltip label="Filtered">
              <Text
                fz={14}
                mb={15}
                color="#212529"
                sx={{ cursor: "pointer" }}
                mr={3}
              >
                {`(${dynamicCount})`}
              </Text>
            </Tooltip>
          )}
          <Text fz={14} mb={15} color="#5C5CEB" sx={{ cursor: "pointer" }}>
            Companies
          </Text>
        </Flex>
      </Menu.Target>
      <Menu.Dropdown>
        <Checkbox.Group value={currentValues} onChange={handleCheckboxChange}>
          <Box
            sx={{
              maxHeight: 300,
              width: 250,
              overflowY: "auto",
              overflowX: "hidden",
              borderRadius: 8,
              padding: "12px",
            }}
          >
            {companies &&
              companies?.length > 0 &&
              companies?.slice(0, 50).map((company: any) => (
                <CompanyCheckboxLine
                  key={company?._id}
                  company={company}
                  active={dynamicOrgIds?.includes(company?._id)}
                />
              ))}
            {companies.length > 50 && (
              <Text mt="sm">{`And ${companies?.length - 50} others...`}</Text>
            )}
          </Box>
        </Checkbox.Group>
      </Menu.Dropdown>
    </Menu>
  );
};

export default CompaniesList;
