import {
  ActionIcon,
  Flex,
  Text,
  Tooltip,
  Box,
  Avatar,
  Group,
} from "@mantine/core";
import { IconX } from "@tabler/icons";
import ReactTimeAgo from "react-time-ago";
import { closeAllModals } from "@mantine/modals";
import BusinessCategoryIcon from "../../../icons/BusinessCategoryIcon";
import { getRequestIconByType } from "../../../utils";
import { getInitials } from "../../../utils/getInitials";

export interface ModalHeaderProps {
  evidence: any;
}

export function ModalHeader({ evidence }: ModalHeaderProps) {
  const businessName =
    evidence.businessName ||
    (evidence.origin === "salesforce" || evidence.origin === "zendesk"
      ? "Support Request"
      : "GTM Request");
  // console.log({ evidence });

  const createdAt = evidence.createdAt;
  const icon = getRequestIconByType("opportunity");

  return (
    <Flex
      justify="space-between"
      align="center"
      p="sm"
      h={56}
      sx={{
        borderBottom: "1px solid #E5E7EB",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        top: 0,
        position: 'sticky',
        zIndex:2
      }}
      bg="#F8F9FB"
    >
      <Flex>
        <Tooltip label={businessName || "General"} withArrow>
          <Box mr="6px" mt={4.9}>
            {businessName === "Opportunity Requirements" ? (
              <Avatar src={icon} alt={businessName} color="#5B60E3" size={21}>
                {getInitials(businessName)}
              </Avatar>
            ) : (
              <BusinessCategoryIcon
                businessName={businessName || "Sic Description"}
              />
            )}
          </Box>
        </Tooltip>
        <Flex align="center">
          <Text mx={5} fw={600}>
            {businessName || "General"}
          </Text>
          <Text mr="md" ml={6} fz="sm">
            Evidence submitted by{" "}
            <Text fw="500" span> {evidence.submitter?.name || "Unknown"}</Text>
          </Text>
          {createdAt && (
            <Text ml={-4} size="xs" c="dimmed">
              <ReactTimeAgo date={new Date(createdAt)} />
            </Text>
          )}
        </Flex>
      </Flex>
      <ActionIcon onClick={() => closeAllModals()}>
        <IconX size={18} />
      </ActionIcon>
    </Flex>
  );
}
