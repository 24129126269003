import { Anchor, Box, Flex, Group, Text } from '@mantine/core'
import CardBody from './CardBody'
import { SourceIcon } from '../../../../utils/SourceIcon'
import moment from 'moment'

const UpperScroller = ({chosenEvidence}:{chosenEvidence: any}) => {

  const cxchosenEvidence = chosenEvidence?.cxItem;
  const origin = cxchosenEvidence?.trueOrigin || cxchosenEvidence?.origin || chosenEvidence?.origin;

  return (
    <Box>
      <Box px="35px" mt="28px">
        <CardBody item={chosenEvidence} />
      </Box>
      <Box mb="8px" px="40px">
        {cxchosenEvidence && (
          <Group mt={"md"}>
            {chosenEvidence?.cxItem && (
              <Box>
                <Flex mb="5px">
                  <SourceIcon sourceName={origin} height={15} width={15} />
                  <Text ml={8} fz="xs" sx={{ textTransform: "capitalize" }}>
                    {origin}
                    {cxchosenEvidence?.link && (
                      <Anchor
                        ml="lg"
                        mt={"md"}
                        size={"xs"}
                        component={"a"}
                        target={"_blank"}
                        href={cxchosenEvidence?.link}
                      >
                            Go To {cxchosenEvidence?.originId}
                      </Anchor>
                    )}
                  </Text>
                </Flex>
              </Box>
            )}
          </Group>
        )}
        {!cxchosenEvidence && chosenEvidence?.origin && chosenEvidence?.link && (
          <Group mt={"md"}>
            {origin && (
              <SourceIcon sourceName={origin} height={15} width={15} />
            )}
            <Text fz="sm" sx={{ textTransform: "capitalize" }}>
              {origin || "bagel"}
              {chosenEvidence?.updatedAt && (
                <Text ml="lg" size="xs" c="dimmed" span tt={"lowercase"}>
                  {moment(new Date(chosenEvidence?.updatedAt)).fromNow()}
                </Text>
              )}
              {chosenEvidence?.link && (
                <Anchor
                  ml="lg"
                  mt={"md"}
                  size={"sm"}
                  component={"a"}
                  target={"_blank"}
                  href={chosenEvidence?.link}
                >
                      Go To {chosenEvidence?.chosenEvidenceKey || chosenEvidence?.originId}
                </Anchor>
              )}
            </Text>
          </Group>
        )}
      </Box>
    </Box>
  )
}

export default UpperScroller