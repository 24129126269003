import { createContext, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { addViews, getViews } from "../../Api";
import SyncContext from "../../context/SyncContext";
import { IdeasContextType, initialState } from "../../types/types";
import FiltersContext from "./filters/context/FiltersContext";
import { getFilteredRequests as fetchFilteredRequests } from "./ideasHelpers";
import { updateAccessorByState } from "../../utils";

const IdeasContext = createContext<IdeasContextType>(initialState);

const IdeasContextProvider = ({ children }: { children: React.ReactNode }) => {

  const { ideaColumns, ideasFiltersConfig, impactAggregationProperties } = useContext(SyncContext);

  const { activeFilters } = useContext(FiltersContext);
  const location = useLocation();
  const auth0 = useAuth0();
  const queryParams = new URLSearchParams(location.search);
  const _page = queryParams.get("page");
  const _selectedView = queryParams.get("selectedView");

  const [ideas, setIdeas] = useState<any[]>([]);
  const [page, setPage] = useState<string | null>(_page || null);
  const [requestsTotalCount, setRequestsTotalCount] = useState(0);
  const [sortState, setSortState] = useState<string>("total");
  const [filters, setFilters] = useState<any>(() => {
    const queryParams = new URLSearchParams(location.search);
    const filtersStr = queryParams.get("filters");
    return filtersStr ? JSON.parse(decodeURIComponent(filtersStr)) : null;
  });
  const [sortStatus, setSortStatus] = useState<any>({});
  const [isTagsHovered, setIsTagsHovered] = useState<boolean>(false);
  const [hoveredId, setHoveredId] = useState("");
  const [savedViews, setSavedViews] = useState<any[]>([]);
  const [selectedView, setSelectedView] = useState<any>(
    _selectedView ? JSON.parse(decodeURIComponent(_selectedView)) : {}
  );

  const [search, setSearch] = useState("");
  const [, setLastFilterParams] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const getFilteredIdeas = () => {
  
    fetchFilteredRequests(
      sortStatus,
      setLastFilterParams,
      page,
      search,
      activeFilters,
      setLoading,
      setIdeas,
      setRequestsTotalCount, 
      setPage,
      ideasFiltersConfig,
      auth0
    );
  };

  const loadViews = async (deleted = false) => {
    try {
      const { views } = await getViews(auth0);
      setSavedViews(views);
      if (!selectedView?._id || deleted) {
        setSelectedView(views[0]);
      }
    } catch (error) {
      console.error("Failed to load views:", error);
    }
  };

  const upsertView = async (body: any, callback: (res: any) => void) => {
    try {
      const res = await addViews(body, auth0);
      callback(res);
      await loadViews(body?.deleted);
    } catch (error) {
      console.error("Failed to upsert view:", error);
    }
  };

  const hoverTags = (e: any, id: string) => {
    if (e.type === "mouseleave") {
      setIsTagsHovered(false);
      setHoveredId(id);
    } else if (e.type === "mouseenter") {
      setIsTagsHovered(true);
      setHoveredId(id);
    }
  };

  useEffect(() => {
    loadViews();
  }, []);

  const findColumnByImpactName = (ideaColumns: any[], impactName: string) => {
    return ideaColumns.find((column: any) => column.accessor.includes(impactName));
  };
  
  useEffect(() => {
   
    const firstImpact = impactAggregationProperties?.length
      ? impactAggregationProperties?.find(
        (impactItem: any) => impactItem?.impact?.index === 0
      )
      : null;
    const impactName = firstImpact?.propertyName || null;

    const foundColumn = impactName
      ? findColumnByImpactName(ideaColumns, impactName)
      : null;
    const defaultAccessor = foundColumn?.accessor;
    const updatedAccessor = updateAccessorByState(defaultAccessor, "total");
    const impactSortDefault = {
      columnAccessor: updatedAccessor,
      direction: "asc",
    };
    const _sortStatus = queryParams.get("sortStatus");

    const lastSort = _sortStatus ? JSON.parse(_sortStatus) : impactSortDefault;

    const accessorUpdated = lastSort?.columnAccessor || updatedAccessor;
    const relevant = updateAccessorByState(accessorUpdated, "total");
    lastSort.columnAccessor = relevant;
    setSortStatus(
      lastSort ||
        impactSortDefault || { columnAccessor: "updatedAt", direction: "asc" }
    );
  }, [impactAggregationProperties, ideaColumns]);

  
  return (
    <IdeasContext.Provider
      value={{
        location,
        page,
        filters,
        sortStatus,
        savedViews,
        selectedView,
        requestsTotalCount,
        setRequestsTotalCount,
        setPage,
        setFilters,
        setSortStatus,
        setSelectedView,
        loadViews,
        upsertView,
        sortState,
        setSortState,
        isTagsHovered,
        hoverTags,
        hoveredId,
        search,
        setSearch,
        loading,
        setLoading,
        ideas,
        setIdeas,
        getFilteredIdeas,
        ideaColumns
      }}
    >
      {children}
    </IdeasContext.Provider>
  );
};

export { IdeasContext, IdeasContextProvider };
export default IdeasContext;
