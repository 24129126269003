import { Box, Flex, Stack, Tooltip, Text, Menu } from "@mantine/core";
import {
  BagelAiIcon,
  FromBagelIcon,
  RequestIcon,
} from "../../../../icons/x-symbol-svgrepo-com";
import { SourceIcon } from "../../../../utils/SourceIcon";
import { IconAlertOctagon, IconCheck } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import SyncContext from "../../../../context/SyncContext";
import { saveRequest } from "../../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { showNotification } from "@mantine/notifications";
import IdeaContext from "../../IdeaContext";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { getNestedValue } from "../../Funcs";
import { formatBigMonetaryValueIdeas } from "../../../ideas/ideas-table-helpers/Helpers";

const CardAttributes = ({ item }: { item: any }) => {
  const auth0 = useAuth0();
  const { requestPriorities, allComponents, impactAggregationProperties } =
    useContext(SyncContext);

  

  const { loadEvidence } = useContext(IdeaContext);

  const [priority, setPriority] = useState<string>(
    item?.priority || "Unassigned"
  );
  const [color, setColor] = useState("");

  const sourceOfAssignment = item?.sourceOfAssignment; // FIX sourceOfAssignment FOR MATCHES
  const origin = item?.cxItem?.trueOrigin || item?.cxItem?.origin || item?.origin 
  const company = item?.company || null;
  const companyName = company?.name;

  const onSelectSave = (type: string, value: string) => {
    let updatedValue = value;

    if (type === "componentId") {
      const found = allComponents.find((a: any) => a._id === value);
      updatedValue = found?.name;
    }

    console.log({
      onSelectSave: {
        type,
        value,
      },
    });
    if (item) {
      const itemObj = {
        id: item?._id,
        [type]: value,
      };

  
      console.log({ item, itemObj });
      saveRequest(itemObj, auth0)
        .then((response) => {
          console.log(response);
          loadEvidence();
          showNotification({
            title: `${
              type === "componentId"
                ? "Product area"
                : type === "priority"
                  ? "Priority"
                  : "Domain"
            } updated`,
            color: "teal",
            message: `${
              type === "componentId"
                ? "Product area"
                : type === "priority"
                  ? "Priority"
                  : "Domain"
            } updated to "${updatedValue}" successfully`,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200,
          });
        })
        .catch((e: any) => console.log(e));
      
    }
  };

  useEffect(() => {
    setPriority(item?.priority || "Unassigned");
    const currentPriorityIndex = requestPriorities.findIndex(
      (r: any) => r.key.toLowerCase() === item?.priority?.toLowerCase()
    );
    if (currentPriorityIndex === -1) {
      setPriority("Unassigned");
    }
    setColor(requestPriorities[currentPriorityIndex]?.color);
  }, [item]);

  return (
    <Flex h={32}  align="center" w='100%' maw='100%'>
      <Flex h={32} mr={4} align="center" maw='100%'>
        <Stack mr={9} justify="center" align="center">
          {sourceOfAssignment === "suggestion" ? (
            <Tooltip label="Found by Bagel AI">
              <Stack h={20} w={20} align="center">
                <BagelAiIcon />
              </Stack>
            </Tooltip>
          ) : (sourceOfAssignment === "bagel" || sourceOfAssignment === "search") ? (
            <Tooltip label="Added by PM">
              <Stack align="center" h={20} w={20}>
                <FromBagelIcon />
              </Stack>
            </Tooltip>
          ) : (sourceOfAssignment === "request") ? (
            <Tooltip label="Request">
              <Stack align="center" h={20} w={20}>
                <RequestIcon />
              </Stack>
            </Tooltip>
          ) : (
            ""
          )}
        </Stack>
        {origin && (
          <Tooltip label={capitalizeFirstLetter(origin) || "N/A"}>
            <Stack
              mx={6}
              align="center"
              mb={origin === "hero" ? "3px" : origin === "facebook" ? -5: "0px"}
            >
              <SourceIcon sourceName={origin?.toLowerCase() || "N/A"} />
            </Stack>
          </Tooltip>
        )}

        <Tooltip label={companyName || "N/A"}>
          <Box h={32} px={9} sx={{ borderRight: "1px solid #D8D8DB" }}  w='100%' >
            <Text h={16} fz={12} fw={400}>
              Name
            </Text>
            <Text h={16} fz={12} fw={600} lineClamp={1} >
              {companyName || "N/A"}
            </Text>
          </Box>
        </Tooltip>
      </Flex>
      <Flex>
        <Flex>
          {impactAggregationProperties &&
            impactAggregationProperties.length > 0 &&
            impactAggregationProperties.map((impactItem: any) => (
              <Box
                h={32}
                px={9}
                maw={124}
                sx={{ borderRight: "1px solid #D8D8DB" }}
                key={impactItem?.label}
              >
                <Tooltip label={impactItem?.label || "N/A"}>
                  <Text h={16} fz={12} fw={400} lineClamp={1}>
                    {impactItem?.label || "N/A"}
                  </Text>
                </Tooltip>

                <Text h={16} fz={12} fw={600} lineClamp={1}>
                  {impactItem.fieldPath &&
                  getNestedValue(item, impactItem.fieldPath)
                    ? formatBigMonetaryValueIdeas(
                      getNestedValue(item, impactItem.fieldPath),
                      (impactItem?.impact?.prefix || undefined)
                    )
                    : "N/A"}
                </Text>
              </Box>
            ))}
        </Flex>
     
        <Box mr={45}>
          <Menu>
            <Menu.Target>
              <Tooltip label={`Priority: ${priority || "N/A"}`}>
                <Box sx={{ cursor: "pointer" }}>
                  <Stack align="center" justify="center" ml={16} fz="14px">
                    {" "}
                    <IconAlertOctagon size={16} color={color} />{" "}
                  </Stack>
                </Box>
              </Tooltip>
            </Menu.Target>

            <Menu.Dropdown>
              {requestPriorities.map((priorityItem: any) => (
                <Menu.Item
                  w="180px"
                  key={priorityItem.label}
                  onClick={() => {
                    setPriority(priorityItem?.label);
                    setColor(priorityItem.color);
                    onSelectSave("priority", priorityItem.label);
                  }}
                >
                  {priorityItem.label}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Flex>
      
    </Flex>
  );
};

export default CardAttributes;
