import { Box, Button, Group, Select, Textarea, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { closeAllModals } from "@mantine/modals";
import { UseListStateHandlers } from "@mantine/hooks";
import useEvidenceActions from "../../../hooks/useEvidenceActions";

export function DeclineModalContent({
  evidence,
  evidencesHandlers,
  // idea,
  onUpdated,
}: {
  evidence: any;
  evidencesHandlers?: UseListStateHandlers<any[]> | undefined;
  // idea: any;
  onUpdated?: (useLoading?: boolean) => void;
}) {
  const { dismiss, isLoading } = useEvidenceActions({ evidenceId: evidence._id });
  const form = useForm({
    initialValues: {
      reason: "",
      comment: "",
    },
  });
  const submitterName =
    evidence.submitter?.name ||
    evidence.cxSubmitter?.name ||
    evidence.submitter?.displayName;

  const handleDecline = (values: any) => {
    console.log({ values });
    dismiss({
      rejectInfo: { reason: values.reason, comment: values.comment },
    })
      .then((result) => {
        evidencesHandlers?.filter((evi: any) => evi._id !== evidence._id);
        console.log({dismissFeedbackresult: result})
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        onUpdated?.(false);
        closeAllModals();
      });
  };

  // const openDeleteRequestPopup = () =>
  //   openConfirmModal({
  //     title: "Delete idea",
  //     centered: true,
  //     children: (
  //       <Text size="sm">
  //         This idea has no evidence. do you want to delete it?
  //       </Text>
  //     ),
  //     labels: { confirm: "Delete idea", cancel: "No, keep it for now" },
  //     confirmProps: { color: "red" },
  //     onCancel: () => console.log("Cancel"),
  //     onConfirm: () => {
  //       deleteIdea(idea._id, auth0)
  //         .then((response) => {
  //           navigate("/ideas");
  //         })
  //         .catch((e: any) => console.log(e));
  //     },
  //   });

  return (
    <Box>
      <Box>
        <Title color="#5B60E3" order={3} align="center">
          {submitterName
            ? `Let ${submitterName} know why this evidence was declined`
            : "Let stakeholders know why this evidence was declined"}
        </Title>
      </Box>
      <Box m="xl" sx={{ width: 400 }} mx="auto">
        <form onSubmit={form.onSubmit((values) => handleDecline(values))}>
          <Select
            mb="md"
            label="Reason"
            placeholder="Select reason"
            withAsterisk
            data={[
              "Solution existing (feature / alternative / work around)",
              "Not a product request",
              "Not aligned with product vision",
              "Low demand/low ROI",
              "Other",
            ]}
            {...form.getInputProps("reason")}
          />
          <Textarea
            withAsterisk
            placeholder="Add your comment"
            label="Add comment"
            {...form.getInputProps("comment")}
          />

          <Group position="center" mt="xl">
            <Button
              disabled={
                form.values.comment.length < 3 || form.values.reason === null
              }
              loading={isLoading.dismiss}
              mt="xl"
              sx={{
                backgroundColor: "#5B60E3",
                ":hover": {
                  backgroundColor: "#4c50ba",
                },
              }}
              type="submit"
              size="lg"
            >
              Decline
            </Button>
          </Group>
        </form>
      </Box>
    </Box>
  );
}
