import { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getEvidencesExportData } from "../../Api";
import {
  Badge,
  Box,
  Button,
  Center,
  Group,
  Paper,
  Switch,
  Text,
  Title,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import Papa from "papaparse";
import { IconDownload, TablerIcon } from "@tabler/icons";
import SyncContext from "../../context/SyncContext";
import moment from "moment";

type CsvColumn = {
  accessor: string;
  title: string;
  render?: (row: any) => string;
};

const CellBadge = ({
  text,
  Icon,
}: {
  text: string | number;
  Icon: TablerIcon;
}) => (
  <Badge
    sx={{
      textTransform: "none",
      color: "black",
      fontWeight: 500,
    }}
    px={5}
    variant="outline"
    leftSection={
      <Center>
        <Icon size={12} />
      </Center>
    }
    radius="sm"
    color="gray"
  >
    {text}
  </Badge>
);
const columns: CsvColumn[] = [
  {
    accessor: "_id",
    title: "EvidenceId",
  },
  {
    accessor: "title",
    title: "Title",
    render: ({ title, textItem }: any) =>
      title && title !== "" && title !== null ? title : textItem?.title,
  },
  {
    accessor: "text",
    title: "Description",
    render: ({ text, textItem }: any) =>
      text && text !== "" && text !== null ? text : textItem?.text,
  },
  {
    accessor: "priority",
    title: "Priority",
  },
  {
    accessor: "componentObj.domain",
    title: "Domain",
  },
  {
    accessor: "componentObj",
    title: "ProductArea",
    render: ({ componentObj }: any) =>
      componentObj?.parent?.name ?? componentObj?.name,
  },
  {
    accessor: "componentObj",
    title: "SubArea",
    render: ({ componentObj }: any) =>
      componentObj.parent ? componentObj.name : "",
  },
  {
    accessor: "businessName",
    title: "BusinessName",
    render: ({ businessName, textItem }: any) =>
      textItem?.businessName ?? businessName,
  },
  // {
  //   accessor: "excerpts",
  //   title: "Excerpt",
  // },
  {
    accessor: "status",
    title: "Status",
  },
  {
    accessor: "ideaId",
    title: "IdeaId",
  },
  {
    accessor: "idea.title",
    title: "IdeaName",
  },
  {
    accessor: "idea.status",
    title: "IdeaStatus",
  },
  {
    accessor: "gtmIdeaId",
    title: "gtmIdeaId",
  },
  {
    accessor: "gtmIdea.title",
    title: "gtmIdeaName",
  },
  {
    accessor: "gtmIdea.status",
    title: "gtmIdeaStatus",
  },
  {
    accessor: "idea.linkedProductItem.link",
    title: "DeliveryItemLink",
  },
  {
    accessor: "idea.linkedProductItem.status",
    title: "DeliveryItemStatus",
  },
  {
    accessor: "ownerId",
    title: "OwnerId",
  },
  {
    accessor: "owner.name",
    title: "OwnerName",
  },
  {
    accessor: "internalSubmitter.email",
    title: "BagelSubmitterEmail",
  },
  {
    accessor: "internalSubmitter.name",
    title: "BagelSubmitterName",
  },
  {
    accessor: "cxSubmitter.email",
    title: "SubmitterEmail",
  },
  {
    accessor: "cxSubmitter.name",
    title: "SubmitterName",
  },
  {
    accessor: "cxItem.originSubmitterId",
    title: "SubmitterId",
  },
  {
    accessor: "origin",
    title: "Origin",
  },
  {
    accessor: "type",
    title: "Type",
  },
  {
    accessor: "sourceOfAssignment",
    title: "Source",
  },
  {
    accessor: "company.sfId",
    title: "SfAccountId",
  },
  {
    accessor: "company.name",
    title: "AccountName",
  },
  {
    accessor: "cxItem.originId",
    title: "SfOpportunityId",
    render: ({ chat }: any) => chat?.origin == 'salesforce' ? chat.originId : '',
  },
  {
    accessor: "OperationName",
    title: "OperationName",
  },
  {
    accessor: "SfOperationtId",
    title: "SfOperationtId",
  },
  {
    accessor: "createdAt",
    title: "CreatedAt",
    render: ({ createdAt }: any) => moment(createdAt).format("YYYY-MM-DD"),
  },
  {
    accessor: "updatedAt",
    title: "UpdatedAt",
    render: ({ updatedAt }: any) => moment(updatedAt).format("YYYY-MM-DD"),
  },
  {
    accessor: "rejectInfo.reason",
    title: "RejectionReason",
  },
  {
    accessor: "rejectInfo.comment",
    title: "RejectionComment",
  },
  {
    accessor: "rejectInfo.rejectingUserId",
    title: "RejectingUserId",
  },
  {
    accessor: "rejectInfo.rejectedAt",
    title: "RejectedAt",
    render: ({ rejectInfo }: any) =>
      rejectInfo?.rejectedAt
        ? moment(rejectInfo.rejectedAt).format("YYYY-MM-DD")
        : "",
  },
  {
    accessor: "cxItem.link",
    title: "ExternalEvidenceLink",
  },
];

function getNestedValue(obj: any, path: string): any {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
}

const useThisWeekDates = (): [Date, Date] => {
  const now = new Date();
  const firstDayOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
  firstDayOfWeek.setHours(0, 0, 0, 0);
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  return [firstDayOfWeek, lastDayOfWeek];
};

const ExportItems = () => {
  const auth0 = useAuth0();
  const { accountDoc } = useContext(SyncContext);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(0);
  const [value, setValue] = useState<[Date | null, Date | null]>(
    useThisWeekDates()
  );
  const [checked, setChecked] = useState(false);
  const downloadCSV = async () => {
    setLoading(true);
    const { evidences } = await getEvidencesExportData(auth0, {
      dates: checked ? [null, null] : value,
    });
    const todaysDate = moment().format("MMM-DD-YYYY");
    const hasCrmId = evidences.some((request: any) => request?.company?.crmId)
    const addedColumns: CsvColumn[] = []
    if (hasCrmId) {
      addedColumns.push({
        accessor: "company.crmId",
        title: "CrmId",
      })
    }
    const csvColumns = [...columns, ...addedColumns]
    const mappedData = evidences.map((item: any) => {
      const record: { [key: string]: any } = {};
      csvColumns.forEach(({ accessor, title, render }: CsvColumn) => {
        record[title] = render ? render(item) : getNestedValue(item, accessor);
      });
      return record;
    });

    // console.log({ mappedData });
    const csvFileName = `${accountDoc?.name}_Evidence_${todaysDate}.csv`;
    const csvData = Papa.unparse(mappedData, {
      header: true, // Include headers in the CSV
    });

    // Continue with Blob and creating the download link
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);

    // Create an anchor tag to trigger the download
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = csvFileName;

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    setLoading(false);
  };

  return (
    <Paper m="md" radius="md" withBorder>
      <Center>
        <Group py={50} px="md" spacing={50} hidden={active !== 0}>
          <Box
            sx={{
              opacity: checked ? 0.5 : 1,
            }}
          >
            <DatePicker
              value={value}
              onChange={setValue}
              type="range"
              /* firstDayOfWeek="sunday" */
              styles={{
                day: {
                  "&[data-selected]": {
                    backgroundColor: "#5B60E3",
                  },
                  "&[data-in-range]": {
                    backgroundColor: "#5b60e321",
                    "&[data-selected]": {
                      backgroundColor: "#5B60E3",
                    },
                  },
                  "&[data-weekend]": {
                    color: `#5B60E3`,
                    "&[data-selected]": {
                      color: "#FFF",
                    },
                  },
                },
              }}
            />
          </Box>
          <Box>
            <Title order={2}>Export evidence data</Title>
            <Text maw={300} my="sm" color="dimmed">
              Please select a date range to customize your evidence data export
              to your needs for accurate analysis or reporting.
            </Text>
            <Group>
              <Button
                onClick={() => downloadCSV()}
                rightIcon={<IconDownload size={16} />}
                loading={loading}
                disabled={!accountDoc?.name}
                styles={(theme) => ({
                  root: {
                    backgroundColor: "#5B60E3",
                    border: 0,
                    height: 40,
                    "&:hover": {
                      backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                    },
                  },
                })}
              >
                Export Data
              </Button>
              <Box>
                <Switch
                  styles={{
                    input: {
                      ":checked": {
                        backgroundColor: "#5B60E3 !important",
                        borderColor: "#5B60E3 !important",
                      },
                    },
                  }}
                  checked={checked}
                  onChange={(event) => setChecked(event.currentTarget.checked)}
                />
                <Text size="xs" color="dimmed">
                  Export all data
                </Text>
              </Box>
            </Group>
          </Box>
        </Group>
      </Center>
    </Paper>
  );
};

export default ExportItems;
