import { Box, Flex, Text, Popover } from "@mantine/core";
import TitleWithTooltip from "./TitleWithTooltip";
import CompaniesList from "./Companies/CompaniesList";
import { useEffect, useRef } from "react";
import { formatBigMonetaryValue } from "../Funcs";

interface ImpactItemProps {
  aggregationProperty: any;
  impactData: any;
  dynamicCount: number;
  dynamicOrgIds: string[];
  ideaPage: boolean;
  companies: any[];
  ideaDomain?: string;
  ideaRanks: any;
}

const ImpactItem = ({
  aggregationProperty,
  impactData,
  dynamicCount,
  dynamicOrgIds,
  ideaPage,
  ideaRanks,
  companies
}: ImpactItemProps) => {


  const getMatchedDomain = (objectName: string | undefined, matchedObject: any) => {
    if (!objectName || !matchedObject) return { domainKey: null, matchedDomain: matchedObject };
  
    if (objectName.startsWith("domains") || objectName.startsWith("components")) {
      const domainKey = Object.keys(matchedObject).filter((key) => key !== "index" && key !== "total").pop();
      return { domainKey, matchedDomain: domainKey ? matchedObject[domainKey] : null };
    }
  
    return { domainKey: null, matchedDomain: matchedObject };
  };
  
  const currentImpact = impactData[aggregationProperty.propertyName];
  const isCompanies = aggregationProperty?.fieldPath === "orgId";
  const validatedTotal = currentImpact?.total + currentImpact?.validatedTotal || 0;
  const displayValue = isCompanies ? validatedTotal : formatBigMonetaryValue(validatedTotal, "");
  

  return (
    <Box
      style={{
        flex: "1 1 auto",
        minWidth: "127px",
        borderRadius: 4,
        padding: "12px 16px 12px 16px",
        backgroundColor: "#F8F8FB",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <TitleWithTooltip
        label={aggregationProperty.label}
        tooltip={aggregationProperty.impact.tooltip}
        ideaPage={ideaPage}
      />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <Text weight={600} color="#212529">
          <span style={{ fontSize: "24px" }}>{displayValue}</span>
          {aggregationProperty.fieldPath === "orgId" && companies?.length !== validatedTotal &&
           <span style={{ fontSize: "12px", marginLeft: "4px",fontWeight:400 }}>{`of ${companies?.length}` }</span>}
        </Text>
        {aggregationProperty.fieldPath === "orgId" && (
          <Popover
            width={300}
            position="bottom"
            withArrow
            shadow="md"
            radius="md"
          >
            <Popover.Target>
              <Box
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 4,
                  backgroundColor: "#F0F0FF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  border: "1px solid #D0D0E0",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#4F4F4F"
                  style={{ width: 16, height: 16 }}
                >
                  <path d="M12 16L6 10h12L12 16z" />
                </svg>
              </Box>
            </Popover.Target>
            <Popover.Dropdown>
              <Box>
                <CompaniesList
                  dynamicCount={dynamicCount}
                  dynamicOrgIds={dynamicOrgIds}
                  companies={companies}
                  showDropdownOnly
                />
              </Box>
            </Popover.Dropdown>
          </Popover>
        )}
      </Box>
  
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "30px",
          gap: "8px",
          width: "100%",
        }}
      >
        {ideaPage &&
    currentImpact?.ranksData?.map((rank: any, index: number) => {
      const objectName = rank?.objectName;
      const matchedObject = ideaRanks?.[objectName];
      const { domainKey, matchedDomain } = getMatchedDomain(objectName, matchedObject);
  
      const label = objectName
        ? objectName
          .split("By")[0]
          .replace(/components/gi, "ideas")
          .replace(/domains/gi, "ideas")
          .trim()
        : "Items";
    
      const domainKeyLabel = domainKey && objectName.startsWith("domains") ? domainKey : null;
      const componentKey = domainKey && objectName.startsWith("components") ? domainKey : null;
  
      return (
        <Flex
          key={rank?.index || index}
          align="center"
          justify="center"
          style={{
            display: "flex",
            justifyContent: "left",
            gap: "8px",
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <Box
            style={{
              backgroundColor: "rgb(217 217 248)",
              borderRadius: 4,
              padding: "2px 6px",
              textAlign: "center",
            }}
          >
            <Text size="10px" weight={600} color="#5C5CEB">
              #{matchedDomain?.index || rank?.index}
            </Text>
          </Box>
          <Text size="xs" color="#585C68" style={{ textAlign: "left"}}>
            of {matchedDomain?.total || rank?.total} {label.trim()}{" "}
            {(componentKey || domainKeyLabel) && (
              <>
                <Text span>in </Text>
                <Text color="#6060eb" span>'{componentKey || domainKeyLabel}'</Text>
              </>
            )}
          </Text>
        </Flex>
      );
    })}
      </Box>
    </Box>
  );
};

export default ImpactItem;
