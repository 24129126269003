import {
  Menu,
  Text,
  UnstyledButton,
  Button,
  Flex,
  Divider,
  Box,
  SegmentedControl,
  Center,
  Indicator,
  Switch,
  ActionIcon
} from "@mantine/core";
import { IconBell, IconDots, IconMail, IconMailOpened } from "@tabler/icons";
import { useEffect, useState } from "react";
import Notification from "./Notification";
import { markNotificationsRead } from "../../Api";
import { useAuth0 } from "@auth0/auth0-react";

const NotificationsDropdown = (props: {
  notifications: any[];
  getListNotifications: () => void;
}) => {
  const auth0 = useAuth0();
  const notifications = props.notifications;
  const [opened, setOpened] = useState(false);
  const [marked, setMarked] = useState(false);
  const [tab, setTab] = useState("comments");
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);

  const filtered = notifications.filter((n) => n.status === "unread");
  const notificationsCount = filtered.length;
  useEffect(() => {
    props.getListNotifications();
  }, [opened, marked]);

  const markAllNotificationsRead = async (id = '', mode: string) => {
    await markNotificationsRead(auth0, id, mode).then(() => {
      setMarked(!marked);
    });
  };

  let comments = notifications.filter((n) => n.notification.commentId);
  let activity = notifications.filter((n) => !n.notification.commentId);
  if (showUnreadOnly) {
    comments = comments.filter((n) => n.status === "unread");
    activity = activity.filter((n) => n.status === "unread");
  }
  // Count unread notifications for blue dot indication
  const unreadComments = comments.filter((n) => n.status === "unread").length;
  const unreadActivity = activity.filter((n) => n.status === "unread").length;

  return (
    <Menu
      width={400}
      shadow="md"
      position={"bottom-end"}
      withArrow
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <UnstyledButton>
          {notificationsCount === 0 && (
            <IconBell fill="#363699" color="#363699" size={26} />
          )}
          {notificationsCount > 0 && (
            <Indicator
              size={20}
              color="#FF9499"
              inline
              label={
                <Text p={0} m={0}>
                  {notificationsCount}
                </Text>
              }
            >
              <IconBell fill="#363699" color="#363699" size={26} />
            </Indicator>
          )}
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown p={0}>
        <Flex justify={"space-between"} sx={{background: "#f1f3f5", height: "44px"}} align={"center"} px="xs" >
          <Text
            py={6}
            mb={0}
            sx={{fontSize: "20px"}}
            fw="bold"
          >
            {" "}
            Notifications{" "}
          </Text>
          <Flex align="center">
            <Switch
              size="xs"
              checked={showUnreadOnly}
              onChange={(event) => setShowUnreadOnly(event.currentTarget.checked)}
              label="Unread"
              mr={15}
              sx={(theme) => ({
                '& .mantine-Switch-inner': {
                  backgroundColor: showUnreadOnly ? '#5C5CEB' : '#D8D8DB', 
                },
                '& .mantine-Switch-track': {
                  backgroundColor: showUnreadOnly ? '#5C5CEB' : '#D8D8DB', 
                },
                '& .mantine-Switch-input:checked + .mantine-Switch-track': {
                  backgroundColor: '#5C5CEB', 
                  borderColor: "#5C5CEB",
                },
                '& .mantine-Switch-label': {
                  color: '#585C68',    
                  fontSize: '14px', 
                },
              })}
            />
            <Menu shadow="md" position="bottom-end" withArrow >
              <Menu.Target>
                <ActionIcon variant="subtle" sx={{backgroundColor:"#5C5CEB1A"}} size={24}>
                  <IconDots size={15} color="#1C1B1F"/>
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown sx={{height: "48px", width: "250px"}}>
                <Menu.Item sx={{width: "240px"}} onClick={() => markAllNotificationsRead('', 'viewed')}>{"Mark all as read"}</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Box
          mah={500}
          sx={{
            overflowY: "scroll",
          }}
        >
          <SegmentedControl
            sx={{
              position: "sticky",
              top: "12px",
              height: "40px"
            }}
            value={tab}
            onChange={setTab}
            m="sm"
            fullWidth
            radius="4px"
            data={[
              {
                label: <Flex gap={8} justify="center" align="center" sx={{ fontSize: "14px",fontWeight: 600}}>{unreadComments > 0 && <Box sx={{height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#2684FF"}}></Box>}Comments </Flex>,
                value: "comments",
              },
              {
                label: <Flex gap={8} justify="center" align="center" sx={{ fontSize: "14px", fontWeight: 600}}> {unreadActivity > 0 && <Box sx={{height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#2684FF"}} ></Box>}Activity</Flex>,
                value: "activity",
              }
            ]}
          />
          {((tab === "comments" ? comments : activity).length > 0) ? (
            (tab === "comments" ? comments : activity).map((n, index) => (
              <Box key={n._id}>
                <Notification getListNotifications={props.getListNotifications} notification={{ ...n.notification, status: n.status }} />
                {index + 1 !== (tab === "comments" ? comments : activity).length && <Divider my="md" />}
              </Box>
            ))
          ) : (
            <Center p="md" mb="md">
              <Box sx={{ textAlign: "center" }}>
                {tab === "activity" ? <IconMail size={35} color="#5b60e38c" /> : <IconMailOpened size={35} color="#5b60e38c" />}
                <Text color="gray"> No {tab} notifications</Text>
              </Box>
            </Center>
          )}
        </Box>
      </Menu.Dropdown>
    </Menu>
  );
};

export default NotificationsDropdown;
