import { HoverCard, Text } from "@mantine/core";
import ImpactTooltip from "./ImpactTooltip/ImpactTooltip";

interface TitleWithTooltipProps {
  label: string;
  tooltip?: string;
  ideaPage: boolean;
}

const TitleWithTooltip = ({ label, tooltip, ideaPage }: TitleWithTooltipProps) => {
  return (
    <HoverCard
      disabled={!tooltip}
      position="top"
      radius="md"
      shadow="md"
      styles={{
        dropdown: {
          transition: "transform 0.1s ease, opacity 0.1s ease",
        },
      }}
    >
      <HoverCard.Target>
        <Text size="12px" color="#212529" weight={700} style={{minHeight: "50px", alignContent: 'center',width: '100%',textAlign: 'left' }}>
          {label}
        </Text>
      </HoverCard.Target>
      {tooltip && (
        <HoverCard.Dropdown bg="rgba(245, 245, 255, 1)">
          <ImpactTooltip tooltip={tooltip} ideaPage={ideaPage} />
        </HoverCard.Dropdown>
      )}
    </HoverCard>
  );
};

export default TitleWithTooltip;
