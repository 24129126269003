import { Tooltip, Box, Group, Text } from "@mantine/core";
import BusinessCategoryIcon from "../../../../icons/BusinessCategoryIcon";
import { SourceIcon } from "../../../../utils/SourceIcon";
import { TablerIcon } from "@tabler/icons";

const Property = ({
  label,
  text,
  Icon,
  bg,
  BusinessCategory,
  origin,
  withTooltip,
  withBorder
}: {
  label: string;
  text: string;
  Icon?: ((props: any) => JSX.Element) | TablerIcon;
  BusinessCategory?: string;
  bg?: string;
  origin?: string;
  withTooltip?: boolean;
  withBorder?: boolean;
}) => {
  return (
    <Tooltip disabled={!withTooltip}  label={label}>
      <Box
        sx={{
          backgroundColor: bg ?? "#F8F9FB",
          borderRadius: 8,
          padding: "4px 8px",
          fontSize: 14,
          border: withBorder ? '1px solid #dee2e6' : undefined
        }}
      >
        <Group noWrap spacing={10}>
          {Icon ? <Icon stroke={1.50} size={16} /> : ""}
          {origin ? (
            <SourceIcon sourceName={origin} width={15} height={15} />
          ) : (
            ""
          )}
          {BusinessCategory ? (
            <BusinessCategoryIcon businessName={BusinessCategory} />
          ) : (
            ""
          )}
          <Box>
            <Text tt="capitalize" fz="xs" color="dimmed">
              {label}
            </Text>
            <Text tt="capitalize" fz="xs" fw={500}>
              {text}
            </Text>
          </Box>
          {/* <Text lineClamp={1}>{text}</Text> */}
        </Group>
      </Box>
    </Tooltip>
  );
};
export default Property;
